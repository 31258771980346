import type { AppProps } from 'next/app';
import Script from 'next/script';
import { ApolloProvider } from '@apollo/client';
import { SessionProvider } from 'next-auth/react';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import { reset } from 'stitches-reset';
import { DesignSystemProvider, globalCss } from '@classtinginc/design-system';
import { Toaster } from 'react-hot-toast';
import { useEvent } from 'react-use';
import { useCallback } from 'react';

import AppVersionChecker from '../components/common/AppVersionChecker';

import '@/common/mobile-polyfill';
import { useApollo } from '@libs/apollo-client';
import Auth from '@components/common/Auth';
import Subscription from '@components/common/Subscription';
import TrackingPixel from '@components/common/TrackingPixel';

import nextI18NextConfig from '@/next-i18next.config';
import useInAppRouter from '@/common/hooks/useInAppRouter';
import { AdServiceProvider } from '@/common/advertisement/Provider';

type NavEventDetail = {
  detail: {
    url: string;
  };
};

globalCss({
  ...reset,
  '*': {
    boxSizing: 'border-box',
  },
  a: {
    textDecoration: 'none',
    color: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
  },
  body: {
    ...reset.body,
    fontFamily:
      'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, Helvetica Neue, Segoe UI, Apple SD Gothic Neo, Noto Sans KR, Malgun Gothic, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, sans-serif',
    wordWrap: 'break-word',
    wordBreak: 'keep-all',
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    /*
     FIXME design-system에서 배경색 alise가 추가되면 수정 필요
     https://github.com/classtinginc/design-system/issues/122
    */
    backgroundColor: '$gray-100',
  },
  'button, input, optgroup, select, textarea': {
    fontFamily: 'inherit',
  },
  'input[type="number"]': {
    appearance: 'textfield',
  },
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: '0',
  },
})();

const App = ({ Component, pageProps }: AppProps) => {
  const router = useInAppRouter();

  const navigationHandler = useCallback(
    ({ detail: { url } }: NavEventDetail) => {
      router.push(url);
    },
    [router]
  );

  useEvent('nav:replace', navigationHandler);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no,shrink-to-fit=no"
        />
      </Head>
      <Script
        src="https://polyfill.io/v3/polyfill.min.js?features=globalThis"
        strategy="beforeInteractive"
      />
      <TrackingPixel />
      <Toaster position="bottom-center" />
      <AppVersionChecker skip={!Component.appVersionCheck}>
        <DesignSystemProvider>
          {Component.auth ? (
            <Auth>
              {Component.subscription ? (
                <Subscription>
                  <Component {...pageProps} />
                </Subscription>
              ) : (
                <AdServiceProvider>
                  <Component {...pageProps} />
                </AdServiceProvider>
              )}
            </Auth>
          ) : (
            <Component {...pageProps} />
          )}
        </DesignSystemProvider>
      </AppVersionChecker>
    </>
  );
};

const AppWithTranslation = appWithTranslation(App, nextI18NextConfig);

const AppWithAuthAndApollo = (props: AppProps) => {
  const client = useApollo(props.pageProps);

  return (
    <ApolloProvider client={client}>
      <SessionProvider session={props.pageProps.session}>
        <AppWithTranslation {...props} />
      </SessionProvider>
    </ApolloProvider>
  );
};

export default AppWithAuthAndApollo;
