import { useRouter } from 'next/router';

import { WebViewBridge } from '../webview-bridge';

import useGoBackPage from './useGoBackPage';

type FallbackOptions = {
  target?: '_blank' | '_self';
};

const useInAppRouter = () => {
  const router = useRouter();
  const {
    isClasstingRNWebView,
    open: openWebView,
    close: closeWebView,
    navigateToInsights,
  } = WebViewBridge;
  const historyBack = useGoBackPage();

  const generateFullUrl = (url: string) => {
    const http = 'http://';
    const https = 'https://';

    if (url.includes(http) || url.includes(https)) {
      return url;
    }

    return `${window.location.origin}${url}`;
  };

  const push = (
    url: string,
    appbar: boolean = true,
    fallbackOptions?: FallbackOptions
  ) => {
    const fullUrl = generateFullUrl(url);

    if (isClasstingRNWebView()) {
      openWebView({ url: fullUrl, appbar });
    } else {
      const target = fallbackOptions?.target ?? '_self';

      if (target === '_self') {
        router.push(fullUrl);
      }
      if (target === '_blank') {
        window.open(fullUrl);
      }
    }
  };

  const replace = (url: string) => {
    const fullUrl = generateFullUrl(url);

    if (isClasstingRNWebView()) {
      closeWebView({ eventName: 'nav:replace', eventDetail: { url: fullUrl } });
    } else {
      router.replace(fullUrl);
    }
  };

  const back = () => {
    if (isClasstingRNWebView()) {
      closeWebView();
    } else {
      historyBack();
    }
  };

  const backToHome = () => {
    if (isClasstingRNWebView()) {
      navigateToInsights();
    } else {
      router.push('/home');
    }
  };

  return { push, replace, back, backToHome };
};

export default useInAppRouter;
