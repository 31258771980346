const path = require('path');

const dayjs = require('dayjs');

require('dayjs/locale/ko');

module.exports = {
  i18n: {
    defaultLocale: 'ko',
    locales: ['ko', 'en', 'ja', 'vi', 'es', 'zh-TW'],
  },
  localePath: path.resolve('./public/locales'),
  interpolation: {
    escapeValue: false,
    format: function (value, format, lng = 'en') {
      if (value instanceof Date) {
        let lang = lng;

        if (lng === 'ko-KR') {
          lang = 'ko';
        }
        return dayjs(value).locale(lang).format(format);
      }

      return value;
    },
  },
  ns: ['insights'],
  defaultNS: 'insights',
  fallbackLng: 'en',
  serializeConfig: false,
};
