import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ClassroommutationInputCompleteOnboardingChecklistActionInputAction: any;
  ClassroommutationInputCreateAssignmentSubmissionCommentInputContent: any;
  ClassroommutationInputCreateChannelGroupInputName: any;
  ClassroommutationInputCreateChannelInputName: any;
  ClassroommutationInputCreateChannelInputPurpose: any;
  ClassroommutationInputCreateClassroomInputAbout: any;
  ClassroommutationInputCreateClassroomInputName: any;
  ClassroommutationInputCreateCommentInputContent: any;
  ClassroommutationInputCreateReplyInputContent: any;
  ClassroommutationInputCreateTagInputName: any;
  ClassroommutationInputExcuseAttendanceInputMemo: any;
  ClassroommutationInputPrivateCreateClassroomInputAbout: any;
  ClassroommutationInputPrivateCreateClassroomInputName: any;
  ClassroommutationInputPrivateRegisterForClassroomInputName: any;
  ClassroommutationInputRegisterForClassroomInputName: any;
  ClassroommutationInputReportPostInputReason: any;
  ClassroommutationInputSavePostAsDraftInputContent: any;
  ClassroommutationInputSavePostAsDraftInputTagsItems: any;
  ClassroommutationInputSavePostAsDraftInputTitle: any;
  ClassroommutationInputUpdateAssignmentSubmissionCommentInputContent: any;
  ClassroommutationInputUpdateChannelGroupInputName: any;
  ClassroommutationInputUpdateChannelInputName: any;
  ClassroommutationInputUpdateChannelInputPurpose: any;
  ClassroommutationInputUpdateClassroomInputAbout: any;
  ClassroommutationInputUpdateClassroomInputName: any;
  ClassroommutationInputUpdateClassroomMemberInputName: any;
  ClassroommutationInputUpdateCommentInputContent: any;
  ClassroommutationInputUpdatePostInputTagsItems: any;
  ClassroommutationInputUpdatePostInputTitle: any;
  ClassroommutationInputUpdateReplyInputContent: any;
  ClassroomqueryInputSearchPostsOrderBy: any;
  DateTime: any;
  JSON: any;
  MutationInputUpdateAccountInputDescription: any;
  NonNegativeFloat: any;
  PositiveFloat: any;
  Void: any;
};

export type AccountDto = {
  __typename?: 'AccountDto';
  avatarImage: Maybe<Scalars['String']>;
  birthDate: Maybe<Scalars['String']>;
  countryCode: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  description: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  emailVerified: Scalars['Boolean'];
  gender: Maybe<QueryGetMeGender>;
  id: Scalars['String'];
  identities: Array<Maybe<SocialIdentity>>;
  isAdFreeSubscribed: Scalars['Boolean'];
  language: Maybe<Scalars['String']>;
  marketingAgreement: MarketingAgreementDto;
  mobile: MobileDto;
  name: Maybe<Scalars['String']>;
  personalCertificate: PersonalCertificate;
  privacyTermsAgreedAt: Maybe<Scalars['DateTime']>;
  profileImage: Maybe<Scalars['String']>;
  role: AccountRole;
  schoolInfo: SchoolInfo;
  schoolInfoDto: Maybe<SchoolInfoDto>;
  schoolInfoDtoForChildren: Array<Maybe<SchoolInfoDto>>;
  schoolInfoForChildren: Array<Maybe<SchoolInfo>>;
  signedUpAt: Maybe<Scalars['DateTime']>;
  studentRegistrationCode: Maybe<Scalars['String']>;
  termsOfUseAgreedAt: Maybe<Scalars['DateTime']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  username: Maybe<Scalars['String']>;
};

export enum AccountRole {
  Parent = 'parent',
  Student = 'student',
  Teacher = 'teacher',
}

export type AccountSchool = {
  __typename?: 'AccountSchool';
  admissionAge: Scalars['Float'];
  city: Scalars['String'];
  countryCode: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  regularCourseYear: Scalars['Float'];
  zipcode: Scalars['String'];
};

export type ActivitiesCollectionDto = {
  __typename?: 'ActivitiesCollectionDto';
  activities: Array<Maybe<ActivityDto>>;
  cursor: Maybe<Scalars['String']>;
};

export type ActivityDto = {
  __typename?: 'ActivityDto';
  actor: Scalars['JSON'];
  childId: Scalars['String'];
  content: Maybe<Scalars['String']>;
  generator: Scalars['JSON'];
  id: Scalars['String'];
  image: Maybe<Scalars['String']>;
  object: Scalars['JSON'];
  publishedAt: Maybe<Scalars['DateTime']>;
  stickers: Maybe<Array<Maybe<StickerDto>>>;
  summary: Maybe<Scalars['String']>;
  target: Scalars['JSON'];
  type: Scalars['String'];
};

export type AssignmentSubmissionRateDto = {
  __typename?: 'AssignmentSubmissionRateDto';
  id: Maybe<Scalars['String']>;
  submissionRate: Maybe<Scalars['Float']>;
  totalAssignmentCount: Maybe<Scalars['Int']>;
  totalSubmissionCount: Maybe<Scalars['Int']>;
  year: Maybe<Scalars['String']>;
};

export type ChangeEmailDtoInput = {
  code: Scalars['String'];
};

export type ChangeMobileDtoInput = {
  code: Scalars['String'];
  requestId: Scalars['String'];
};

export type ChangePasswordDtoInput = {
  newPassword: Scalars['String'];
  newPasswordConfirm: Scalars['String'];
  password: Scalars['String'];
};

export type ChildAccountDto = {
  __typename?: 'ChildAccountDto';
  avatarImage: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  id: Scalars['String'];
  mobile: MobileDto;
  name: Scalars['String'];
  schoolInfo: Maybe<SchoolInfoDto>;
  signInMethods: Array<Maybe<SignInMethod>>;
  signedUpAt: Maybe<Scalars['DateTime']>;
};

export type ChildCodeResponseDto = {
  __typename?: 'ChildCodeResponseDto';
  childId: Scalars['String'];
  childName: Scalars['String'];
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  expireAt: Scalars['DateTime'];
  id: Scalars['String'];
  verifiedGuardianId: Scalars['String'];
};

export type ClassroomActivityCountByMemberDto = {
  __typename?: 'ClassroomActivityCountByMemberDto';
  commentCount: Scalars['Int'];
  lastVisitDate: Maybe<Scalars['DateTime']>;
  memberGroup: Maybe<ClassroomMemberGroup>;
  memberId: Scalars['String'];
  memberName: Scalars['String'];
  postCreationCount: Scalars['Int'];
  reactionCount: Scalars['Int'];
  replyCount: Scalars['Int'];
};

export type ClassroomActivityCountDto = {
  __typename?: 'ClassroomActivityCountDto';
  classroomId: Scalars['String'];
  commentCount: Scalars['Int'];
  postCreationCount: Scalars['Int'];
  reactionCount: Scalars['Int'];
  replyCount: Scalars['Int'];
};

export type ClassroomActivityCountsByMemberCollectionDto = {
  __typename?: 'ClassroomActivityCountsByMemberCollectionDto';
  activityCounts: Array<Maybe<ClassroomActivityCountByMemberDto>>;
  classroomId: Scalars['String'];
  totalMemberCount: Scalars['Int'];
};

export type ClassroomAddReactionToCommentDtoInput = {
  name: Scalars['String'];
};

export type ClassroomAddReactionToPostDtoInput = {
  name: Scalars['String'];
};

export type ClassroomAddReactionToReplyDtoInput = {
  name: Scalars['String'];
};

export type ClassroomAssignAssignmentDtoInput = {
  assigneeIds: Array<InputMaybe<Scalars['String']>>;
  channelId: Scalars['String'];
};

export type ClassroomAssignMemberRoleDtoInput = {
  role: ClassroommutationInputAssignMemberRoleInputRole;
};

export type ClassroomAssignmentDto = {
  __typename?: 'ClassroomAssignmentDto';
  assigneeIds: Maybe<Array<Maybe<Scalars['String']>>>;
  assigneesCount: Scalars['Float'];
  assignmentSubmissions: Array<Maybe<ClassroomAssignmentSubmissionDto>>;
  authorId: Scalars['String'];
  channelId: Maybe<Scalars['String']>;
  classroomId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  dueDateTime: Maybe<Scalars['DateTime']>;
  hasMaxPoints: Scalars['Boolean'];
  id: Scalars['String'];
  isAssigned: Scalars['Boolean'];
  maxPoints: Scalars['Float'];
  membersSubmittedCount: Scalars['Float'];
  meta: Maybe<Scalars['JSON']>;
  postId: Scalars['String'];
  title: Maybe<Scalars['String']>;
  type: ClassroomAssignmentType;
  updatedAt: Scalars['DateTime'];
};

export type ClassroomAssignmentPermissionDto = {
  __typename?: 'ClassroomAssignmentPermissionDto';
  canGrade: Scalars['Boolean'];
  canSubmit: Scalars['Boolean'];
};

export type ClassroomAssignmentSubmissionCommentDto = {
  __typename?: 'ClassroomAssignmentSubmissionCommentDto';
  assigneeId: Scalars['String'];
  assignmentId: Scalars['String'];
  author: ClassroomMemberDto;
  authorId: Scalars['String'];
  classroomId: Scalars['String'];
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  repliesCount: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type ClassroomAssignmentSubmissionCommentPermissionDto = {
  __typename?: 'ClassroomAssignmentSubmissionCommentPermissionDto';
  canDelete: Scalars['Boolean'];
  canEdit: Scalars['Boolean'];
};

export type ClassroomAssignmentSubmissionCommentsCollectionDto = {
  __typename?: 'ClassroomAssignmentSubmissionCommentsCollectionDto';
  assignmentSubmissionComments: Array<
    Maybe<ClassroomAssignmentSubmissionCommentDto>
  >;
  cursor: Maybe<Scalars['String']>;
};

export type ClassroomAssignmentSubmissionDto = {
  __typename?: 'ClassroomAssignmentSubmissionDto';
  assigneeId: Scalars['String'];
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
  commentsCount: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  gradingStatus: ClassroomGradingStatus;
  id: Scalars['String'];
  isSubmitted: Scalars['Boolean'];
  lastSubmission: Maybe<ClassroomSubmissionDto>;
  learningSubmission: Maybe<ClassroomLearningSubmissionDto>;
  points: Maybe<Scalars['Float']>;
  repliesCount: Maybe<Scalars['Float']>;
  status: ClassroomAssignmentSubmissionStatus;
  submissions: Array<Maybe<ClassroomSubmissionDto>>;
  updatedAt: Scalars['DateTime'];
};

export type ClassroomAssignmentSubmissionDto2 = {
  __typename?: 'ClassroomAssignmentSubmissionDto2';
  assigneeId: Scalars['String'];
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
  commentsCount: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  gradingStatus: ClassroomGradingStatus;
  id: Scalars['String'];
  isSubmitted: Scalars['Boolean'];
  lastSubmission: Maybe<ClassroomSubmissionDto>;
  learningSubmission: Maybe<ClassroomLearningSubmissionDto>;
  points: Maybe<Scalars['Float']>;
  repliesCount: Maybe<Scalars['Float']>;
  status: ClassroomAssignmentSubmissionStatus;
  submissions: Array<Maybe<ClassroomSubmissionDto>>;
  updatedAt: Scalars['DateTime'];
};

export type ClassroomAssignmentSubmissionDto3 = {
  __typename?: 'ClassroomAssignmentSubmissionDto3';
  assigneeId: Scalars['String'];
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
  commentsCount: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  gradingStatus: ClassroomGradingStatus;
  id: Scalars['String'];
  isSubmitted: Scalars['Boolean'];
  lastSubmission: Maybe<ClassroomSubmissionDto>;
  learningSubmission: Maybe<ClassroomLearningSubmissionDto>;
  points: Maybe<Scalars['Float']>;
  repliesCount: Maybe<Scalars['Float']>;
  status: ClassroomAssignmentSubmissionStatus;
  submissions: Array<Maybe<ClassroomSubmissionDto>>;
  updatedAt: Scalars['DateTime'];
};

export enum ClassroomAssignmentSubmissionStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Prepared = 'PREPARED',
  Rejected = 'REJECTED',
  Submitted = 'SUBMITTED',
}

export enum ClassroomAssignmentType {
  Assignment = 'ASSIGNMENT',
  Learning = 'LEARNING',
}

export type ClassroomAssignmentsSearchCollectionDto = {
  __typename?: 'ClassroomAssignmentsSearchCollectionDto';
  assignments: Array<Maybe<ClassroomAssignmentDto>>;
  page: Maybe<Scalars['Float']>;
  perPage: Maybe<Scalars['Float']>;
  totalPages: Maybe<Scalars['Float']>;
  totalResults: Maybe<Scalars['Float']>;
};

export type ClassroomAttendanceDto = {
  __typename?: 'ClassroomAttendanceDto';
  attendedAt: Maybe<Scalars['DateTime']>;
  attendee: Maybe<ClassroomMemberDto>;
  attendeeId: Scalars['String'];
  classroomId: Scalars['String'];
  id: Scalars['String'];
  memo: Maybe<Scalars['String']>;
  status: ClassroommutationCreateAttendanceStatus;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type ClassroomAttendanceSummaryDto = {
  __typename?: 'ClassroomAttendanceSummaryDto';
  attendanceRatio: Scalars['Float'];
  date: Scalars['DateTime'];
  numberOfAttendedStudents: Scalars['Float'];
  numberOfStudents: Scalars['Float'];
};

export type ClassroomChannel = {
  __typename?: 'ClassroomChannel';
  archived: Scalars['Boolean'];
  autoJoin: Scalars['Boolean'];
  canMemberReactToPost: Scalars['Boolean'];
  canMemberWriteComment: Scalars['Boolean'];
  canMemberWritePost: Scalars['Boolean'];
  channelGroupId: Maybe<Scalars['String']>;
  classroomId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  editorMode: Maybe<Scalars['JSON']>;
  general: Scalars['Boolean'];
  iconUrl: Maybe<Scalars['String']>;
  id: Scalars['String'];
  inactiveMembersCount: Scalars['Float'];
  isCommon: Scalars['Boolean'];
  isDeletable: Scalars['Boolean'];
  isEditable: Scalars['Boolean'];
  isPrivate: Scalars['Boolean'];
  meeting: Maybe<ClassroomMeeting>;
  meetingId: Maybe<Scalars['String']>;
  memberIds: Array<Maybe<Scalars['String']>>;
  membersCount: Scalars['Float'];
  name: Scalars['String'];
  position: Maybe<Scalars['Float']>;
  postPins: Array<Maybe<Scalars['JSON']>>;
  purpose: Maybe<Scalars['String']>;
  type: ClassroommutationCreateChannelType;
  updatedAt: Scalars['DateTime'];
};

export type ClassroomChannel2 = {
  __typename?: 'ClassroomChannel2';
  archived: Scalars['Boolean'];
  autoJoin: Scalars['Boolean'];
  canMemberReactToPost: Scalars['Boolean'];
  canMemberWriteComment: Scalars['Boolean'];
  canMemberWritePost: Scalars['Boolean'];
  channelGroupId: Maybe<Scalars['String']>;
  classroomId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  editorMode: Maybe<Scalars['JSON']>;
  general: Scalars['Boolean'];
  iconUrl: Maybe<Scalars['String']>;
  id: Scalars['String'];
  inactiveMembersCount: Scalars['Float'];
  isCommon: Scalars['Boolean'];
  isDeletable: Scalars['Boolean'];
  isEditable: Scalars['Boolean'];
  isPrivate: Scalars['Boolean'];
  meeting: Maybe<ClassroomMeeting>;
  meetingId: Maybe<Scalars['String']>;
  memberIds: Array<Maybe<Scalars['String']>>;
  membersCount: Scalars['Float'];
  name: Scalars['String'];
  position: Maybe<Scalars['Float']>;
  postPins: Array<Maybe<Scalars['JSON']>>;
  purpose: Maybe<Scalars['String']>;
  type: ClassroommutationCreateChannelType;
  updatedAt: Scalars['DateTime'];
};

export type ClassroomChannelDto = {
  __typename?: 'ClassroomChannelDto';
  /** 비활성 구성원 수를 제외한 활성 구성원 수 */
  activeMembersCount: Scalars['Float'];
  archived: Scalars['Boolean'];
  autoJoin: Scalars['Boolean'];
  canMemberReactToPost: Scalars['Boolean'];
  canMemberWriteComment: Scalars['Boolean'];
  canMemberWritePost: Scalars['Boolean'];
  channelGroupId: Maybe<Scalars['String']>;
  channelType: ClassroommutationCreateChannelDtoChannelType;
  classroomId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 게시글 작성 시 사용될 에디터 모드를 결정하는 값. "default" | "presentation" */
  editorMode: Scalars['String'];
  iconUrl: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isCommon: Scalars['Boolean'];
  isDeletable: Scalars['Boolean'];
  isEditable: Scalars['Boolean'];
  isPrivate: Scalars['Boolean'];
  meeting: Maybe<ClassroomMeetingDto>;
  meetingId: Maybe<Scalars['String']>;
  memberIds: Array<Maybe<Scalars['String']>>;
  membersCount: Scalars['Float'];
  name: Scalars['String'];
  purpose: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ClassroomChannelDto2 = {
  __typename?: 'ClassroomChannelDto2';
  /** 비활성 구성원 수를 제외한 활성 구성원 수 */
  activeMembersCount: Scalars['Float'];
  archived: Scalars['Boolean'];
  autoJoin: Scalars['Boolean'];
  canMemberReactToPost: Scalars['Boolean'];
  canMemberWriteComment: Scalars['Boolean'];
  canMemberWritePost: Scalars['Boolean'];
  channelGroupId: Maybe<Scalars['String']>;
  channelType: ClassroommutationCreateChannelDtoChannelType;
  classroomId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 게시글 작성 시 사용될 에디터 모드를 결정하는 값. "default" | "presentation" */
  editorMode: Scalars['String'];
  iconUrl: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isCommon: Scalars['Boolean'];
  isDeletable: Scalars['Boolean'];
  isEditable: Scalars['Boolean'];
  isPrivate: Scalars['Boolean'];
  meeting: Maybe<ClassroomMeetingDto>;
  meetingId: Maybe<Scalars['String']>;
  memberIds: Array<Maybe<Scalars['String']>>;
  membersCount: Scalars['Float'];
  name: Scalars['String'];
  purpose: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ClassroomChannelDto3 = {
  __typename?: 'ClassroomChannelDto3';
  /** 비활성 구성원 수를 제외한 활성 구성원 수 */
  activeMembersCount: Scalars['Float'];
  archived: Scalars['Boolean'];
  autoJoin: Scalars['Boolean'];
  canMemberReactToPost: Scalars['Boolean'];
  canMemberWriteComment: Scalars['Boolean'];
  canMemberWritePost: Scalars['Boolean'];
  channelGroupId: Maybe<Scalars['String']>;
  channelType: ClassroommutationCreateChannelDtoChannelType;
  classroomId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 게시글 작성 시 사용될 에디터 모드를 결정하는 값. "default" | "presentation" */
  editorMode: Scalars['String'];
  iconUrl: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isCommon: Scalars['Boolean'];
  isDeletable: Scalars['Boolean'];
  isEditable: Scalars['Boolean'];
  isPrivate: Scalars['Boolean'];
  meeting: Maybe<ClassroomMeetingDto>;
  meetingId: Maybe<Scalars['String']>;
  memberIds: Array<Maybe<Scalars['String']>>;
  membersCount: Scalars['Float'];
  name: Scalars['String'];
  purpose: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ClassroomChannelGroupDto = {
  __typename?: 'ClassroomChannelGroupDto';
  channelIds: Array<Maybe<Scalars['String']>>;
  channels: Array<Maybe<ClassroomChannelDto>>;
  classroomId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  primary: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type ClassroomChannelGroupDto2 = {
  __typename?: 'ClassroomChannelGroupDto2';
  channelIds: Array<Maybe<Scalars['String']>>;
  channels: Array<Maybe<ClassroomChannelDto>>;
  classroomId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  primary: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type ClassroomChannelGroupDto3 = {
  __typename?: 'ClassroomChannelGroupDto3';
  channelIds: Array<Maybe<Scalars['String']>>;
  channels: Array<Maybe<ClassroomChannelDto>>;
  classroomId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  primary: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type ClassroomChannelPostPinDto = {
  __typename?: 'ClassroomChannelPostPinDto';
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  postId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ClassroomChannelPostPinDto2 = {
  __typename?: 'ClassroomChannelPostPinDto2';
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  postId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ClassroomClassCodeDto = {
  __typename?: 'ClassroomClassCodeDto';
  classroomId: Scalars['String'];
  code: Scalars['String'];
  expiresAt: Maybe<Scalars['DateTime']>;
};

export type ClassroomClassroomBookmarkStatusDto = {
  __typename?: 'ClassroomClassroomBookmarkStatusDto';
  bookmarkStatus: Scalars['Boolean'];
  classroomId: Scalars['String'];
  userId: Scalars['String'];
};

export type ClassroomClassroomDto = {
  __typename?: 'ClassroomClassroomDto';
  about: Maybe<Scalars['String']>;
  adminsCount: Scalars['Float'];
  archived: Scalars['Boolean'];
  countryCode: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  grade: ClassroommutationCreateClassroomGrade;
  id: Scalars['String'];
  isLegacy: Maybe<Scalars['Boolean']>;
  legacyClassRelation: Maybe<ClassroomLegacyClassRelation>;
  members: Array<Maybe<ClassroomMember>>;
  membersCount: Scalars['Float'];
  name: Scalars['String'];
  pinnedTagsCount: Scalars['Float'];
  plan: Maybe<Scalars['JSON']>;
  profileImageId: Maybe<Scalars['String']>;
  profileImageUrl: Scalars['String'];
  /**
   * 클래스룸 생성에 관련된 작업들의 진행 상태를 표시,
   *       'in-progress' | 'completed'
   */
  provisioningStatus: Scalars['String'];
  schoolId: Maybe<Scalars['String']>;
  schoolName: Maybe<Scalars['String']>;
  schoolYear: Maybe<Scalars['Int']>;
  tagsCount: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  /**
   * 클래스룸 버전. 정수로 표현됨
   *       - v2: 알림장 공간 및 공지 공간 (공통공간)의 기능 제공
   */
  version: Scalars['Float'];
};

export type ClassroomClassroomFavoriteDto = {
  __typename?: 'ClassroomClassroomFavoriteDto';
  classroom: ClassroomClassroomDto;
  classroomId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  userId: Scalars['String'];
};

export type ClassroomClassroomFavoritesCollectionDto = {
  __typename?: 'ClassroomClassroomFavoritesCollectionDto';
  classroomFavorites: Array<Maybe<ClassroomClassroomFavoriteDto>>;
  cursor: Maybe<Scalars['String']>;
};

export enum ClassroomClassroomGrade {
  Adult = 'adult',
  Eighth = 'eighth',
  Eleventh = 'eleventh',
  Fifth = 'fifth',
  First = 'first',
  Fourth = 'fourth',
  HigherEducation = 'higher_education',
  Kindergarten = 'kindergarten',
  Ninth = 'ninth',
  None = 'none',
  PreKindergarten = 'pre_kindergarten',
  Second = 'second',
  Seventh = 'seventh',
  Sixth = 'sixth',
  Tenth = 'tenth',
  Third = 'third',
  Twelfth = 'twelfth',
}

export type ClassroomClassroomsCollectionDto = {
  __typename?: 'ClassroomClassroomsCollectionDto';
  classrooms: Array<Maybe<ClassroomClassroomDto>>;
  cursor: Maybe<Scalars['String']>;
};

export type ClassroomCommentDto = {
  __typename?: 'ClassroomCommentDto';
  author: ClassroomMemberDto;
  authorId: Scalars['String'];
  classroomId: Scalars['String'];
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  postId: Scalars['String'];
  reactions: Array<Maybe<ClassroomCommentReactionDto>>;
  reactionsCount: Scalars['JSON'];
  repliesCount: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type ClassroomCommentPermissionDto = {
  __typename?: 'ClassroomCommentPermissionDto';
  canDelete: Scalars['Boolean'];
  canEdit: Scalars['Boolean'];
  canReact: Scalars['Boolean'];
  canWriteReply: Scalars['Boolean'];
};

export type ClassroomCommentReactionDto = {
  __typename?: 'ClassroomCommentReactionDto';
  createdAt: Scalars['DateTime'];
  member: Maybe<ClassroomMemberDto>;
  memberId: Scalars['String'];
  name: Scalars['String'];
};

export type ClassroomCommentReactionsCollectionDto = {
  __typename?: 'ClassroomCommentReactionsCollectionDto';
  reactions: Array<Maybe<ClassroomCommentReactionDto>>;
};

export type ClassroomCommentsCollectionDto = {
  __typename?: 'ClassroomCommentsCollectionDto';
  comments: Array<Maybe<ClassroomCommentDto>>;
  cursor: Maybe<Scalars['String']>;
};

export type ClassroomCompleteAssignmentSubmissionDtoInput = {
  assigneeId: Scalars['String'];
};

export type ClassroomCompleteOnboardingChecklistActionDtoInput = {
  action: Scalars['ClassroommutationInputCompleteOnboardingChecklistActionInputAction'];
};

export type ClassroomCreateAssignmentDtoInput = {
  postId: Scalars['String'];
  type: InputMaybe<ClassroomAssignmentType>;
};

export type ClassroomCreateAssignmentSubmissionCommentDtoInput = {
  content: Scalars['ClassroommutationInputCreateAssignmentSubmissionCommentInputContent'];
};

export type ClassroomCreateAttendanceDtoInput = {
  timezone: InputMaybe<Scalars['String']>;
};

export type ClassroomCreateChannelDtoInput = {
  autoJoin: InputMaybe<Scalars['Boolean']>;
  canMemberReactToPost: InputMaybe<Scalars['Boolean']>;
  canMemberWriteComment: InputMaybe<Scalars['Boolean']>;
  canMemberWritePost: InputMaybe<Scalars['Boolean']>;
  channelGroupId: Scalars['String'];
  isPrivate: InputMaybe<Scalars['Boolean']>;
  memberIds: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: Scalars['ClassroommutationInputCreateChannelInputName'];
  purpose: InputMaybe<
    Scalars['ClassroommutationInputCreateChannelInputPurpose']
  >;
};

export type ClassroomCreateChannelGroupDtoInput = {
  name: Scalars['ClassroommutationInputCreateChannelGroupInputName'];
};

export type ClassroomCreateClassroomDtoInput = {
  about: InputMaybe<Scalars['ClassroommutationInputCreateClassroomInputAbout']>;
  grade: InputMaybe<ClassroommutationInputCreateClassroomInputGrade>;
  name: Scalars['ClassroommutationInputCreateClassroomInputName'];
  schoolId: InputMaybe<Scalars['String']>;
  schoolYear: InputMaybe<Scalars['Int']>;
};

export type ClassroomCreateCommentDtoInput = {
  content: Scalars['ClassroommutationInputCreateCommentInputContent'];
};

export type ClassroomCreatePostDtoInput = {
  isAssignment: Scalars['Boolean'];
};

export type ClassroomCreateReplyDtoInput = {
  content: Scalars['ClassroommutationInputCreateReplyInputContent'];
};

export type ClassroomCreateTagDtoInput = {
  name: Scalars['ClassroommutationInputCreateTagInputName'];
};

export type ClassroomExcelExportJobStateDto = {
  __typename?: 'ClassroomExcelExportJobStateDto';
  createdAt: Scalars['DateTime'];
  /** 내려받기 작업 상태가 제거될 시각 */
  deleteAt: Maybe<Scalars['DateTime']>;
  /** 생성된 파일을 다운받을 경로. 내려받기 상태가 완료 시 반환됨 */
  downloadUrl: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** 내려받기 진행 상태를 나타내는 상태값. "processing" | "success" | "failure" */
  jobStatus: Scalars['String'];
  numOfCompletedTasks: Scalars['Float'];
  numOfTotalTasks: Scalars['Float'];
  /** 내려받기 진행도를 나타내는 값. 백분율로 표시 */
  progress: Scalars['Float'];
};

export type ClassroomExcuseAttendanceDtoInput = {
  attendedAt: Scalars['String'];
  attendeeId: Scalars['String'];
  memo: InputMaybe<Scalars['ClassroommutationInputExcuseAttendanceInputMemo']>;
  timezone: InputMaybe<Scalars['String']>;
};

export type ClassroomFriendDto = {
  __typename?: 'ClassroomFriendDto';
  active: Scalars['Boolean'];
  blocked: Scalars['JSON'];
  canStartMeeting: Maybe<Scalars['Boolean']>;
  classroomId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  group: Maybe<ClassroommutationRegisterForClassroomGroup>;
  id: Scalars['String'];
  name: Maybe<Scalars['String']>;
  profileImageId: Maybe<Scalars['String']>;
  profileImageUrl: Maybe<Scalars['String']>;
  rank: Maybe<Scalars['Int']>;
  role: ClassroommutationRegisterForClassroomRole;
  roleId: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ClassroomFriendshipCollectionDto = {
  __typename?: 'ClassroomFriendshipCollectionDto';
  classroomId: Scalars['String'];
  friendshipWeightAverage: Scalars['Float'];
  friendships: Array<Maybe<Friendship>>;
};

export enum ClassroomGradingStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
}

export type ClassroomImageDto = {
  __typename?: 'ClassroomImageDto';
  categoryId: Scalars['String'];
  categoryType: Scalars['String'];
  contentType: Scalars['String'];
  createdAt: Scalars['String'];
  filePath: Scalars['String'];
  id: Scalars['String'];
  originalFileName: Scalars['String'];
  totalSize: Scalars['Float'];
  updatedAt: Scalars['String'];
  urls: Scalars['JSON'];
  userId: Scalars['String'];
};

export type ClassroomInitiateExportAttendancesDtoInput = {
  fileName: Scalars['String'];
  /** comma separated string으로 월 지정. 예시) "1,2" */
  months: Scalars['String'];
  timezone: InputMaybe<Scalars['String']>;
  year: Scalars['PositiveFloat'];
};

export type ClassroomIntegrateZoomDtoInput = {
  code: Scalars['String'];
  redirectUri: Scalars['String'];
};

export type ClassroomInviteMembersDtoInput = {
  phoneNumbers: Array<InputMaybe<Scalars['String']>>;
};

export enum ClassroomLearningCardPageType {
  Essay = 'ESSAY',
  MultipleChoice = 'MULTIPLE_CHOICE',
  NonQuiz = 'NON_QUIZ',
  TextEntry = 'TEXT_ENTRY',
}

export type ClassroomLearningSubmissionDto = {
  __typename?: 'ClassroomLearningSubmissionDto';
  answers: Array<Maybe<ClassroomLearningUserAnswerDto>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ClassroomLearningUserAnswerDto = {
  __typename?: 'ClassroomLearningUserAnswerDto';
  correctOptions: Maybe<Array<Maybe<Scalars['JSON']>>>;
  duration: Scalars['Float'];
  essay: Maybe<Scalars['String']>;
  isCorrect: Maybe<Scalars['Boolean']>;
  maxPoints: Scalars['Float'];
  multipleChoiceOption: Maybe<Scalars['JSON']>;
  pageId: Scalars['Float'];
  pageIndex: Scalars['Float'];
  pageType: ClassroomLearningCardPageType;
  points: Scalars['Float'];
  textEntry: Maybe<Scalars['String']>;
  userInput: Scalars['String'];
};

export enum ClassroomLegacyClassRelation {
  Invited = 'invited',
  Joined = 'joined',
  Waiting = 'waiting',
}

export enum ClassroomListMemberClassroomFilter {
  Active = 'active',
  Archived = 'archived',
}

export enum ClassroomListMyClassroomFilter {
  Active = 'active',
  Archived = 'archived',
}

export type ClassroomMeeting = {
  __typename?: 'ClassroomMeeting';
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
  id: Scalars['String'];
  memberId: Scalars['String'];
  status: ClassroomStatus;
  zoomMeeting: Scalars['JSON'];
  zoomSignature: Scalars['String'];
};

export type ClassroomMeetingDto = {
  __typename?: 'ClassroomMeetingDto';
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
  id: Scalars['String'];
  memberId: Scalars['String'];
  status: ClassroomStatus;
  zoomMeeting: Scalars['JSON'];
  zoomSignature: Scalars['String'];
};

export type ClassroomMember = {
  __typename?: 'ClassroomMember';
  active: Scalars['Boolean'];
  blocked: Scalars['JSON'];
  canStartMeeting: Maybe<Scalars['Boolean']>;
  classroomId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  group: Maybe<ClassroommutationCreateClassroomMembersItemsGroup>;
  id: Scalars['String'];
  name: Maybe<Scalars['String']>;
  profileImageId: Maybe<Scalars['String']>;
  profileImageUrl: Maybe<Scalars['String']>;
  role: ClassroommutationCreateClassroomMembersItemsRole;
  roleId: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ClassroomMemberAttendancesCollectionDto = {
  __typename?: 'ClassroomMemberAttendancesCollectionDto';
  attendances: Array<Maybe<ClassroomAttendanceDto>>;
};

export type ClassroomMemberDto = {
  __typename?: 'ClassroomMemberDto';
  active: Scalars['Boolean'];
  blocked: Scalars['Boolean'];
  canStartMeeting: Maybe<Scalars['Boolean']>;
  classroomId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  group: Maybe<ClassroommutationRegisterForClassroomGroup>;
  id: Scalars['String'];
  isProvisioningCompleted: Scalars['Boolean'];
  name: Maybe<Scalars['String']>;
  profileImageId: Maybe<Scalars['String']>;
  profileImageUrl: Maybe<Scalars['String']>;
  role: ClassroommutationRegisterForClassroomRole;
  roleId: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ClassroomMemberDto2 = {
  __typename?: 'ClassroomMemberDto2';
  active: Scalars['Boolean'];
  blocked: Scalars['Boolean'];
  canStartMeeting: Maybe<Scalars['Boolean']>;
  classroomId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  group: Maybe<ClassroommutationRegisterForClassroomGroup>;
  id: Scalars['String'];
  isProvisioningCompleted: Scalars['Boolean'];
  name: Maybe<Scalars['String']>;
  profileImageId: Maybe<Scalars['String']>;
  profileImageUrl: Maybe<Scalars['String']>;
  role: ClassroommutationRegisterForClassroomRole;
  roleId: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ClassroomMemberDto3 = {
  __typename?: 'ClassroomMemberDto3';
  active: Scalars['Boolean'];
  blocked: Scalars['Boolean'];
  canStartMeeting: Maybe<Scalars['Boolean']>;
  classroomId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  group: Maybe<ClassroommutationRegisterForClassroomGroup>;
  id: Scalars['String'];
  isProvisioningCompleted: Scalars['Boolean'];
  name: Maybe<Scalars['String']>;
  profileImageId: Maybe<Scalars['String']>;
  profileImageUrl: Maybe<Scalars['String']>;
  role: ClassroommutationRegisterForClassroomRole;
  roleId: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ClassroomMemberDto4 = {
  __typename?: 'ClassroomMemberDto4';
  active: Scalars['Boolean'];
  blocked: Scalars['Boolean'];
  canStartMeeting: Maybe<Scalars['Boolean']>;
  classroomId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  group: Maybe<ClassroommutationRegisterForClassroomGroup>;
  id: Scalars['String'];
  isProvisioningCompleted: Scalars['Boolean'];
  name: Maybe<Scalars['String']>;
  profileImageId: Maybe<Scalars['String']>;
  profileImageUrl: Maybe<Scalars['String']>;
  role: ClassroommutationRegisterForClassroomRole;
  roleId: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ClassroomMemberDto5 = {
  __typename?: 'ClassroomMemberDto5';
  active: Scalars['Boolean'];
  blocked: Scalars['Boolean'];
  canStartMeeting: Maybe<Scalars['Boolean']>;
  classroomId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  group: Maybe<ClassroommutationRegisterForClassroomGroup>;
  id: Scalars['String'];
  isProvisioningCompleted: Scalars['Boolean'];
  name: Maybe<Scalars['String']>;
  profileImageId: Maybe<Scalars['String']>;
  profileImageUrl: Maybe<Scalars['String']>;
  role: ClassroommutationRegisterForClassroomRole;
  roleId: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ClassroomMemberDto6 = {
  __typename?: 'ClassroomMemberDto6';
  active: Scalars['Boolean'];
  blocked: Scalars['Boolean'];
  canStartMeeting: Maybe<Scalars['Boolean']>;
  classroomId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  group: Maybe<ClassroommutationRegisterForClassroomGroup>;
  id: Scalars['String'];
  isProvisioningCompleted: Scalars['Boolean'];
  name: Maybe<Scalars['String']>;
  profileImageId: Maybe<Scalars['String']>;
  profileImageUrl: Maybe<Scalars['String']>;
  role: ClassroommutationRegisterForClassroomRole;
  roleId: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export enum ClassroomMemberGroup {
  Guardian = 'guardian',
  Student = 'student',
  Teacher = 'teacher',
}

export type ClassroomMemberPushNotificationSettingsDto = {
  __typename?: 'ClassroomMemberPushNotificationSettingsDto';
  id: Scalars['String'];
  pushNotificationSettings: ClassroommutationUpdateMemberPushNotificationSettingsPushNotificationSettings;
};

export enum ClassroomMemberRole {
  Admin = 'admin',
  Member = 'member',
}

export type ClassroomMembersCollectionDto = {
  __typename?: 'ClassroomMembersCollectionDto';
  cursor: Maybe<Scalars['String']>;
  members: Array<Maybe<ClassroomMemberDto>>;
};

export type ClassroomMyClassroomsCollectionDto = {
  __typename?: 'ClassroomMyClassroomsCollectionDto';
  classrooms: Array<Maybe<ClassroomClassroomDto>>;
};

export type ClassroomOnboardingChecklistDto = {
  __typename?: 'ClassroomOnboardingChecklistDto';
  /**
   * 해당 클래스룸에서 보여질 수 있는 순서가 정의된 스탭들,
   *     허용되는 값들:
   *       | 'invite-member'
   *       | 'publish-post'
   *       | 'distribute-ai-test'
   *       | 'visit-product-tour-page'
   */
  availableActions: Array<Maybe<Scalars['String']>>;
  classroomId: Scalars['String'];
  id: Scalars['String'];
  /** 체크리스트를 노출할지 여부 */
  isActive: Scalars['Boolean'];
  /** 체크리스트의 모든 스텝이 완료되었는지 여부 */
  isComplete: Scalars['Boolean'];
  progress: Scalars['JSON'];
};

export type ClassroomPinPostDtoInput = {
  postId: Scalars['String'];
};

export type ClassroomPostDto = {
  __typename?: 'ClassroomPostDto';
  adminExclusive: Scalars['Boolean'];
  announcedAt: Maybe<Scalars['DateTime']>;
  assignmentId: Maybe<Scalars['String']>;
  author: ClassroomMemberDto;
  authorId: Scalars['String'];
  channelId: Maybe<Scalars['String']>;
  classroomId: Scalars['String'];
  commentsCount: Scalars['Float'];
  content: Maybe<Scalars['String']>;
  coverImageUrl: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  fileIds: Array<Maybe<Scalars['String']>>;
  id: Scalars['String'];
  imageIds: Array<Maybe<Scalars['String']>>;
  images: Maybe<Array<Maybe<ClassroomImageDto>>>;
  isAnnouncement: Scalars['Boolean'];
  isPinned: Scalars['Boolean'];
  lastNotifiedAt: Maybe<Scalars['DateTime']>;
  publishedAt: Maybe<Scalars['DateTime']>;
  reactions: Array<Maybe<ClassroomPostReactionDto>>;
  reactionsCount: Scalars['JSON'];
  readsCount: Scalars['Float'];
  repliesCount: Scalars['Float'];
  reporterIds: Maybe<Array<Maybe<Scalars['String']>>>;
  scheduledAt: Maybe<Scalars['DateTime']>;
  status: ClassroommutationCreatePostStatus;
  summary: Maybe<Scalars['String']>;
  tags: Array<Maybe<Scalars['String']>>;
  title: Maybe<Scalars['String']>;
  type: ClassroomPostType;
  updatedAt: Scalars['DateTime'];
  videoIds: Array<Maybe<Scalars['String']>>;
  videos: Maybe<Array<Maybe<ClassroomVideoDto>>>;
};

export type ClassroomPostPermissionDto = {
  __typename?: 'ClassroomPostPermissionDto';
  canDelete: Scalars['Boolean'];
  canEdit: Scalars['Boolean'];
  canMarkAsAnnouncement: Scalars['Boolean'];
  canMarkAsRead: Scalars['Boolean'];
  canPin: Scalars['Boolean'];
  canReact: Scalars['Boolean'];
  canReport: Scalars['Boolean'];
  canWriteComment: Scalars['Boolean'];
  postId: Maybe<Scalars['String']>;
};

export type ClassroomPostReactionDto = {
  __typename?: 'ClassroomPostReactionDto';
  createdAt: Scalars['DateTime'];
  member: Maybe<ClassroomMemberDto>;
  memberId: Scalars['String'];
  name: Scalars['String'];
};

export type ClassroomPostReactionsCollectionDto = {
  __typename?: 'ClassroomPostReactionsCollectionDto';
  reactions: Array<Maybe<ClassroomPostReactionDto>>;
};

export type ClassroomPostReadRateByMemberDto = {
  __typename?: 'ClassroomPostReadRateByMemberDto';
  memberId: Scalars['String'];
  memberName: Scalars['String'];
  postCount: Scalars['Int'];
  postReadCount: Scalars['Int'];
  postReadRate: Scalars['Int'];
};

export type ClassroomPostReadRateByPostDto = {
  __typename?: 'ClassroomPostReadRateByPostDto';
  postCreatedAt: Scalars['DateTime'];
  postId: Scalars['String'];
  postReadRate: Scalars['Int'];
  postTitle: Maybe<Scalars['String']>;
  postUrl: Scalars['String'];
};

export type ClassroomPostReadRateDto = {
  __typename?: 'ClassroomPostReadRateDto';
  classroomId: Scalars['String'];
  date: Scalars['DateTime'];
  postReadRate: Scalars['Float'];
};

export type ClassroomPostReadRatesByMemberCollectionDto = {
  __typename?: 'ClassroomPostReadRatesByMemberCollectionDto';
  classroomId: Scalars['String'];
  endDate: Scalars['DateTime'];
  postReadRates: Array<Maybe<ClassroomPostReadRateByMemberDto>>;
  startDate: Scalars['DateTime'];
  totalMemberCount: Scalars['Int'];
};

export type ClassroomPostReadRatesByPostCollectionDto = {
  __typename?: 'ClassroomPostReadRatesByPostCollectionDto';
  classroomId: Scalars['String'];
  endDate: Scalars['DateTime'];
  postReadRates: Array<Maybe<ClassroomPostReadRateByPostDto>>;
  startDate: Scalars['DateTime'];
  totalPostCount: Scalars['Int'];
};

export type ClassroomPostReadStatusDto = {
  __typename?: 'ClassroomPostReadStatusDto';
  postId: Scalars['String'];
  readStatus: ClassroomqueryGetPostReadStatusReadStatus;
};

export enum ClassroomPostType {
  Announcement = 'ANNOUNCEMENT',
  Assignment = 'ASSIGNMENT',
  Post = 'POST',
}

export type ClassroomPostsCollectionDto = {
  __typename?: 'ClassroomPostsCollectionDto';
  cursor: Maybe<Scalars['String']>;
  posts: Array<Maybe<ClassroomPostDto>>;
};

export type ClassroomPostsSearchCollectionDto = {
  __typename?: 'ClassroomPostsSearchCollectionDto';
  page: Maybe<Scalars['Float']>;
  perPage: Maybe<Scalars['Float']>;
  posts: Array<Maybe<ClassroomPostDto>>;
  totalPages: Maybe<Scalars['Float']>;
  totalResults: Maybe<Scalars['Float']>;
};

export type ClassroomPrivateCreateClassroomDtoInput = {
  about: InputMaybe<
    Scalars['ClassroommutationInputPrivateCreateClassroomInputAbout']
  >;
  adminId: Scalars['String'];
  grade: InputMaybe<ClassroommutationInputPrivateCreateClassroomInputGrade>;
  name: Scalars['ClassroommutationInputPrivateCreateClassroomInputName'];
  schoolId: InputMaybe<Scalars['String']>;
  schoolYear: InputMaybe<Scalars['Int']>;
};

export type ClassroomPrivateRegisterDtoInput = {
  group: InputMaybe<ClassroomMemberGroup>;
  name: Scalars['ClassroommutationInputPrivateRegisterForClassroomInputName'];
  profileImageId: InputMaybe<Scalars['String']>;
  profileImageUrl: InputMaybe<Scalars['String']>;
  role: ClassroomMemberRole;
  userId: Scalars['String'];
};

export type ClassroomRegisterDtoInput = {
  classCode: Scalars['String'];
  group: InputMaybe<ClassroommutationInputRegisterForClassroomInputGroup>;
  name: Scalars['ClassroommutationInputRegisterForClassroomInputName'];
  profileImageId: InputMaybe<Scalars['String']>;
  profileImageUrl: InputMaybe<Scalars['String']>;
};

export type ClassroomRegradeAssignmentSubmissionDtoInput = {
  points: Scalars['NonNegativeFloat'];
};

export type ClassroomRemoveReactionFromCommentDtoInput = {
  name: Scalars['String'];
};

export type ClassroomRemoveReactionFromPostDtoInput = {
  name: Scalars['String'];
};

export type ClassroomRemoveReactionFromReplyDtoInput = {
  name: Scalars['String'];
};

export type ClassroomRepliesCollectionDto = {
  __typename?: 'ClassroomRepliesCollectionDto';
  cursor: Maybe<Scalars['String']>;
  replies: Array<Maybe<ClassroomReplyDto>>;
};

export type ClassroomReplyDto = {
  __typename?: 'ClassroomReplyDto';
  author: ClassroomMemberDto;
  authorId: Scalars['String'];
  commentId: Scalars['String'];
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  postId: Scalars['String'];
  reactions: Array<Maybe<ClassroomReplyReactionDto>>;
  reactionsCount: Scalars['JSON'];
  updatedAt: Scalars['DateTime'];
};

export type ClassroomReplyPermissionDto = {
  __typename?: 'ClassroomReplyPermissionDto';
  canDelete: Scalars['Boolean'];
  canEdit: Scalars['Boolean'];
  canReact: Scalars['Boolean'];
};

export type ClassroomReplyReactionDto = {
  __typename?: 'ClassroomReplyReactionDto';
  createdAt: Scalars['DateTime'];
  member: Maybe<ClassroomMemberDto>;
  memberId: Scalars['String'];
  name: Scalars['String'];
};

export type ClassroomReplyReactionsCollectionDto = {
  __typename?: 'ClassroomReplyReactionsCollectionDto';
  reactions: Array<Maybe<ClassroomReplyReactionDto>>;
};

export type ClassroomReportPostDtoInput = {
  reason: InputMaybe<Scalars['ClassroommutationInputReportPostInputReason']>;
};

export enum ClassroomRoleCategory {
  Admin = 'ADMIN',
  Member = 'MEMBER',
  Moderator = 'MODERATOR',
  Visitor = 'VISITOR',
}

export type ClassroomRoleDto = {
  __typename?: 'ClassroomRoleDto';
  category: ClassroomRoleCategory;
  classroomId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  /** 역할을 지칭하기 위한 수정 가능한 이름 */
  name: Scalars['String'];
  /** 목록내의 정렬순서를 나타냄 */
  position: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type ClassroomSaveAssignmentSubmissionPointsDtoInput = {
  points: InputMaybe<Scalars['NonNegativeFloat']>;
};

export type ClassroomSaveEssayAnswerPointsToLearningAssignmentDtoInput = {
  pageId: Scalars['Float'];
  points: InputMaybe<Scalars['NonNegativeFloat']>;
};

export type ClassroomSaveMemberAnswerToLearningAssignmentDtoInput = {
  correctOptions: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  duration: Scalars['Float'];
  essay: InputMaybe<Scalars['String']>;
  isCorrect: InputMaybe<Scalars['Boolean']>;
  maxPoints: InputMaybe<Scalars['NonNegativeFloat']>;
  multipleChoiceOption: InputMaybe<Scalars['JSON']>;
  pageId: Scalars['Float'];
  pageIndex: Scalars['Float'];
  pageType: ClassroomLearningCardPageType;
  textEntry: InputMaybe<Scalars['String']>;
  userInput: InputMaybe<Scalars['String']>;
};

export type ClassroomSavePostAsDraftDtoInput = {
  adminExclusive: InputMaybe<Scalars['Boolean']>;
  channelId: InputMaybe<Scalars['String']>;
  content: InputMaybe<
    Scalars['ClassroommutationInputSavePostAsDraftInputContent']
  >;
  fileIds: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imageIds: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isAnnouncement: InputMaybe<Scalars['Boolean']>;
  scheduledAt: InputMaybe<Scalars['String']>;
  tags: InputMaybe<
    Array<
      InputMaybe<Scalars['ClassroommutationInputSavePostAsDraftInputTagsItems']>
    >
  >;
  title: InputMaybe<Scalars['ClassroommutationInputSavePostAsDraftInputTitle']>;
  videoIds: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum ClassroomStatus {
  Ended = 'ended',
  Started = 'started',
  Waiting = 'waiting',
}

export type ClassroomSubmissionDto = {
  __typename?: 'ClassroomSubmissionDto';
  createdAt: Scalars['DateTime'];
  fileIds: Array<Maybe<Scalars['String']>>;
  id: Scalars['String'];
  imageIds: Array<Maybe<Scalars['String']>>;
  text: Scalars['String'];
};

export type ClassroomSubmissionDto2 = {
  __typename?: 'ClassroomSubmissionDto2';
  createdAt: Scalars['DateTime'];
  fileIds: Array<Maybe<Scalars['String']>>;
  id: Scalars['String'];
  imageIds: Array<Maybe<Scalars['String']>>;
  text: Scalars['String'];
};

export type ClassroomSubmitSubmissionDtoInput = {
  fileIds: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imageIds: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  text: InputMaybe<Scalars['String']>;
};

export type ClassroomTagDto = {
  __typename?: 'ClassroomTagDto';
  classroomId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  pinned: Scalars['Boolean'];
  postsCount: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type ClassroomTagDto2 = {
  __typename?: 'ClassroomTagDto2';
  classroomId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  pinned: Scalars['Boolean'];
  postsCount: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type ClassroomTranslatePostDtoInput = {
  targetLanguage: Scalars['String'];
};

export type ClassroomTranslatedPostDto = {
  __typename?: 'ClassroomTranslatedPostDto';
  classroomId: Scalars['String'];
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  postId: Scalars['String'];
  targetLanguage: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ClassroomTranslationPermissionDto = {
  __typename?: 'ClassroomTranslationPermissionDto';
  canTranslatePost: Scalars['Boolean'];
  memberId: Scalars['String'];
};

export type ClassroomUnpinPostDtoInput = {
  postId: Scalars['String'];
};

export type ClassroomUpdateAssignedAssignmentDtoInput = {
  dueDateTime: InputMaybe<Scalars['String']>;
  maxPoints: InputMaybe<Scalars['NonNegativeFloat']>;
};

export type ClassroomUpdateAssignmentDtoInput = {
  channelId: InputMaybe<Scalars['String']>;
  content: InputMaybe<Scalars['String']>;
  dueDateTime: Scalars['String'];
  maxPoints: Scalars['NonNegativeFloat'];
};

export type ClassroomUpdateAssignmentSubmissionCommentDtoInput = {
  content: Scalars['ClassroommutationInputUpdateAssignmentSubmissionCommentInputContent'];
};

export type ClassroomUpdateChannelDtoInput = {
  autoJoin: InputMaybe<Scalars['Boolean']>;
  canMemberReactToPost: InputMaybe<Scalars['Boolean']>;
  canMemberWriteComment: InputMaybe<Scalars['Boolean']>;
  canMemberWritePost: InputMaybe<Scalars['Boolean']>;
  channelGroupId: InputMaybe<Scalars['String']>;
  isPrivate: InputMaybe<Scalars['Boolean']>;
  memberIds: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: InputMaybe<Scalars['ClassroommutationInputUpdateChannelInputName']>;
  purpose: InputMaybe<
    Scalars['ClassroommutationInputUpdateChannelInputPurpose']
  >;
};

export type ClassroomUpdateChannelGroupDtoInput = {
  name: InputMaybe<
    Scalars['ClassroommutationInputUpdateChannelGroupInputName']
  >;
};

export type ClassroomUpdateClassroomDtoInput = {
  about: InputMaybe<Scalars['ClassroommutationInputUpdateClassroomInputAbout']>;
  grade: InputMaybe<ClassroommutationInputUpdateClassroomInputGrade>;
  name: InputMaybe<Scalars['ClassroommutationInputUpdateClassroomInputName']>;
  profileImageId: InputMaybe<Scalars['String']>;
  profileImageUrl: InputMaybe<Scalars['String']>;
  schoolId: InputMaybe<Scalars['String']>;
  schoolYear: InputMaybe<Scalars['Int']>;
};

export type ClassroomUpdateCommentDtoInput = {
  content: Scalars['ClassroommutationInputUpdateCommentInputContent'];
};

export type ClassroomUpdateMemberDtoInput = {
  group: InputMaybe<ClassroommutationInputUpdateClassroomMemberInputGroup>;
  name: InputMaybe<
    Scalars['ClassroommutationInputUpdateClassroomMemberInputName']
  >;
  profileImageId: InputMaybe<Scalars['String']>;
  profileImageUrl: InputMaybe<Scalars['String']>;
};

export type ClassroomUpdateMemberPushNotificationSettingsDtoInput = {
  announcementsEnabled: InputMaybe<Scalars['Boolean']>;
  commentsAndRepliesEnabled: InputMaybe<Scalars['Boolean']>;
  postCommentsAndRepliesEnabled: InputMaybe<Scalars['Boolean']>;
  postReactionsEnabled: InputMaybe<Scalars['Boolean']>;
  postsEnabled: InputMaybe<Scalars['Boolean']>;
  reactionsEnabled: InputMaybe<Scalars['Boolean']>;
};

export type ClassroomUpdatePostAdminExclusiveByAdminDtoInput = {
  adminExclusive: Scalars['Boolean'];
};

export type ClassroomUpdatePostChannelAdminDtoInput = {
  channelId: Scalars['String'];
};

export type ClassroomUpdatePostDtoInput = {
  adminExclusive: InputMaybe<Scalars['Boolean']>;
  channelId: InputMaybe<Scalars['String']>;
  content: InputMaybe<Scalars['String']>;
  fileIds: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imageIds: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isAnnouncement: InputMaybe<Scalars['Boolean']>;
  tags: InputMaybe<
    Array<InputMaybe<Scalars['ClassroommutationInputUpdatePostInputTagsItems']>>
  >;
  title: InputMaybe<Scalars['ClassroommutationInputUpdatePostInputTitle']>;
  videoIds: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ClassroomUpdateReplyDtoInput = {
  content: Scalars['ClassroommutationInputUpdateReplyInputContent'];
};

export type ClassroomUpdateTagDtoInput = {
  pinned: InputMaybe<Scalars['Boolean']>;
};

export type ClassroomVideoDto = {
  __typename?: 'ClassroomVideoDto';
  categoryId: Scalars['String'];
  categoryType: Scalars['String'];
  contentType: Scalars['String'];
  conversionStatus: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  filePath: Scalars['String'];
  id: Scalars['String'];
  originalFileName: Scalars['String'];
  posterUrl: Maybe<Scalars['String']>;
  streamUrl: Maybe<Scalars['String']>;
  totalSize: Scalars['Float'];
  updatedAt: Scalars['String'];
  urls: Scalars['JSON'];
  userId: Scalars['String'];
};

export type ClassroomVisitRateDto = {
  __typename?: 'ClassroomVisitRateDto';
  classroomId: Scalars['String'];
  visitRate: Scalars['Float'];
};

export type ClassroomZoomIntegrationDto = {
  __typename?: 'ClassroomZoomIntegrationDto';
  accessToken: Scalars['String'];
  expiresIn: Scalars['Float'];
  memberId: Scalars['String'];
  refreshToken: Scalars['String'];
  scope: Scalars['String'];
  tokenType: Scalars['String'];
  userId: Scalars['String'];
};

export enum ClassroommutationCreateAttendanceStatus {
  Absent = 'absent',
  Attended = 'attended',
  Excused = 'excused',
  Unknown = 'unknown',
}

export enum ClassroommutationCreateChannelDtoChannelType {
  Announcement = 'ANNOUNCEMENT',
  Legacy = 'LEGACY',
  Post = 'POST',
}

export enum ClassroommutationCreateChannelType {
  Announcement = 'ANNOUNCEMENT',
  Legacy = 'LEGACY',
  Post = 'POST',
}

export enum ClassroommutationCreateClassroomGrade {
  Adult = 'adult',
  Eighth = 'eighth',
  Eleventh = 'eleventh',
  Fifth = 'fifth',
  First = 'first',
  Fourth = 'fourth',
  HigherEducation = 'higher_education',
  Kindergarten = 'kindergarten',
  Ninth = 'ninth',
  None = 'none',
  PreKindergarten = 'pre_kindergarten',
  Second = 'second',
  Seventh = 'seventh',
  Sixth = 'sixth',
  Tenth = 'tenth',
  Third = 'third',
  Twelfth = 'twelfth',
}

export enum ClassroommutationCreateClassroomMembersItemsGroup {
  Guardian = 'guardian',
  Student = 'student',
  Teacher = 'teacher',
}

export enum ClassroommutationCreateClassroomMembersItemsRole {
  Admin = 'admin',
  Member = 'member',
}

export enum ClassroommutationCreatePostStatus {
  Created = 'created',
  Drafted = 'drafted',
  Published = 'published',
  Scheduled = 'scheduled',
}

export enum ClassroommutationInputAssignMemberRoleInputRole {
  Admin = 'admin',
  Member = 'member',
}

export enum ClassroommutationInputCreateClassroomInputGrade {
  Adult = 'adult',
  Eighth = 'eighth',
  Eleventh = 'eleventh',
  Fifth = 'fifth',
  First = 'first',
  Fourth = 'fourth',
  HigherEducation = 'higher_education',
  Kindergarten = 'kindergarten',
  Ninth = 'ninth',
  None = 'none',
  PreKindergarten = 'pre_kindergarten',
  Second = 'second',
  Seventh = 'seventh',
  Sixth = 'sixth',
  Tenth = 'tenth',
  Third = 'third',
  Twelfth = 'twelfth',
}

export enum ClassroommutationInputPrivateCreateClassroomInputGrade {
  Adult = 'adult',
  Eighth = 'eighth',
  Eleventh = 'eleventh',
  Fifth = 'fifth',
  First = 'first',
  Fourth = 'fourth',
  HigherEducation = 'higher_education',
  Kindergarten = 'kindergarten',
  Ninth = 'ninth',
  None = 'none',
  PreKindergarten = 'pre_kindergarten',
  Second = 'second',
  Seventh = 'seventh',
  Sixth = 'sixth',
  Tenth = 'tenth',
  Third = 'third',
  Twelfth = 'twelfth',
}

export enum ClassroommutationInputRegisterForClassroomInputGroup {
  Guardian = 'guardian',
  Student = 'student',
  Teacher = 'teacher',
}

export enum ClassroommutationInputUpdateClassroomInputGrade {
  Adult = 'adult',
  Eighth = 'eighth',
  Eleventh = 'eleventh',
  Fifth = 'fifth',
  First = 'first',
  Fourth = 'fourth',
  HigherEducation = 'higher_education',
  Kindergarten = 'kindergarten',
  Ninth = 'ninth',
  None = 'none',
  PreKindergarten = 'pre_kindergarten',
  Second = 'second',
  Seventh = 'seventh',
  Sixth = 'sixth',
  Tenth = 'tenth',
  Third = 'third',
  Twelfth = 'twelfth',
}

export enum ClassroommutationInputUpdateClassroomMemberInputGroup {
  Guardian = 'guardian',
  Student = 'student',
  Teacher = 'teacher',
}

export enum ClassroommutationRegisterForClassroomGroup {
  Guardian = 'guardian',
  Student = 'student',
  Teacher = 'teacher',
}

export enum ClassroommutationRegisterForClassroomRole {
  Admin = 'admin',
  Member = 'member',
}

export type ClassroommutationUpdateMemberPushNotificationSettingsPushNotificationSettings =
  {
    __typename?: 'ClassroommutationUpdateMemberPushNotificationSettingsPushNotificationSettings';
    announcementsEnabled: Maybe<Scalars['Boolean']>;
    commentsAndRepliesEnabled: Maybe<Scalars['Boolean']>;
    postCommentsAndRepliesEnabled: Maybe<Scalars['Boolean']>;
    postReactionsEnabled: Maybe<Scalars['Boolean']>;
    postsEnabled: Maybe<Scalars['Boolean']>;
    reactionsEnabled: Maybe<Scalars['Boolean']>;
  };

export enum ClassroomqueryGetPostReadStatusReadStatus {
  Read = 'read',
  Unread = 'unread',
}

export enum ClassroomqueryInputListChannelGroupsDtoScope {
  All = 'all',
  Joined = 'joined',
}

export enum ClassroomqueryInputListChannelGroupsScope {
  All = 'all',
  Joined = 'joined',
}

export enum ClassroomqueryInputListNewsfeedPostsStatus {
  Drafted = 'drafted',
  Published = 'published',
  Scheduled = 'scheduled',
}

export enum ClassroomqueryInputListPostDraftsPostType {
  Announcement = 'ANNOUNCEMENT',
  Assignment = 'ASSIGNMENT',
  Post = 'POST',
}

export enum ClassroomqueryInputListPostSchedulesPostType {
  Announcement = 'ANNOUNCEMENT',
  Assignment = 'ASSIGNMENT',
  Post = 'POST',
}

export enum ClassroomqueryInputListPostsStatus {
  Drafted = 'drafted',
  Published = 'published',
  Scheduled = 'scheduled',
}

export type CreateCumulativeRecordDtoInput = {
  contents: Scalars['String'];
  recordedAt: Scalars['DateTime'];
};

export type CreateFreePlanSubscriptionDtoInput = {
  childIds: Array<InputMaybe<Scalars['String']>>;
  endAt: Scalars['DateTime'];
  ordererId: Scalars['String'];
  packageName: Scalars['String'];
  period: InputMaybe<Scalars['String']>;
  productId: Scalars['String'];
  startAt: Scalars['DateTime'];
};

export type CreateGoalDtoInput = {
  reward: InputMaybe<Scalars['String']>;
  targetStampCount: Scalars['Float'];
};

export type CumulativeRecordDto = {
  __typename?: 'CumulativeRecordDto';
  adminId: Scalars['String'];
  classroomId: Scalars['String'];
  contents: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  memberId: Scalars['String'];
  recordedAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CumulativeRecordDto2 = {
  __typename?: 'CumulativeRecordDto2';
  adminId: Scalars['String'];
  classroomId: Scalars['String'];
  contents: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  memberId: Scalars['String'];
  recordedAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CumulativeRecordsCollectionDto = {
  __typename?: 'CumulativeRecordsCollectionDto';
  cumulativeRecords: Array<Maybe<CumulativeRecordDto>>;
  cursor: Maybe<Scalars['String']>;
};

export type EstimatedOrderDto = {
  __typename?: 'EstimatedOrderDto';
  currencyCode: Scalars['String'];
  price: Scalars['Float'];
};

export type Friend = {
  __typename?: 'Friend';
  avatarImage: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  profileImage: Maybe<Scalars['String']>;
  rank: Maybe<Scalars['Int']>;
  role: AccountRole;
  username: Maybe<Scalars['String']>;
};

export type Friendship = {
  __typename?: 'Friendship';
  friendCountLevel: Scalars['Int'];
  friendshipActivityCount: Scalars['Int'];
  friendshipWeightList: Array<Maybe<FriendshipWeight>>;
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  profileImage: Maybe<Scalars['String']>;
  rank: Scalars['Int'];
};

export type FriendshipWeight = {
  __typename?: 'FriendshipWeight';
  friendshipWeightLevel: Scalars['Int'];
  id: Scalars['String'];
};

export type GoalDto = {
  __typename?: 'GoalDto';
  childId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  endAt: Maybe<Scalars['DateTime']>;
  guardianId: Scalars['String'];
  id: Scalars['String'];
  reward: Maybe<Scalars['String']>;
  stamps: Array<Maybe<StampDto>>;
  startAt: Maybe<Scalars['DateTime']>;
  status: GoalStatus;
  targetStampCount: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export enum GoalStatus {
  Complete = 'COMPLETE',
  Created = 'CREATED',
  InProgress = 'IN_PROGRESS',
  Suspended = 'SUSPENDED',
}

export type GuardianAccountDto = {
  __typename?: 'GuardianAccountDto';
  avatarImage: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
};

export type GuardianRelationshipDto = {
  __typename?: 'GuardianRelationshipDto';
  childAccount: Maybe<ChildAccountDto>;
  createdAt: Scalars['DateTime'];
  guardianAccount: Maybe<GuardianAccountDto>;
  id: Scalars['String'];
  relationshipType: RelationshipType;
};

export enum GuardianRelationshipFilter {
  Child = 'child',
  Guardian = 'guardian',
}

export enum InsightBillingPeriodDuration {
  P1M = 'P1M',
  P1Y = 'P1Y',
}

export type InsightChildDto = {
  __typename?: 'InsightChildDto';
  avatarImage: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  schoolInfo: Maybe<InsightSchoolInfoDto>;
};

export type InsightOrderDto = {
  __typename?: 'InsightOrderDto';
  billingPeriodDuration: Maybe<InsightBillingPeriodDuration>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  ordererId: Scalars['String'];
  paymentMethod: InsightPaymentMethod;
  price: Maybe<Scalars['Float']>;
  status: InsightOrderStatus;
  subscription: Maybe<InsightSubscriptionDto>;
  subscriptionId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum InsightOrderStatus {
  Complete = 'COMPLETE',
  Created = 'CREATED',
  Draft = 'DRAFT',
}

export enum InsightPaymentMethod {
  AndroidInApp = 'ANDROID_IN_APP',
  None = 'NONE',
}

export type InsightSchoolDto = {
  __typename?: 'InsightSchoolDto';
  id: Scalars['String'];
  name: Scalars['String'];
};

export enum InsightSchoolGrade {
  Adult = 'ADULT',
  Eighth = 'EIGHTH',
  Eleventh = 'ELEVENTH',
  Fifth = 'FIFTH',
  First = 'FIRST',
  Fourth = 'FOURTH',
  HigherEducation = 'HIGHER_EDUCATION',
  Kindergarten = 'KINDERGARTEN',
  Ninth = 'NINTH',
  None = 'NONE',
  PreKindergarten = 'PRE_KINDERGARTEN',
  Second = 'SECOND',
  Seventh = 'SEVENTH',
  Sixth = 'SIXTH',
  Tenth = 'TENTH',
  Third = 'THIRD',
  Twelfth = 'TWELFTH',
}

export type InsightSchoolInfoDto = {
  __typename?: 'InsightSchoolInfoDto';
  grade: Maybe<InsightSchoolGrade>;
  school: Maybe<InsightSchoolDto>;
};

export type InsightSubscriptionDto = {
  __typename?: 'InsightSubscriptionDto';
  childIds: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  endAt: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isAvailable: Scalars['Boolean'];
  orderId: Scalars['String'];
  plan: Maybe<InsightSubscriptionPlan>;
  platformStatus: Maybe<InsightSubscriptionPlatformStatus>;
  startAt: Maybe<Scalars['DateTime']>;
  status: InsightSubscriptionStatus;
  subscriberId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum InsightSubscriptionPlan {
  Free = 'FREE',
  Pro = 'PRO',
}

export enum InsightSubscriptionPlatformStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
  InGracePeriod = 'IN_GRACE_PERIOD',
  OnHold = 'ON_HOLD',
}

export enum InsightSubscriptionStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Created = 'CREATED',
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
}

export type InsightsAccountDto = {
  __typename?: 'InsightsAccountDto';
  guardianIds: Array<Maybe<Scalars['String']>>;
  id: Scalars['String'];
  role: InsightsAccountRole;
};

export enum InsightsAccountRole {
  Child = 'CHILD',
  Guardian = 'GUARDIAN',
}

export type LinkChildRequestDtoInput = {
  childId: Scalars['String'];
  relationshipType: RelationshipType;
};

export type LinkChildResponseDto = {
  __typename?: 'LinkChildResponseDto';
  childId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  guardianId: Scalars['String'];
  id: Scalars['String'];
  relationshipType: RelationshipType;
};

export type LinkGuardianRequestDtoInput = {
  guardianId: Scalars['String'];
  relationshipType: RelationshipType;
};

export type MarketingAgreementDto = {
  __typename?: 'MarketingAgreementDto';
  isEmailAgreed: Scalars['Boolean'];
  isPushAgreed: Scalars['Boolean'];
  isSmsAgreed: Scalars['Boolean'];
};

export type MarketingAgreementDtoInput = {
  isEmailAgreed: Scalars['Boolean'];
  isPushAgreed: Scalars['Boolean'];
  isSmsAgreed: Scalars['Boolean'];
};

export type MobileDto = {
  __typename?: 'MobileDto';
  countryCode: Scalars['String'];
  number: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addReactionToComment: Maybe<ClassroomCommentDto>;
  addReactionToPost: Maybe<ClassroomPostDto>;
  addReactionToReply: Maybe<ClassroomReplyDto>;
  archiveChannel: Maybe<ClassroomChannel>;
  archiveChannelDto: Maybe<ClassroomChannelDto>;
  archiveClassroom: Maybe<ClassroomClassroomDto>;
  assignAssignment: Maybe<ClassroomAssignmentDto>;
  assignMemberRole: Maybe<ClassroomMemberDto>;
  blockMember: Maybe<ClassroomMemberDto>;
  bookmarkClassroom: Maybe<ClassroomClassroomFavoriteDto>;
  bookmarkLegacyClassroom: Maybe<ClassroomClassroomFavoriteDto>;
  changeEmail: Maybe<Scalars['Void']>;
  changeMobile: Maybe<Scalars['Void']>;
  changePassword: Maybe<Scalars['Void']>;
  completeAllAssignmentSubmission: Maybe<
    Array<Maybe<ClassroomAssignmentSubmissionDto2>>
  >;
  completeAssignmentSubmission: Maybe<ClassroomAssignmentSubmissionDto>;
  completeOnboardingChecklistAction: Maybe<ClassroomOnboardingChecklistDto>;
  createAssignment: Maybe<ClassroomAssignmentDto>;
  createAssignmentSubmissionComment: Maybe<ClassroomAssignmentSubmissionCommentDto>;
  createAttendance: Maybe<ClassroomAttendanceDto>;
  createChannel: Maybe<ClassroomChannel>;
  createChannelDto: Maybe<ClassroomChannelDto>;
  createChannelGroup: Maybe<ClassroomChannelGroupDto>;
  createChannelGroupDto: Maybe<ClassroomChannelGroupDto>;
  createChildCode: Maybe<ChildCodeResponseDto>;
  createClassroom: Maybe<ClassroomClassroomDto>;
  createComment: Maybe<ClassroomCommentDto>;
  createCumulativeRecord: Maybe<CumulativeRecordDto>;
  createFreePlanSubscription: Maybe<InsightOrderDto>;
  createGoal: Maybe<GoalDto>;
  createPersonalCertificate: Maybe<PersonalCertificate>;
  createPost: Maybe<ClassroomPostDto>;
  createReply: Maybe<ClassroomReplyDto>;
  createTag: Maybe<ClassroomTagDto>;
  deactivateClassroomMember: Maybe<ClassroomMemberDto>;
  deleteAccount: Maybe<Scalars['Void']>;
  deleteAssignment: Maybe<Scalars['Void']>;
  deleteAssignmentSubmissionComment: Maybe<Scalars['Void']>;
  deleteChannel: Maybe<Scalars['Void']>;
  deleteChannelGroup: Maybe<Scalars['JSON']>;
  deleteClassroom: Maybe<Scalars['Void']>;
  deleteComment: Maybe<Scalars['Void']>;
  deleteCumulativeRecord: Maybe<Scalars['JSON']>;
  deletePost: Maybe<Scalars['Void']>;
  deleteReply: Maybe<Scalars['Void']>;
  disintegrateZoom: Maybe<Scalars['JSON']>;
  draftOrder: InsightOrderDto;
  endMeeting: Maybe<ClassroomChannelDto>;
  excuseAttendance: Maybe<ClassroomAttendanceDto>;
  hideOnboardingChecklist: Maybe<ClassroomOnboardingChecklistDto>;
  initiateExportAttendances: Maybe<ClassroomExcelExportJobStateDto>;
  integrateZoom: Maybe<ClassroomZoomIntegrationDto>;
  inviteMembers: Maybe<Scalars['Void']>;
  joinChannel: Maybe<ClassroomChannel>;
  joinChannelDto: Maybe<ClassroomChannelDto>;
  joinMeeting: Maybe<ClassroomMeetingDto>;
  leaveChannel: Maybe<ClassroomChannel>;
  leaveChannelDto: Maybe<ClassroomChannelDto>;
  linkChild: Maybe<LinkChildResponseDto>;
  linkGuardian: Maybe<LinkChildResponseDto>;
  markPostAsRead: Maybe<Scalars['JSON']>;
  pinPost: Maybe<ClassroomChannelPostPinDto>;
  praiseChildActivity: Maybe<ActivityDto>;
  privateCreateClassroom: Maybe<ClassroomClassroomDto>;
  privateRegisterForClassroom: Maybe<ClassroomMemberDto>;
  privateUpdateClassroom: Maybe<ClassroomClassroomDto>;
  publishPost: Maybe<ClassroomPostDto>;
  recreateClassroomClassCode: Maybe<ClassroomClassCodeDto>;
  registerForClassroom: Maybe<ClassroomMemberDto>;
  regradeAssignmentSubmission: Maybe<ClassroomAssignmentSubmissionDto>;
  remindPost: Maybe<ClassroomPostDto>;
  removeReactionFromComment: Maybe<ClassroomCommentDto>;
  removeReactionFromPost: Maybe<ClassroomPostDto>;
  removeReactionFromReply: Maybe<ClassroomReplyDto>;
  reportPost: Maybe<ClassroomPostDto>;
  requestEmailChange: Maybe<Scalars['Void']>;
  requestMobileChange: Maybe<PinCodeDto>;
  saveAssignmentSubmissionPoints: Maybe<ClassroomAssignmentSubmissionDto>;
  saveEssayAnswerPointsToLearningAssignment: Maybe<ClassroomAssignmentSubmissionDto>;
  saveMemberAnswerToLearningAssignment: Maybe<ClassroomAssignmentSubmissionDto>;
  savePostAsDraft: Maybe<ClassroomPostDto>;
  schedulePost: Maybe<ClassroomPostDto>;
  startMeeting: Maybe<ClassroomMeetingDto>;
  studentRegistrationCodesControllerCreateStudentRegistrationCode: Maybe<StudentRegistrationCodeDto2>;
  submitLearningSubmission: Maybe<ClassroomAssignmentSubmissionDto>;
  submitSubmission: Maybe<ClassroomAssignmentSubmissionDto>;
  suspendGoal: Maybe<GoalDto>;
  translatePost: Maybe<ClassroomTranslatedPostDto>;
  unarchiveChannel: Maybe<ClassroomChannel>;
  unarchiveChannelDto: Maybe<ClassroomChannelDto>;
  unarchiveClassroom: Maybe<ClassroomClassroomDto>;
  unblockMember: Maybe<ClassroomMemberDto>;
  unbookmarkClassroom: Maybe<Scalars['Void']>;
  unbookmarkLegacyClassroom: Maybe<Scalars['Void']>;
  unlinkChild: Maybe<Scalars['Void']>;
  unpinPost: Maybe<Scalars['Void']>;
  unschedulePost: Maybe<ClassroomPostDto>;
  updateAccount: Maybe<AccountDto>;
  updateAssignedAssignment: Maybe<ClassroomAssignmentDto>;
  updateAssignment: Maybe<ClassroomAssignmentDto>;
  updateAssignmentSubmissionComment: Maybe<ClassroomAssignmentSubmissionCommentDto>;
  updateChannel: Maybe<ClassroomChannel>;
  updateChannelDto: Maybe<ClassroomChannelDto>;
  updateChannelGroup: Maybe<ClassroomChannelGroupDto>;
  updateChannelGroupDto: Maybe<ClassroomChannelGroupDto>;
  updateChildSchoolInfo: Maybe<AccountDto>;
  updateClassroom: Maybe<ClassroomClassroomDto>;
  updateClassroomMember: Maybe<ClassroomMemberDto>;
  updateComment: Maybe<ClassroomCommentDto>;
  updateMemberPushNotificationSettings: Maybe<ClassroomMemberPushNotificationSettingsDto>;
  updatePost: Maybe<ClassroomPostDto>;
  updatePostAdminExclusiveByAdmin: Maybe<ClassroomPostDto>;
  updatePostChannelAdmin: Maybe<ClassroomPostDto>;
  updateReply: Maybe<ClassroomReplyDto>;
  updateTag: Maybe<ClassroomTagDto>;
  verifyChildCode: Maybe<ChildCodeResponseDto>;
  verifyOrderControllerVerifyOrder: Maybe<Scalars['JSON']>;
};

export type MutationAddReactionToCommentArgs = {
  commentId: Scalars['String'];
  input?: InputMaybe<ClassroomAddReactionToCommentDtoInput>;
  postId: Scalars['String'];
};

export type MutationAddReactionToPostArgs = {
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomAddReactionToPostDtoInput>;
  postId: Scalars['String'];
};

export type MutationAddReactionToReplyArgs = {
  commentId: Scalars['String'];
  input?: InputMaybe<ClassroomAddReactionToReplyDtoInput>;
  postId: Scalars['String'];
  replyId: Scalars['String'];
};

export type MutationArchiveChannelArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
};

export type MutationArchiveChannelDtoArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
};

export type MutationArchiveClassroomArgs = {
  classroomId: Scalars['String'];
};

export type MutationAssignAssignmentArgs = {
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomAssignAssignmentDtoInput>;
};

export type MutationAssignMemberRoleArgs = {
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomAssignMemberRoleDtoInput>;
  memberId: Scalars['String'];
};

export type MutationBlockMemberArgs = {
  classroomId: Scalars['String'];
  memberId: Scalars['String'];
};

export type MutationBookmarkClassroomArgs = {
  classroomId: Scalars['String'];
};

export type MutationBookmarkLegacyClassroomArgs = {
  classroomId: Scalars['String'];
};

export type MutationChangeEmailArgs = {
  id: Scalars['String'];
  input?: InputMaybe<ChangeEmailDtoInput>;
};

export type MutationChangeMobileArgs = {
  id: Scalars['String'];
  input?: InputMaybe<ChangeMobileDtoInput>;
};

export type MutationChangePasswordArgs = {
  id: Scalars['String'];
  input?: InputMaybe<ChangePasswordDtoInput>;
};

export type MutationCompleteAllAssignmentSubmissionArgs = {
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
};

export type MutationCompleteAssignmentSubmissionArgs = {
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomCompleteAssignmentSubmissionDtoInput>;
};

export type MutationCompleteOnboardingChecklistActionArgs = {
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomCompleteOnboardingChecklistActionDtoInput>;
};

export type MutationCreateAssignmentArgs = {
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomCreateAssignmentDtoInput>;
};

export type MutationCreateAssignmentSubmissionCommentArgs = {
  assignmentSubmissionId: Scalars['String'];
  input?: InputMaybe<ClassroomCreateAssignmentSubmissionCommentDtoInput>;
};

export type MutationCreateAttendanceArgs = {
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomCreateAttendanceDtoInput>;
};

export type MutationCreateChannelArgs = {
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomCreateChannelDtoInput>;
};

export type MutationCreateChannelDtoArgs = {
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomCreateChannelDtoInput>;
};

export type MutationCreateChannelGroupArgs = {
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomCreateChannelGroupDtoInput>;
};

export type MutationCreateChannelGroupDtoArgs = {
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomCreateChannelGroupDtoInput>;
};

export type MutationCreateChildCodeArgs = {
  id: Scalars['String'];
};

export type MutationCreateClassroomArgs = {
  input?: InputMaybe<ClassroomCreateClassroomDtoInput>;
};

export type MutationCreateCommentArgs = {
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomCreateCommentDtoInput>;
  postId: Scalars['String'];
};

export type MutationCreateCumulativeRecordArgs = {
  classroomId: Scalars['String'];
  input?: InputMaybe<CreateCumulativeRecordDtoInput>;
  memberId: Scalars['String'];
};

export type MutationCreateFreePlanSubscriptionArgs = {
  input?: InputMaybe<CreateFreePlanSubscriptionDtoInput>;
};

export type MutationCreateGoalArgs = {
  childId: Scalars['String'];
  input?: InputMaybe<CreateGoalDtoInput>;
};

export type MutationCreatePersonalCertificateArgs = {
  id: Scalars['String'];
  input?: InputMaybe<Scalars['JSON']>;
};

export type MutationCreatePostArgs = {
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomCreatePostDtoInput>;
};

export type MutationCreateReplyArgs = {
  classroomId: Scalars['String'];
  commentId: Scalars['String'];
  input?: InputMaybe<ClassroomCreateReplyDtoInput>;
  postId: Scalars['String'];
};

export type MutationCreateTagArgs = {
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomCreateTagDtoInput>;
};

export type MutationDeactivateClassroomMemberArgs = {
  classroomId: Scalars['String'];
  memberId: Scalars['String'];
};

export type MutationDeleteAccountArgs = {
  id: Scalars['String'];
};

export type MutationDeleteAssignmentArgs = {
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
};

export type MutationDeleteAssignmentSubmissionCommentArgs = {
  assignmentSubmissionId: Scalars['String'];
  commentId: Scalars['String'];
};

export type MutationDeleteChannelArgs = {
  channelGroupId: Scalars['String'];
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
};

export type MutationDeleteChannelGroupArgs = {
  channelGroupId: Scalars['String'];
  classroomId: Scalars['String'];
};

export type MutationDeleteClassroomArgs = {
  classroomId: Scalars['String'];
};

export type MutationDeleteCommentArgs = {
  commentId: Scalars['String'];
  postId: Scalars['String'];
};

export type MutationDeleteCumulativeRecordArgs = {
  classroomId: Scalars['String'];
  cumulativeRecordId: Scalars['String'];
};

export type MutationDeletePostArgs = {
  classroomId: Scalars['String'];
  postId: Scalars['String'];
};

export type MutationDeleteReplyArgs = {
  commentId: Scalars['String'];
  replyId: Scalars['String'];
};

export type MutationDisintegrateZoomArgs = {
  classroomId: Scalars['String'];
};

export type MutationDraftOrderArgs = {
  childIds: Array<Scalars['ID']>;
  paymentMethod: InsightPaymentMethod;
};

export type MutationEndMeetingArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
};

export type MutationExcuseAttendanceArgs = {
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomExcuseAttendanceDtoInput>;
};

export type MutationHideOnboardingChecklistArgs = {
  classroomId: Scalars['String'];
};

export type MutationInitiateExportAttendancesArgs = {
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomInitiateExportAttendancesDtoInput>;
};

export type MutationIntegrateZoomArgs = {
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomIntegrateZoomDtoInput>;
};

export type MutationInviteMembersArgs = {
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomInviteMembersDtoInput>;
};

export type MutationJoinChannelArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
};

export type MutationJoinChannelDtoArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
};

export type MutationJoinMeetingArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
};

export type MutationLeaveChannelArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
};

export type MutationLeaveChannelDtoArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
};

export type MutationLinkChildArgs = {
  input?: InputMaybe<LinkChildRequestDtoInput>;
};

export type MutationLinkGuardianArgs = {
  input?: InputMaybe<LinkGuardianRequestDtoInput>;
};

export type MutationMarkPostAsReadArgs = {
  classroomId: Scalars['String'];
  postId: Scalars['String'];
};

export type MutationPinPostArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomPinPostDtoInput>;
};

export type MutationPraiseChildActivityArgs = {
  activityId: Scalars['String'];
  childId: Scalars['String'];
  input?: InputMaybe<PraiseChildActivityDtoInput>;
};

export type MutationPrivateCreateClassroomArgs = {
  input?: InputMaybe<ClassroomPrivateCreateClassroomDtoInput>;
};

export type MutationPrivateRegisterForClassroomArgs = {
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomPrivateRegisterDtoInput>;
};

export type MutationPrivateUpdateClassroomArgs = {
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomUpdateClassroomDtoInput>;
};

export type MutationPublishPostArgs = {
  classroomId: Scalars['String'];
  postId: Scalars['String'];
};

export type MutationRecreateClassroomClassCodeArgs = {
  classroomId: Scalars['String'];
};

export type MutationRegisterForClassroomArgs = {
  input?: InputMaybe<ClassroomRegisterDtoInput>;
};

export type MutationRegradeAssignmentSubmissionArgs = {
  assigneeId: Scalars['String'];
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomRegradeAssignmentSubmissionDtoInput>;
};

export type MutationRemindPostArgs = {
  classroomId: Scalars['String'];
  postId: Scalars['String'];
};

export type MutationRemoveReactionFromCommentArgs = {
  commentId: Scalars['String'];
  input?: InputMaybe<ClassroomRemoveReactionFromCommentDtoInput>;
  postId: Scalars['String'];
};

export type MutationRemoveReactionFromPostArgs = {
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomRemoveReactionFromPostDtoInput>;
  postId: Scalars['String'];
};

export type MutationRemoveReactionFromReplyArgs = {
  commentId: Scalars['String'];
  input?: InputMaybe<ClassroomRemoveReactionFromReplyDtoInput>;
  postId: Scalars['String'];
  replyId: Scalars['String'];
};

export type MutationReportPostArgs = {
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomReportPostDtoInput>;
  postId: Scalars['String'];
};

export type MutationRequestEmailChangeArgs = {
  id: Scalars['String'];
  input?: InputMaybe<RequestEmailChangeDtoInput>;
};

export type MutationRequestMobileChangeArgs = {
  id: Scalars['String'];
  input?: InputMaybe<RequestMobileChangeDtoInput>;
};

export type MutationSaveAssignmentSubmissionPointsArgs = {
  assigneeId: Scalars['String'];
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomSaveAssignmentSubmissionPointsDtoInput>;
};

export type MutationSaveEssayAnswerPointsToLearningAssignmentArgs = {
  assigneeId: Scalars['String'];
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomSaveEssayAnswerPointsToLearningAssignmentDtoInput>;
};

export type MutationSaveMemberAnswerToLearningAssignmentArgs = {
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomSaveMemberAnswerToLearningAssignmentDtoInput>;
};

export type MutationSavePostAsDraftArgs = {
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomSavePostAsDraftDtoInput>;
  postId: Scalars['String'];
};

export type MutationSchedulePostArgs = {
  classroomId: Scalars['String'];
  postId: Scalars['String'];
};

export type MutationStartMeetingArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
};

export type MutationSubmitLearningSubmissionArgs = {
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
};

export type MutationSubmitSubmissionArgs = {
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomSubmitSubmissionDtoInput>;
};

export type MutationSuspendGoalArgs = {
  childId: Scalars['String'];
  goalId: Scalars['String'];
};

export type MutationTranslatePostArgs = {
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomTranslatePostDtoInput>;
  postId: Scalars['String'];
};

export type MutationUnarchiveChannelArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
};

export type MutationUnarchiveChannelDtoArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
};

export type MutationUnarchiveClassroomArgs = {
  classroomId: Scalars['String'];
};

export type MutationUnblockMemberArgs = {
  classroomId: Scalars['String'];
  memberId: Scalars['String'];
};

export type MutationUnbookmarkClassroomArgs = {
  classroomId: Scalars['String'];
};

export type MutationUnbookmarkLegacyClassroomArgs = {
  classroomId: Scalars['String'];
};

export type MutationUnlinkChildArgs = {
  id: Scalars['String'];
};

export type MutationUnpinPostArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomUnpinPostDtoInput>;
};

export type MutationUnschedulePostArgs = {
  classroomId: Scalars['String'];
  postId: Scalars['String'];
};

export type MutationUpdateAccountArgs = {
  id: Scalars['String'];
  input?: InputMaybe<UpdateAccountDtoInput>;
};

export type MutationUpdateAssignedAssignmentArgs = {
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomUpdateAssignedAssignmentDtoInput>;
};

export type MutationUpdateAssignmentArgs = {
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomUpdateAssignmentDtoInput>;
};

export type MutationUpdateAssignmentSubmissionCommentArgs = {
  assignmentSubmissionId: Scalars['String'];
  commentId: Scalars['String'];
  input?: InputMaybe<ClassroomUpdateAssignmentSubmissionCommentDtoInput>;
};

export type MutationUpdateChannelArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomUpdateChannelDtoInput>;
};

export type MutationUpdateChannelDtoArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomUpdateChannelDtoInput>;
};

export type MutationUpdateChannelGroupArgs = {
  channelGroupId: Scalars['String'];
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomUpdateChannelGroupDtoInput>;
};

export type MutationUpdateChannelGroupDtoArgs = {
  channelGroupId: Scalars['String'];
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomUpdateChannelGroupDtoInput>;
};

export type MutationUpdateChildSchoolInfoArgs = {
  id: Scalars['String'];
  input?: InputMaybe<UpdateChildSchoolInfoDtoInput>;
};

export type MutationUpdateClassroomArgs = {
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomUpdateClassroomDtoInput>;
};

export type MutationUpdateClassroomMemberArgs = {
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomUpdateMemberDtoInput>;
  memberId: Scalars['String'];
};

export type MutationUpdateCommentArgs = {
  commentId: Scalars['String'];
  input?: InputMaybe<ClassroomUpdateCommentDtoInput>;
  postId: Scalars['String'];
};

export type MutationUpdateMemberPushNotificationSettingsArgs = {
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomUpdateMemberPushNotificationSettingsDtoInput>;
};

export type MutationUpdatePostArgs = {
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomUpdatePostDtoInput>;
  postId: Scalars['String'];
};

export type MutationUpdatePostAdminExclusiveByAdminArgs = {
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomUpdatePostAdminExclusiveByAdminDtoInput>;
  postId: Scalars['String'];
};

export type MutationUpdatePostChannelAdminArgs = {
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomUpdatePostChannelAdminDtoInput>;
  postId: Scalars['String'];
};

export type MutationUpdateReplyArgs = {
  commentId: Scalars['String'];
  input?: InputMaybe<ClassroomUpdateReplyDtoInput>;
  replyId: Scalars['String'];
};

export type MutationUpdateTagArgs = {
  classroomId: Scalars['String'];
  input?: InputMaybe<ClassroomUpdateTagDtoInput>;
  tag: Scalars['String'];
};

export type MutationVerifyChildCodeArgs = {
  code: Scalars['String'];
  id: Scalars['String'];
};

export type MutationVerifyOrderControllerVerifyOrderArgs = {
  input?: InputMaybe<VerifyOrderDtoInput>;
  orderId: Scalars['String'];
};

export enum MutationInputUpdateAccountInputGender {
  Female = 'female',
  Male = 'male',
}

export type PersonalCertificate = {
  __typename?: 'PersonalCertificate';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type PinCodeDto = {
  __typename?: 'PinCodeDto';
  expireAt: Scalars['DateTime'];
  id: Scalars['String'];
  isGlobalSms: Scalars['Boolean'];
};

export type PostReactionDto = {
  __typename?: 'PostReactionDto';
  classAverage: Maybe<Scalars['Float']>;
  classStandardDeviation: Maybe<Scalars['Float']>;
  classroomId: Maybe<Scalars['String']>;
  classroomName: Maybe<Scalars['String']>;
  userEventCount: Maybe<Scalars['Int']>;
  userLevel: Maybe<UserLevel>;
};

export type PostReadDto = {
  __typename?: 'PostReadDto';
  classAverage: Maybe<Scalars['Float']>;
  classStandardDeviation: Maybe<Scalars['Float']>;
  classroomId: Maybe<Scalars['String']>;
  classroomName: Maybe<Scalars['String']>;
  postCount: Maybe<Scalars['Float']>;
  userEventCount: Maybe<Scalars['Int']>;
  userLevel: Maybe<UserLevel>;
};

export type PraiseChildActivityDtoInput = {
  stickerCode: StickerCode;
};

export type Query = {
  __typename?: 'Query';
  assignmentSubmission: Maybe<ClassroomAssignmentSubmissionDto>;
  excuseAttendance: Maybe<ClassroomExcelExportJobStateDto>;
  exportClassroomAttendances: Maybe<Scalars['JSON']>;
  getAccount: Maybe<SimpleAccountDto>;
  getAssignment: Maybe<ClassroomAssignmentDto>;
  getAssignmentPermission: Maybe<ClassroomAssignmentPermissionDto>;
  getAssignmentSubmission: Maybe<ClassroomAssignmentSubmissionDto>;
  getAssignmentSubmissionCommentPermission: Maybe<ClassroomAssignmentSubmissionCommentPermissionDto>;
  getAssignmentSubmissionRate: Maybe<AssignmentSubmissionRateDto>;
  getAttendanceSummary: Maybe<ClassroomAttendanceSummaryDto>;
  getChannel: Maybe<ClassroomChannel>;
  getChannelDto: Maybe<ClassroomChannelDto>;
  getChannelGroup: Maybe<ClassroomChannelGroupDto>;
  getChannelGroupDto: Maybe<ClassroomChannelGroupDto>;
  getClassCode: Maybe<ClassroomClassCodeDto>;
  getClassroom: Maybe<ClassroomClassroomDto>;
  getClassroomActivityCount: Maybe<ClassroomActivityCountDto>;
  getClassroomBookmarkStatus: Maybe<ClassroomClassroomBookmarkStatusDto>;
  getClassroomClassCode: Maybe<ClassroomClassCodeDto>;
  getClassroomMember: Maybe<ClassroomMemberDto>;
  getClassroomPostReadRate: Maybe<ClassroomPostReadRateDto>;
  getClassroomVisitRate: Maybe<ClassroomVisitRateDto>;
  getComment: Maybe<ClassroomCommentDto>;
  getCommentPermission: Maybe<ClassroomCommentPermissionDto>;
  getEstimatedOrder: Maybe<EstimatedOrderDto>;
  getGoal: Maybe<GoalDto>;
  getInsightsAccount: Maybe<InsightsAccountDto>;
  getLatestGoal: Maybe<GoalDto>;
  getLatestSubscription: Maybe<InsightSubscriptionDto>;
  getMe: Maybe<AccountDto>;
  getMemberPushNotificationSettings: Maybe<ClassroomMemberPushNotificationSettingsDto>;
  getOnboardingChecklist: Maybe<ClassroomOnboardingChecklistDto>;
  getOrder: InsightOrderDto;
  getPost: Maybe<ClassroomPostDto>;
  getPostPermission: Maybe<ClassroomPostPermissionDto>;
  getPostReadStatus: Maybe<ClassroomPostReadStatusDto>;
  getReply: Maybe<ClassroomReplyDto>;
  getReplyPermission: Maybe<ClassroomReplyPermissionDto>;
  getTag: Maybe<ClassroomTagDto>;
  getTranslationPermission: Maybe<ClassroomTranslationPermissionDto>;
  listAllCumulativeRecords: Maybe<Array<Maybe<CumulativeRecordDto2>>>;
  listAssignmentAssignees: Maybe<Array<Maybe<ClassroomMemberDto6>>>;
  listAssignmentSubmissionComments: Maybe<ClassroomAssignmentSubmissionCommentsCollectionDto>;
  listAssignmentSubmissions: Maybe<
    Array<Maybe<ClassroomAssignmentSubmissionDto3>>
  >;
  listBookmarkedClassrooms: Maybe<ClassroomClassroomFavoritesCollectionDto>;
  listChannelGroups: Maybe<Array<Maybe<ClassroomChannelGroupDto2>>>;
  listChannelGroupsDto: Maybe<Array<Maybe<ClassroomChannelGroupDto3>>>;
  listChannelMediaPosts: Maybe<ClassroomPostsSearchCollectionDto>;
  listChannelMembers: Maybe<Array<Maybe<ClassroomMemberDto2>>>;
  listChannels: Maybe<Array<Maybe<ClassroomChannel2>>>;
  listChannelsDto: Maybe<Array<Maybe<ClassroomChannelDto2>>>;
  listChildActivities: Maybe<ActivitiesCollectionDto>;
  listChildren: Array<InsightChildDto>;
  listClassroomActivityCountsByMember: Maybe<ClassroomActivityCountsByMemberCollectionDto>;
  listClassroomAssignments: Maybe<ClassroomAssignmentsSearchCollectionDto>;
  listClassroomBlockedMembers: Maybe<ClassroomMembersCollectionDto>;
  listClassroomFriends: Maybe<Array<Maybe<ClassroomFriendDto>>>;
  listClassroomFriendships: Maybe<ClassroomFriendshipCollectionDto>;
  listClassroomMediaPosts: Maybe<ClassroomPostsSearchCollectionDto>;
  listClassroomMembers: Maybe<ClassroomMembersCollectionDto>;
  listClassroomMembersControllerAdminListClassroomMembers: Maybe<ClassroomMembersCollectionDto>;
  listClassroomPostReadRatesByMember: Maybe<ClassroomPostReadRatesByMemberCollectionDto>;
  listClassroomPostReadRatesByPost: Maybe<ClassroomPostReadRatesByPostCollectionDto>;
  listClassroomRoles: Maybe<Array<Maybe<ClassroomRoleDto>>>;
  listClassrooms: Maybe<ClassroomClassroomsCollectionDto>;
  listCommentReactions: Maybe<ClassroomCommentReactionsCollectionDto>;
  listCommonChannels: Maybe<Array<Maybe<ClassroomChannelDto3>>>;
  listCumulativeRecords: Maybe<CumulativeRecordsCollectionDto>;
  listFriends: Maybe<Array<Maybe<Friend>>>;
  listFriendsInterestedFrom: Maybe<Array<Maybe<Friend>>>;
  listFriendsInterestedTo: Maybe<Array<Maybe<Friend>>>;
  listGuardianRelationships: Maybe<Array<Maybe<GuardianRelationshipDto>>>;
  listMemberActivities: Maybe<ActivitiesCollectionDto>;
  listMemberAssignments: Maybe<ClassroomAssignmentsSearchCollectionDto>;
  listMemberAttendances: Maybe<ClassroomMemberAttendancesCollectionDto>;
  listMemberSubmissions: Maybe<Array<Maybe<ClassroomSubmissionDto2>>>;
  listMyBookmarkedClassrooms: Maybe<ClassroomMyClassroomsCollectionDto>;
  listMyClassrooms: Maybe<ClassroomMyClassroomsCollectionDto>;
  listNewsfeedPosts: Maybe<ClassroomPostsCollectionDto>;
  listPostComments: Maybe<ClassroomCommentsCollectionDto>;
  listPostDrafts: Maybe<ClassroomPostsSearchCollectionDto>;
  listPostPins: Maybe<Array<Maybe<ClassroomChannelPostPinDto2>>>;
  listPostReactions: Maybe<ClassroomPostReactionsCollectionDto>;
  listPostReactionsRate: Maybe<Array<Maybe<PostReactionDto>>>;
  listPostReads: Maybe<Array<Maybe<ClassroomMemberDto4>>>;
  listPostReadsRate: Maybe<Array<Maybe<PostReadDto>>>;
  listPostSchedules: Maybe<ClassroomPostsSearchCollectionDto>;
  listPostUnreads: Maybe<Array<Maybe<ClassroomMemberDto5>>>;
  listPosts: Maybe<ClassroomPostsCollectionDto>;
  listPostsByPostType: Maybe<ClassroomPostsSearchCollectionDto>;
  listReplies: Maybe<ClassroomRepliesCollectionDto>;
  listReplyReactions: Maybe<ClassroomReplyReactionsCollectionDto>;
  listSchoolClassrooms: Maybe<ClassroomClassroomsCollectionDto>;
  listSchoolClassroomsControllerAdminListSchoolClassrooms: Maybe<ClassroomClassroomsCollectionDto>;
  listSubscriptionProducts: Maybe<Array<Maybe<SubscriptionProductDto>>>;
  listTags: Maybe<Array<Maybe<ClassroomTagDto2>>>;
  listTodos: Maybe<TodosCollectionDto>;
  privateGetAssignment: Maybe<ClassroomAssignmentDto>;
  privateGetAssignmentSubmission: Maybe<ClassroomAssignmentSubmissionDto>;
  privateGetClassroom: Maybe<ClassroomClassroomDto>;
  privateGetComment: Maybe<ClassroomCommentDto>;
  privateGetPost: Maybe<ClassroomPostDto>;
  privateListChannelMembers: Maybe<Array<Maybe<ClassroomMemberDto3>>>;
  privateListClassroomMembers: Maybe<ClassroomMembersCollectionDto>;
  privateListOrganizationClassrooms: Maybe<ClassroomClassroomsCollectionDto>;
  privateListPosts: Maybe<ClassroomPostsCollectionDto>;
  searchPosts: Maybe<ClassroomPostsSearchCollectionDto>;
  studentRegistrationCodesControllerListStudentRegistrationCodes: Maybe<
    Array<Maybe<StudentRegistrationCodeDto>>
  >;
};

export type QueryAssignmentSubmissionArgs = {
  assignmentSubmissionId: Scalars['String'];
  classroomId: Scalars['String'];
};

export type QueryExcuseAttendanceArgs = {
  jobId: Scalars['String'];
};

export type QueryExportClassroomAttendancesArgs = {
  classroomId: Scalars['String'];
  fileName: Scalars['String'];
  months: Array<InputMaybe<Scalars['Float']>>;
  timezone?: InputMaybe<Scalars['String']>;
  year: Scalars['PositiveFloat'];
};

export type QueryGetAccountArgs = {
  id: Scalars['String'];
};

export type QueryGetAssignmentArgs = {
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
};

export type QueryGetAssignmentPermissionArgs = {
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
};

export type QueryGetAssignmentSubmissionArgs = {
  assigneeId: Scalars['String'];
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
};

export type QueryGetAssignmentSubmissionCommentPermissionArgs = {
  assignmentSubmissionId: Scalars['String'];
  commentId: Scalars['String'];
};

export type QueryGetAssignmentSubmissionRateArgs = {
  userId: Scalars['String'];
  year?: InputMaybe<Scalars['Float']>;
};

export type QueryGetAttendanceSummaryArgs = {
  classroomId: Scalars['String'];
  date: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
};

export type QueryGetChannelArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
};

export type QueryGetChannelDtoArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
};

export type QueryGetChannelGroupArgs = {
  channelGroupId: Scalars['String'];
  classroomId: Scalars['String'];
};

export type QueryGetChannelGroupDtoArgs = {
  channelGroupId: Scalars['String'];
  classroomId: Scalars['String'];
};

export type QueryGetClassCodeArgs = {
  code: Scalars['String'];
};

export type QueryGetClassroomArgs = {
  classroomId: Scalars['String'];
};

export type QueryGetClassroomActivityCountArgs = {
  classroomId: Scalars['String'];
  targetDate?: InputMaybe<Scalars['String']>;
};

export type QueryGetClassroomBookmarkStatusArgs = {
  classroomId: Scalars['String'];
};

export type QueryGetClassroomClassCodeArgs = {
  id: Scalars['String'];
};

export type QueryGetClassroomMemberArgs = {
  classroomId: Scalars['String'];
  memberId: Scalars['String'];
};

export type QueryGetClassroomPostReadRateArgs = {
  classroomId: Scalars['String'];
  targetDate?: InputMaybe<Scalars['String']>;
};

export type QueryGetClassroomVisitRateArgs = {
  classroomId: Scalars['String'];
  targetDate?: InputMaybe<Scalars['String']>;
};

export type QueryGetCommentArgs = {
  classroomId: Scalars['String'];
  commentId: Scalars['String'];
  postId: Scalars['String'];
};

export type QueryGetCommentPermissionArgs = {
  commentId: Scalars['String'];
  postId: Scalars['String'];
};

export type QueryGetEstimatedOrderArgs = {
  orderId: Scalars['String'];
};

export type QueryGetGoalArgs = {
  childId: Scalars['String'];
  goalId: Scalars['String'];
};

export type QueryGetLatestGoalArgs = {
  childId: Scalars['String'];
  guardianId: Scalars['String'];
};

export type QueryGetMemberPushNotificationSettingsArgs = {
  classroomId: Scalars['String'];
};

export type QueryGetOnboardingChecklistArgs = {
  classroomId: Scalars['String'];
};

export type QueryGetOrderArgs = {
  orderId: Scalars['ID'];
};

export type QueryGetPostArgs = {
  classroomId: Scalars['String'];
  postId: Scalars['String'];
};

export type QueryGetPostPermissionArgs = {
  classroomId: Scalars['String'];
  postId: Scalars['String'];
};

export type QueryGetPostReadStatusArgs = {
  postId: Scalars['String'];
};

export type QueryGetReplyArgs = {
  classroomId: Scalars['String'];
  commentId: Scalars['String'];
  replyId: Scalars['String'];
};

export type QueryGetReplyPermissionArgs = {
  classroomId: Scalars['String'];
  commentId: Scalars['String'];
  replyId: Scalars['String'];
};

export type QueryGetTagArgs = {
  classroomId: Scalars['String'];
  tag: Scalars['String'];
};

export type QueryGetTranslationPermissionArgs = {
  classroomId: Scalars['String'];
};

export type QueryListAllCumulativeRecordsArgs = {
  classroomId: Scalars['String'];
};

export type QueryListAssignmentAssigneesArgs = {
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
};

export type QueryListAssignmentSubmissionCommentsArgs = {
  assignmentSubmissionId: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['PositiveFloat']>;
};

export type QueryListAssignmentSubmissionsArgs = {
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
  submitted?: InputMaybe<Scalars['String']>;
};

export type QueryListBookmarkedClassroomsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['PositiveFloat']>;
};

export type QueryListChannelGroupsArgs = {
  classroomId: Scalars['String'];
  scope?: InputMaybe<ClassroomqueryInputListChannelGroupsScope>;
};

export type QueryListChannelGroupsDtoArgs = {
  classroomId: Scalars['String'];
  scope?: InputMaybe<ClassroomqueryInputListChannelGroupsDtoScope>;
};

export type QueryListChannelMediaPostsArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['PositiveFloat']>;
};

export type QueryListChannelMembersArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
  groups?: InputMaybe<Scalars['String']>;
};

export type QueryListChannelsArgs = {
  classroomId: Scalars['String'];
  limit?: InputMaybe<Scalars['Float']>;
};

export type QueryListChannelsDtoArgs = {
  classroomId: Scalars['String'];
  limit?: InputMaybe<Scalars['Float']>;
};

export type QueryListChildActivitiesArgs = {
  childId: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  generatorId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type QueryListClassroomActivityCountsByMemberArgs = {
  classroomId: Scalars['String'];
  endDate?: InputMaybe<Scalars['String']>;
  group: Scalars['String'];
  startDate?: InputMaybe<Scalars['String']>;
};

export type QueryListClassroomAssignmentsArgs = {
  classroomId: Scalars['String'];
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['PositiveFloat']>;
};

export type QueryListClassroomBlockedMembersArgs = {
  classroomId: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['PositiveFloat']>;
};

export type QueryListClassroomFriendsArgs = {
  classroomId: Scalars['String'];
  userId: Scalars['String'];
};

export type QueryListClassroomFriendshipsArgs = {
  classroomId: Scalars['String'];
};

export type QueryListClassroomMediaPostsArgs = {
  classroomId: Scalars['String'];
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['PositiveFloat']>;
};

export type QueryListClassroomMembersArgs = {
  classroomId: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['PositiveFloat']>;
  roles?: InputMaybe<Scalars['String']>;
};

export type QueryListClassroomMembersControllerAdminListClassroomMembersArgs = {
  classroomId: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['PositiveFloat']>;
  roles?: InputMaybe<Scalars['String']>;
};

export type QueryListClassroomPostReadRatesByMemberArgs = {
  classroomId: Scalars['String'];
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type QueryListClassroomPostReadRatesByPostArgs = {
  classroomId: Scalars['String'];
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type QueryListClassroomRolesArgs = {
  classroomId: Scalars['String'];
};

export type QueryListClassroomsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ClassroomListMemberClassroomFilter>;
  limit?: InputMaybe<Scalars['PositiveFloat']>;
  role?: InputMaybe<Scalars['String']>;
};

export type QueryListCommentReactionsArgs = {
  classroomId: Scalars['String'];
  commentId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  postId: Scalars['String'];
};

export type QueryListCommonChannelsArgs = {
  classroomId: Scalars['String'];
};

export type QueryListCumulativeRecordsArgs = {
  classroomId: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['PositiveFloat']>;
  memberId?: InputMaybe<Scalars['String']>;
};

export type QueryListFriendsArgs = {
  userId: Scalars['String'];
};

export type QueryListFriendsInterestedFromArgs = {
  childId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryListFriendsInterestedToArgs = {
  childId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryListGuardianRelationshipsArgs = {
  type: GuardianRelationshipFilter;
};

export type QueryListMemberActivitiesArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  generatorId: Scalars['String'];
  generatorType: Scalars['String'];
  limit?: InputMaybe<Scalars['PositiveFloat']>;
  memberId: Scalars['String'];
};

export type QueryListMemberAssignmentsArgs = {
  classroomId: Scalars['String'];
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
};

export type QueryListMemberAttendancesArgs = {
  classroomId: Scalars['String'];
  endDate: Scalars['String'];
  memberId: Scalars['String'];
  startDate: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
};

export type QueryListMemberSubmissionsArgs = {
  assignmentId: Scalars['String'];
};

export type QueryListMyBookmarkedClassroomsArgs = {
  includeLegacyClassrooms?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['PositiveFloat']>;
};

export type QueryListMyClassroomsArgs = {
  filter?: InputMaybe<ClassroomListMyClassroomFilter>;
  includeLegacyClassrooms?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['PositiveFloat']>;
};

export type QueryListNewsfeedPostsArgs = {
  channelId?: InputMaybe<Scalars['String']>;
  classroomId: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['PositiveFloat']>;
  postIds?: InputMaybe<Scalars['String']>;
  status: ClassroomqueryInputListNewsfeedPostsStatus;
};

export type QueryListPostCommentsArgs = {
  classroomId: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['PositiveFloat']>;
  postId: Scalars['String'];
};

export type QueryListPostDraftsArgs = {
  channelId?: InputMaybe<Scalars['String']>;
  classroomId: Scalars['String'];
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  postType?: InputMaybe<ClassroomqueryInputListPostDraftsPostType>;
};

export type QueryListPostPinsArgs = {
  channelId?: InputMaybe<Scalars['String']>;
  classroomId: Scalars['String'];
  limit?: InputMaybe<Scalars['PositiveFloat']>;
};

export type QueryListPostReactionsArgs = {
  classroomId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  postId: Scalars['String'];
};

export type QueryListPostReactionsRateArgs = {
  day?: InputMaybe<Scalars['Float']>;
  userId: Scalars['String'];
};

export type QueryListPostReadsArgs = {
  classroomId: Scalars['String'];
  postId: Scalars['String'];
};

export type QueryListPostReadsRateArgs = {
  orderBy?: InputMaybe<Scalars['String']>;
  orderByOption?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type QueryListPostSchedulesArgs = {
  channelId?: InputMaybe<Scalars['String']>;
  classroomId: Scalars['String'];
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  postType?: InputMaybe<ClassroomqueryInputListPostSchedulesPostType>;
};

export type QueryListPostUnreadsArgs = {
  classroomId: Scalars['String'];
  postId: Scalars['String'];
};

export type QueryListPostsArgs = {
  channelId?: InputMaybe<Scalars['String']>;
  classroomId: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['PositiveFloat']>;
  postIds?: InputMaybe<Scalars['String']>;
  status: ClassroomqueryInputListPostsStatus;
};

export type QueryListPostsByPostTypeArgs = {
  classroomId: Scalars['String'];
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['PositiveFloat']>;
  postType: Scalars['String'];
};

export type QueryListRepliesArgs = {
  classroomId: Scalars['String'];
  commentId: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['PositiveFloat']>;
  postId: Scalars['String'];
};

export type QueryListReplyReactionsArgs = {
  classroomId: Scalars['String'];
  commentId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  replyId: Scalars['String'];
};

export type QueryListSchoolClassroomsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['PositiveFloat']>;
  schoolId: Scalars['String'];
};

export type QueryListSchoolClassroomsControllerAdminListSchoolClassroomsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['PositiveFloat']>;
  schoolId: Scalars['String'];
};

export type QueryListSubscriptionProductsArgs = {
  currencyCode: Scalars['String'];
  packageName: Scalars['String'];
  productId: Scalars['String'];
  regionCode: Scalars['String'];
};

export type QueryListTagsArgs = {
  classroomId: Scalars['String'];
  pinned?: InputMaybe<Scalars['Boolean']>;
};

export type QueryListTodosArgs = {
  childId: Scalars['String'];
  limit?: InputMaybe<Scalars['Float']>;
};

export type QueryPrivateGetAssignmentArgs = {
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
};

export type QueryPrivateGetAssignmentSubmissionArgs = {
  assignmentSubmissionId: Scalars['String'];
};

export type QueryPrivateGetClassroomArgs = {
  classroomId: Scalars['String'];
};

export type QueryPrivateGetCommentArgs = {
  classroomId: Scalars['String'];
  commentId: Scalars['String'];
  postId: Scalars['String'];
};

export type QueryPrivateGetPostArgs = {
  classroomId: Scalars['String'];
  postId: Scalars['String'];
};

export type QueryPrivateListChannelMembersArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
};

export type QueryPrivateListClassroomMembersArgs = {
  classroomId: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['PositiveFloat']>;
  roles?: InputMaybe<Scalars['String']>;
};

export type QueryPrivateListOrganizationClassroomsArgs = {
  active?: InputMaybe<Scalars['String']>;
  cursor?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<ClassroomClassroomGrade>;
  limit?: InputMaybe<Scalars['PositiveFloat']>;
  organizationId: Scalars['String'];
  schoolYear?: InputMaybe<Scalars['PositiveFloat']>;
};

export type QueryPrivateListPostsArgs = {
  classroomId: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['PositiveFloat']>;
  publishedAt_LEFT_SQUARE_BRACE_gt_RIGHT_SQUARE_BRACE_?: InputMaybe<
    Scalars['String']
  >;
  publishedAt_LEFT_SQUARE_BRACE_lt_RIGHT_SQUARE_BRACE_?: InputMaybe<
    Scalars['String']
  >;
};

export type QuerySearchPostsArgs = {
  channelId?: InputMaybe<Scalars['String']>;
  classroomId: Scalars['String'];
  isAnnouncement?: InputMaybe<Scalars['Boolean']>;
  orderBy?: InputMaybe<Scalars['ClassroomqueryInputSearchPostsOrderBy']>;
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  q?: InputMaybe<Scalars['String']>;
  tag?: InputMaybe<Scalars['String']>;
};

export enum QueryGetMeGender {
  Female = 'female',
  Male = 'male',
}

export enum QueryGetMeIdentitiesItemsProvider {
  Apple = 'apple',
  Google = 'google',
  Kakao = 'kakao',
  Naver = 'naver',
  Whalespace = 'whalespace',
}

export enum RelationshipType {
  Father = 'father',
  GrandFather = 'grand_father',
  GrandMother = 'grand_mother',
  Guardian = 'guardian',
  Mother = 'mother',
}

export type ReplaceFieldsOfChildAccountDto = {
  __typename?: 'ReplaceFieldsOfChildAccountDto';
  schoolInfo: Maybe<SchoolInfoDto>;
};

export type ReplaceFieldsOfClassroomPostReadRateByPostDto = {
  __typename?: 'ReplaceFieldsOfClassroomPostReadRateByPostDto';
  postReadRate: Scalars['Int'];
};

export type ReplaceFieldsOfClassroomPostReadRatesByPostCollectionDto = {
  __typename?: 'ReplaceFieldsOfClassroomPostReadRatesByPostCollectionDto';
  totalPostCount: Scalars['Int'];
};

export type ReplaceFieldsOfInsightSubscriptionDto = {
  __typename?: 'ReplaceFieldsOfInsightSubscriptionDto';
  childIds: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  endAt: Maybe<Scalars['DateTime']>;
  startAt: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type RequestEmailChangeDtoInput = {
  email: Scalars['String'];
};

export type RequestMobileChangeDtoInput = {
  countryCode: Scalars['String'];
  number: Scalars['String'];
};

export type SchoolDto = {
  __typename?: 'SchoolDto';
  admissionAge: Scalars['Float'];
  id: Scalars['String'];
  name: Scalars['String'];
  regularCourseYear: Scalars['Float'];
  zipcode: Scalars['String'];
};

export type SchoolDtoInput = {
  admissionAge: Scalars['Float'];
  id: Scalars['String'];
  name: Scalars['String'];
  regularCourseYear: Scalars['Float'];
  zipcode: Scalars['String'];
};

export enum SchoolGrade {
  Adult = 'adult',
  Eighth = 'eighth',
  Eleventh = 'eleventh',
  Fifth = 'fifth',
  First = 'first',
  Fourth = 'fourth',
  HigherEducation = 'higher_education',
  Kindergarten = 'kindergarten',
  Ninth = 'ninth',
  None = 'none',
  PreKindergarten = 'pre_kindergarten',
  Second = 'second',
  Seventh = 'seventh',
  Sixth = 'sixth',
  Tenth = 'tenth',
  Third = 'third',
  Twelfth = 'twelfth',
}

export type SchoolInfo = {
  __typename?: 'SchoolInfo';
  grade: SchoolGrade;
  school: AccountSchool;
};

export type SchoolInfoByIdDtoInput = {
  grade: SchoolGrade;
  schoolId: Scalars['String'];
};

export type SchoolInfoDto = {
  __typename?: 'SchoolInfoDto';
  grade: SchoolGrade;
  school: SchoolDto;
};

export type SchoolInfoDtoInput = {
  grade: SchoolGrade;
  school: SchoolDtoInput;
};

export enum SignInMethod {
  Apple = 'apple',
  Email = 'email',
  Google = 'google',
  Id = 'id',
  Kakao = 'kakao',
  Mobile = 'mobile',
  Naver = 'naver',
  Username = 'username',
  Whalespace = 'whalespace',
}

export type SimpleAccountDto = {
  __typename?: 'SimpleAccountDto';
  avatarImage: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Maybe<Scalars['String']>;
  profileImage: Maybe<Scalars['String']>;
  role: AccountRole;
  username: Maybe<Scalars['String']>;
};

export type SocialIdentity = {
  __typename?: 'SocialIdentity';
  createdAt: Maybe<Scalars['DateTime']>;
  provider: QueryGetMeIdentitiesItemsProvider;
  uid: Scalars['String'];
};

export type StampDto = {
  __typename?: 'StampDto';
  activityId: Scalars['String'];
  type: StampType;
};

export enum StampType {
  Admire = 'ADMIRE',
  Encourage = 'ENCOURAGE',
  PraiseHighest = 'PRAISE_HIGHEST',
  PraiseNormal = 'PRAISE_NORMAL',
  Thank = 'THANK',
}

export enum StickerCode {
  Admire = 'ADMIRE',
  Encourage = 'ENCOURAGE',
  PraiseHighest = 'PRAISE_HIGHEST',
  PraiseNormal = 'PRAISE_NORMAL',
  Thank = 'THANK',
}

export type StickerDto = {
  __typename?: 'StickerDto';
  code: StickerCode;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  senderId: Scalars['String'];
};

export type StudentRegistrationCodeDto = {
  __typename?: 'StudentRegistrationCodeDto';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  expireAt: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  studentAccountId: Maybe<Scalars['String']>;
  teacherAccountId: Scalars['String'];
  teacherAgreedAt: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type StudentRegistrationCodeDto2 = {
  __typename?: 'StudentRegistrationCodeDto2';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  expireAt: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  studentAccountId: Maybe<Scalars['String']>;
  teacherAccountId: Scalars['String'];
  teacherAgreedAt: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type SubscriptionProductDto = {
  __typename?: 'SubscriptionProductDto';
  basePlanId: Scalars['String'];
  billingPeriodDuration: InsightBillingPeriodDuration;
  currencyCode: Scalars['String'];
  fixedPrice: Scalars['Float'];
  id: Scalars['String'];
  offerId: Maybe<Scalars['String']>;
  plan: InsightSubscriptionPlan;
  sellingPrice: Scalars['Float'];
};

export type TodoDto = {
  __typename?: 'TodoDto';
  completed: Scalars['Boolean'];
  completedAt: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  dueAt: Scalars['DateTime'];
  id: Scalars['String'];
  originalUrl: Scalars['String'];
  title: Scalars['String'];
  type: TodoType;
  updatedAt: Scalars['DateTime'];
};

export enum TodoType {
  Announcement = 'ANNOUNCEMENT',
  Assignment = 'ASSIGNMENT',
}

export type TodosCollectionDto = {
  __typename?: 'TodosCollectionDto';
  todos: Array<Maybe<TodoDto>>;
};

export type UpdateAccountDtoInput = {
  birthDate: InputMaybe<Scalars['String']>;
  countryCode: InputMaybe<Scalars['String']>;
  description: InputMaybe<
    Scalars['MutationInputUpdateAccountInputDescription']
  >;
  gender: InputMaybe<MutationInputUpdateAccountInputGender>;
  isMarketingTermsAgreed: InputMaybe<Scalars['Boolean']>;
  language: InputMaybe<Scalars['String']>;
  marketingAgreement: InputMaybe<MarketingAgreementDtoInput>;
  name: InputMaybe<Scalars['String']>;
  profileImage: InputMaybe<Scalars['String']>;
  schoolInfo: InputMaybe<SchoolInfoDtoInput>;
  schoolInfoById: InputMaybe<SchoolInfoByIdDtoInput>;
  schoolInfoForChildren: InputMaybe<Array<InputMaybe<SchoolInfoDtoInput>>>;
};

export type UpdateChildSchoolInfoDtoInput = {
  schoolInfoForChildren: Array<InputMaybe<SchoolInfoByIdDtoInput>>;
};

export enum UserLevel {
  High = 'high',
  Low = 'low',
  Middle = 'middle',
  MiddleHigh = 'middle_high',
  MiddleLow = 'middle_low',
}

export type VerifyOrderDtoInput = {
  packageName: Scalars['String'];
  productId: Scalars['String'];
  purchaseToken: Scalars['String'];
};

export type ChildInfoQueryVariables = Exact<{ [key: string]: never }>;

export type ChildInfoQuery = {
  __typename?: 'Query';
  me: { __typename?: 'AccountDto'; id: string; role: AccountRole } | null;
};

export type ChildInfoForGuardianQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ChildInfoForGuardianQuery = {
  __typename?: 'Query';
  subscription: {
    __typename?: 'InsightSubscriptionDto';
    id: string;
    childIds: Array<string>;
  } | null;
  guardianRelationships: Array<{
    __typename?: 'GuardianRelationshipDto';
    childAccount: {
      __typename?: 'ChildAccountDto';
      id: string;
      name: string;
      avatarImage: string | null;
      schoolInfo: {
        __typename?: 'SchoolInfoDto';
        grade: SchoolGrade;
        school: { __typename?: 'SchoolDto'; name: string };
      } | null;
    } | null;
  } | null> | null;
};

export type ChildInfoForChildQueryVariables = Exact<{ [key: string]: never }>;

export type ChildInfoForChildQuery = {
  __typename?: 'Query';
  meInfo: {
    __typename?: 'AccountDto';
    id: string;
    name: string | null;
    avatarImage: string | null;
    schoolInfo: {
      __typename?: 'SchoolInfoDto';
      grade: SchoolGrade;
      school: { __typename?: 'SchoolDto'; name: string };
    } | null;
  } | null;
};

export type ChildTrackingInfoQueryVariables = Exact<{ [key: string]: never }>;

export type ChildTrackingInfoQuery = {
  __typename?: 'Query';
  relationships: Array<{
    __typename?: 'GuardianRelationshipDto';
    id: string;
    childAccount: {
      __typename?: 'ChildAccountDto';
      id: string;
      schoolInfo: { __typename?: 'SchoolInfoDto'; grade: SchoolGrade } | null;
    } | null;
  } | null> | null;
};

export type ActivityItemActivityFieldsFragment = {
  __typename?: 'ActivityDto';
  id: string;
  type: string;
  summary: string | null;
  content: string | null;
  image: string | null;
  publishedAt: any | null;
  actor: any;
  object: any;
  target: any;
  generator: any;
  stickers: Array<{
    __typename?: 'StickerDto';
    code: StickerCode;
    senderId: string;
  } | null> | null;
};

export type ActivityItemQueryVariables = Exact<{ [key: string]: never }>;

export type ActivityItemQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'InsightsAccountDto';
    id: string;
    role: InsightsAccountRole;
  } | null;
};

export type ActivityItemDemoActivityFieldsFragment = {
  __typename?: 'ActivityDto';
  id: string;
  type: string;
  summary: string | null;
  content: string | null;
  image: string | null;
  publishedAt: any | null;
  actor: any;
  object: any;
  target: any;
  generator: any;
  stickers: Array<{
    __typename?: 'StickerDto';
    code: StickerCode;
    senderId: string;
  } | null> | null;
};

export type ActivityItemDemoQueryVariables = Exact<{ [key: string]: never }>;

export type ActivityItemDemoQuery = {
  __typename?: 'Query';
  me: { __typename?: 'AccountDto'; id: string; role: AccountRole } | null;
};

export type ActivityItemDemoAccountQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ActivityItemDemoAccountQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'SimpleAccountDto';
    id: string;
    name: string | null;
  } | null;
};

export type ActivityItemFooterMutationVariables = Exact<{
  activityId: Scalars['String'];
  childId: Scalars['String'];
  praiseChildActivityDtoInput?: InputMaybe<PraiseChildActivityDtoInput>;
}>;

export type ActivityItemFooterMutation = {
  __typename?: 'Mutation';
  praiseChildActivity: {
    __typename?: 'ActivityDto';
    id: string;
    type: string;
    summary: string | null;
    content: string | null;
    image: string | null;
    publishedAt: any | null;
    actor: any;
    object: any;
    target: any;
    generator: any;
    stickers: Array<{
      __typename?: 'StickerDto';
      code: StickerCode;
      senderId: string;
    } | null> | null;
  } | null;
};

export type ActivityItemFooterQueryVariables = Exact<{ [key: string]: never }>;

export type ActivityItemFooterQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'InsightsAccountDto';
    id: string;
    role: InsightsAccountRole;
  } | null;
};

export type ActivityListQueryVariables = Exact<{
  childId: Scalars['String'];
  limit?: InputMaybe<Scalars['Float']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  cursor?: InputMaybe<Scalars['String']>;
}>;

export type ActivityListQuery = {
  __typename?: 'Query';
  activitiesCollection: {
    __typename?: 'ActivitiesCollectionDto';
    cursor: string | null;
    activities: Array<{
      __typename?: 'ActivityDto';
      id: string;
      type: string;
      summary: string | null;
      content: string | null;
      image: string | null;
      publishedAt: any | null;
      actor: any;
      object: any;
      target: any;
      generator: any;
      stickers: Array<{
        __typename?: 'StickerDto';
        code: StickerCode;
        senderId: string;
      } | null> | null;
    } | null>;
  } | null;
};

export type ClassActivitiesQueryVariables = Exact<{ [key: string]: never }>;

export type ClassActivitiesQuery = {
  __typename?: 'Query';
  subscription: {
    __typename?: 'InsightSubscriptionDto';
    id: string;
    childIds: Array<string>;
  } | null;
  me: {
    __typename?: 'AccountDto';
    id: string;
    signedUpAt: any | null;
    role: AccountRole;
  } | null;
};

export type ClassActivitiesGoalQueryVariables = Exact<{
  guardianId: Scalars['String'];
  childId: Scalars['String'];
}>;

export type ClassActivitiesGoalQuery = {
  __typename?: 'Query';
  goal: { __typename?: 'GoalDto'; id: string; status: GoalStatus } | null;
};

export type ClassActivitiesRelationshipQueryVariables = Exact<{
  type?: InputMaybe<GuardianRelationshipFilter>;
}>;

export type ClassActivitiesRelationshipQuery = {
  __typename?: 'Query';
  relationships: Array<{
    __typename?: 'GuardianRelationshipDto';
    id: string;
    childAccount: {
      __typename?: 'ChildAccountDto';
      id: string;
      signedUpAt: any | null;
    } | null;
  } | null> | null;
};

export type ClassActivitiesDemoQueryVariables = Exact<{
  type?: InputMaybe<GuardianRelationshipFilter>;
  childId: Scalars['String'];
  limit?: InputMaybe<Scalars['Float']>;
}>;

export type ClassActivitiesDemoQuery = {
  __typename?: 'Query';
  relationships: Array<{
    __typename?: 'GuardianRelationshipDto';
    id: string;
    childAccount: {
      __typename?: 'ChildAccountDto';
      id: string;
      signedUpAt: any | null;
    } | null;
  } | null> | null;
  activitiesCollection: {
    __typename?: 'ActivitiesCollectionDto';
    activities: Array<{
      __typename?: 'ActivityDto';
      id: string;
      type: string;
      summary: string | null;
      content: string | null;
      image: string | null;
      publishedAt: any | null;
      actor: any;
      object: any;
      target: any;
      generator: any;
      stickers: Array<{
        __typename?: 'StickerDto';
        code: StickerCode;
        senderId: string;
      } | null> | null;
    } | null>;
  } | null;
};

export type SubscriptionGuideQueryVariables = Exact<{ [key: string]: never }>;

export type SubscriptionGuideQuery = {
  __typename?: 'Query';
  relationships: Array<{
    __typename?: 'GuardianRelationshipDto';
    id: string;
    childAccount: {
      __typename?: 'ChildAccountDto';
      id: string;
      schoolInfo: { __typename?: 'SchoolInfoDto'; grade: SchoolGrade } | null;
    } | null;
  } | null> | null;
};

export type ClassActivitiesSummaryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ClassActivitiesSummaryQuery = {
  __typename?: 'Query';
  subscription: {
    __typename?: 'InsightSubscriptionDto';
    id: string;
    childIds: Array<string>;
  } | null;
  me: {
    __typename?: 'InsightsAccountDto';
    id: string;
    role: InsightsAccountRole;
  } | null;
};

export type ClassActivitiesSummaryActivityQueryVariables = Exact<{
  childId: Scalars['String'];
  limit?: InputMaybe<Scalars['Float']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
}>;

export type ClassActivitiesSummaryActivityQuery = {
  __typename?: 'Query';
  activitiesCollection: {
    __typename?: 'ActivitiesCollectionDto';
    activities: Array<{
      __typename?: 'ActivityDto';
      id: string;
      type: string;
      summary: string | null;
      content: string | null;
      image: string | null;
      publishedAt: any | null;
      actor: any;
      object: any;
      target: any;
      generator: any;
      stickers: Array<{
        __typename?: 'StickerDto';
        code: StickerCode;
        senderId: string;
      } | null> | null;
    } | null>;
  } | null;
};

export type ClassActivitiesSummaryDemoQueryVariables = Exact<{
  childId: Scalars['String'];
  limit?: InputMaybe<Scalars['Float']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
}>;

export type ClassActivitiesSummaryDemoQuery = {
  __typename?: 'Query';
  activitiesCollection: {
    __typename?: 'ActivitiesCollectionDto';
    activities: Array<{
      __typename?: 'ActivityDto';
      id: string;
      type: string;
      summary: string | null;
      content: string | null;
      image: string | null;
      publishedAt: any | null;
      actor: any;
      object: any;
      target: any;
      generator: any;
      stickers: Array<{
        __typename?: 'StickerDto';
        code: StickerCode;
        senderId: string;
      } | null> | null;
    } | null>;
  } | null;
  relationships: Array<{
    __typename?: 'GuardianRelationshipDto';
    id: string;
    childAccount: {
      __typename?: 'ChildAccountDto';
      id: string;
      schoolInfo: { __typename?: 'SchoolInfoDto'; grade: SchoolGrade } | null;
    } | null;
  } | null> | null;
};

export type MemberActivityListQueryVariables = Exact<{
  memberId: Scalars['String'];
  generatorId: Scalars['String'];
  generatorType?: Scalars['String'];
  limit?: InputMaybe<Scalars['PositiveFloat']>;
  cursor?: InputMaybe<Scalars['String']>;
}>;

export type MemberActivityListQuery = {
  __typename?: 'Query';
  activitiesCollection: {
    __typename?: 'ActivitiesCollectionDto';
    cursor: string | null;
    activities: Array<{
      __typename?: 'ActivityDto';
      id: string;
      type: string;
      summary: string | null;
      content: string | null;
      image: string | null;
      publishedAt: any | null;
      actor: any;
      object: any;
      target: any;
      generator: any;
      stickers: Array<{
        __typename?: 'StickerDto';
        code: StickerCode;
        senderId: string;
      } | null> | null;
    } | null>;
  } | null;
};

export type ChildProfileQueryVariables = Exact<{ [key: string]: never }>;

export type ChildProfileQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'InsightsAccountDto';
    id: string;
    role: InsightsAccountRole;
  } | null;
  subscription: {
    __typename?: 'InsightSubscriptionDto';
    id: string;
    plan: InsightSubscriptionPlan | null;
  } | null;
};

export type ChildProfileDemoInsightChildDtoFieldsFragment = {
  __typename?: 'ChildAccountDto';
  id: string;
  name: string;
  avatarImage: string | null;
  schoolInfo: {
    __typename?: 'SchoolInfoDto';
    grade: SchoolGrade;
    school: { __typename?: 'SchoolDto'; id: string; name: string };
  } | null;
};

export type ChildProfileDemoQueryVariables = Exact<{ [key: string]: never }>;

export type ChildProfileDemoQuery = {
  __typename?: 'Query';
  guardianRelationships: Array<{
    __typename?: 'GuardianRelationshipDto';
    childAccount: {
      __typename?: 'ChildAccountDto';
      id: string;
      name: string;
      avatarImage: string | null;
      schoolInfo: {
        __typename?: 'SchoolInfoDto';
        grade: SchoolGrade;
        school: { __typename?: 'SchoolDto'; id: string; name: string };
      } | null;
    } | null;
  } | null> | null;
};

export type ChildReconnectionQueryVariables = Exact<{ [key: string]: never }>;

export type ChildReconnectionQuery = {
  __typename?: 'Query';
  subscription: {
    __typename?: 'InsightSubscriptionDto';
    id: string;
    isAvailable: boolean;
    childIds: Array<string>;
  } | null;
  guardianRelationships: Array<{
    __typename?: 'GuardianRelationshipDto';
    id: string;
    childAccount: { __typename?: 'ChildAccountDto'; id: string } | null;
  } | null> | null;
};

export type ChildReconnectionAccountQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ChildReconnectionAccountQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'SimpleAccountDto';
    id: string;
    name: string | null;
    avatarImage: string | null;
  } | null;
};

export type ChildSelectPopupQueryVariables = Exact<{ [key: string]: never }>;

export type ChildSelectPopupQuery = {
  __typename?: 'Query';
  relationships: Array<{
    __typename?: 'GuardianRelationshipDto';
    id: string;
    childAccount: {
      __typename?: 'ChildAccountDto';
      id: string;
      schoolInfo: { __typename?: 'SchoolInfoDto'; grade: SchoolGrade } | null;
    } | null;
  } | null> | null;
};

export type MyProfileDemoQueryVariables = Exact<{ [key: string]: never }>;

export type MyProfileDemoQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'AccountDto';
    id: string;
    name: string | null;
    profileImage: string | null;
    schoolInfo: {
      __typename?: 'SchoolInfoDto';
      grade: SchoolGrade;
      school: { __typename?: 'SchoolDto'; name: string };
    } | null;
  } | null;
};

export type ActivityTableStudentQueryVariables = Exact<{
  classroomId: Scalars['String'];
  group?: Scalars['String'];
}>;

export type ActivityTableStudentQuery = {
  __typename?: 'Query';
  studentActivityCountsCollection: {
    __typename?: 'ClassroomActivityCountsByMemberCollectionDto';
    classroomId: string;
    totalMemberCount: number;
    activityCounts: Array<{
      __typename?: 'ClassroomActivityCountByMemberDto';
      memberId: string;
      memberName: string;
      postCreationCount: number;
      commentCount: number;
      replyCount: number;
      reactionCount: number;
      lastVisitDate: any | null;
    } | null>;
  } | null;
};

export type ActivityTableTeacherQueryVariables = Exact<{
  classroomId: Scalars['String'];
  group?: Scalars['String'];
}>;

export type ActivityTableTeacherQuery = {
  __typename?: 'Query';
  teacherActivityCountsCollection: {
    __typename?: 'ClassroomActivityCountsByMemberCollectionDto';
    classroomId: string;
    totalMemberCount: number;
    activityCounts: Array<{
      __typename?: 'ClassroomActivityCountByMemberDto';
      memberId: string;
      memberName: string;
      postCreationCount: number;
      commentCount: number;
      replyCount: number;
      reactionCount: number;
      lastVisitDate: any | null;
    } | null>;
  } | null;
};

export type ActivityTableParentQueryVariables = Exact<{
  classroomId: Scalars['String'];
  group?: Scalars['String'];
}>;

export type ActivityTableParentQuery = {
  __typename?: 'Query';
  parentActivityCountsCollection: {
    __typename?: 'ClassroomActivityCountsByMemberCollectionDto';
    classroomId: string;
    totalMemberCount: number;
    activityCounts: Array<{
      __typename?: 'ClassroomActivityCountByMemberDto';
      memberId: string;
      memberName: string;
      postCreationCount: number;
      commentCount: number;
      replyCount: number;
      reactionCount: number;
      lastVisitDate: any | null;
    } | null>;
  } | null;
};

export type ActivityTableRowClassroomActivityCountByMemberFieldsFragment = {
  __typename?: 'ClassroomActivityCountByMemberDto';
  memberId: string;
  memberName: string;
  postCreationCount: number;
  commentCount: number;
  replyCount: number;
  reactionCount: number;
  lastVisitDate: any | null;
};

export type ActivityTableRowQueryVariables = Exact<{
  classroomId: Scalars['String'];
  memberId: Scalars['String'];
}>;

export type ActivityTableRowQuery = {
  __typename?: 'Query';
  member: {
    __typename?: 'ClassroomMemberDto';
    id: string;
    profileImageUrl: string | null;
  } | null;
};

export type FriendshipClassroomFriendshipCollectionFieldsFragment = {
  __typename?: 'ClassroomFriendshipCollectionDto';
  classroomId: string;
  friendshipWeightAverage: number;
  friendships: Array<{
    __typename?: 'Friendship';
    id: string | null;
    name: string | null;
    profileImage: string | null;
    rank: number;
    friendshipActivityCount: number;
    friendCountLevel: number;
    friendshipWeightList: Array<{
      __typename?: 'FriendshipWeight';
      id: string;
      friendshipWeightLevel: number;
    } | null>;
  } | null>;
};

export type FriendshipQueryVariables = Exact<{
  classroomId: Scalars['String'];
  groups?: InputMaybe<Scalars['String']>;
}>;

export type FriendshipQuery = {
  __typename?: 'Query';
  memberCollection: {
    __typename?: 'ClassroomMembersCollectionDto';
    cursor: string | null;
    members: Array<{ __typename?: 'ClassroomMemberDto'; id: string } | null>;
  } | null;
};

export type FriendshipInfoQueryVariables = Exact<{
  classroomId: Scalars['String'];
}>;

export type FriendshipInfoQuery = {
  __typename?: 'Query';
  friendshipCollection: {
    __typename?: 'ClassroomFriendshipCollectionDto';
    classroomId: string;
    friendshipWeightAverage: number;
    friendships: Array<{
      __typename?: 'Friendship';
      id: string | null;
      name: string | null;
      rank: number;
      friendshipActivityCount: number;
    } | null>;
  } | null;
};

export type FriendshipNetworkGraphQueryVariables = Exact<{
  classroomId: Scalars['String'];
}>;

export type FriendshipNetworkGraphQuery = {
  __typename?: 'Query';
  ClassroomFriendshipCollection: {
    __typename?: 'ClassroomFriendshipCollectionDto';
    classroomId: string;
    friendshipWeightAverage: number;
    friendships: Array<{
      __typename?: 'Friendship';
      id: string | null;
      name: string | null;
      profileImage: string | null;
      rank: number;
      friendshipActivityCount: number;
      friendCountLevel: number;
      friendshipWeightList: Array<{
        __typename?: 'FriendshipWeight';
        id: string;
        friendshipWeightLevel: number;
      } | null>;
    } | null>;
  } | null;
};

export type FriendshipWeightRankListFriendshipFieldsFragment = {
  __typename?: 'Friendship';
  id: string | null;
  name: string | null;
  rank: number;
  friendshipActivityCount: number;
};

export type PostReadRateByMemberItemClassroomPostReadRateByMemberFieldsFragment =
  {
    __typename?: 'ClassroomPostReadRateByMemberDto';
    memberId: string;
    memberName: string;
    postCount: number;
    postReadCount: number;
    postReadRate: number;
  };

export type PostReadRateRankListByMemberQueryVariables = Exact<{
  classroomId: Scalars['String'];
}>;

export type PostReadRateRankListByMemberQuery = {
  __typename?: 'Query';
  postReadsByMemberCollection: {
    __typename?: 'ClassroomPostReadRatesByMemberCollectionDto';
    classroomId: string;
    totalMemberCount: number;
    postReadRates: Array<{
      __typename?: 'ClassroomPostReadRateByMemberDto';
      memberId: string;
      memberName: string;
      postCount: number;
      postReadCount: number;
      postReadRate: number;
    } | null>;
  } | null;
};

export type PostReadRateByPostItemClassroomPostReadRateByPostFieldsFragment = {
  __typename?: 'ClassroomPostReadRateByPostDto';
  postId: string;
  postTitle: string | null;
  postUrl: string;
  postCreatedAt: any;
  postReadRate: number;
};

export type PostReadRateRankListByPostQueryVariables = Exact<{
  classroomId: Scalars['String'];
}>;

export type PostReadRateRankListByPostQuery = {
  __typename?: 'Query';
  postReadsByPostCollection: {
    __typename?: 'ClassroomPostReadRatesByPostCollectionDto';
    classroomId: string;
    totalPostCount: number;
    postReadRates: Array<{
      __typename?: 'ClassroomPostReadRateByPostDto';
      postId: string;
      postTitle: string | null;
      postUrl: string;
      postCreatedAt: any;
      postReadRate: number;
    } | null>;
  } | null;
};

export type YesterdayActivityCountClassroomActivityCountFieldsFragment = {
  __typename?: 'ClassroomActivityCountDto';
  classroomId: string;
  postCreationCount: number;
  commentCount: number;
  replyCount: number;
  reactionCount: number;
};

export type YesterdayActivityCountQueryVariables = Exact<{
  classroomId: Scalars['String'];
  dateToCompare?: InputMaybe<Scalars['String']>;
}>;

export type YesterdayActivityCountQuery = {
  __typename?: 'Query';
  lastWeekClassActivityCountInfo: {
    __typename?: 'ClassroomActivityCountDto';
    classroomId: string;
    postCreationCount: number;
    commentCount: number;
    replyCount: number;
    reactionCount: number;
  } | null;
  yesterdayClassActivityCountInfo: {
    __typename?: 'ClassroomActivityCountDto';
    classroomId: string;
    postCreationCount: number;
    commentCount: number;
    replyCount: number;
    reactionCount: number;
  } | null;
  classroom: {
    __typename?: 'ClassroomClassroomDto';
    id: string;
    schoolId: string | null;
    grade: ClassroommutationCreateClassroomGrade;
    schoolYear: number | null;
    createdAt: any;
  } | null;
};

export type YesterdayVisitRateQueryVariables = Exact<{
  classroomId: Scalars['String'];
  dateToCompare?: InputMaybe<Scalars['String']>;
}>;

export type YesterdayVisitRateQuery = {
  __typename?: 'Query';
  lastWeekClassVisitRate: {
    __typename?: 'ClassroomVisitRateDto';
    classroomId: string;
    visitRate: number;
  } | null;
  yesterdayClassVisitRate: {
    __typename?: 'ClassroomVisitRateDto';
    classroomId: string;
    visitRate: number;
  } | null;
  classroom: {
    __typename?: 'ClassroomClassroomDto';
    id: string;
    createdAt: any;
  } | null;
};

export type InduceSubscriptionPopupQueryVariables = Exact<{
  [key: string]: never;
}>;

export type InduceSubscriptionPopupQuery = {
  __typename?: 'Query';
  relationships: Array<{
    __typename?: 'GuardianRelationshipDto';
    id: string;
    childAccount: { __typename?: 'ChildAccountDto'; id: string } | null;
  } | null> | null;
};

export type InduceSubscriptionPopupMeQueryVariables = Exact<{
  [key: string]: never;
}>;

export type InduceSubscriptionPopupMeQuery = {
  __typename?: 'Query';
  me: { __typename?: 'AccountDto'; id: string; name: string | null } | null;
};

export type SubscriptionQueryVariables = Exact<{ [key: string]: never }>;

export type SubscriptionQuery = {
  __typename?: 'Query';
  subscription: {
    __typename?: 'InsightSubscriptionDto';
    id: string;
    isAvailable: boolean;
    childIds: Array<string>;
  } | null;
  guardianRelationships: Array<{
    __typename?: 'GuardianRelationshipDto';
    id: string;
    childAccount: { __typename?: 'ChildAccountDto'; id: string } | null;
  } | null> | null;
  me: { __typename?: 'AccountDto'; id: string; role: AccountRole } | null;
};

export type TrackingPixelQueryVariables = Exact<{ [key: string]: never }>;

export type TrackingPixelQuery = {
  __typename?: 'Query';
  subscription: {
    __typename?: 'InsightSubscriptionDto';
    id: string;
    isAvailable: boolean;
  } | null;
};

export type ClassCumulativeRecordFormMutationVariables = Exact<{
  classroomId: Scalars['String'];
  memberId: Scalars['String'];
  createCumulativeRecordDtoInput: CreateCumulativeRecordDtoInput;
}>;

export type ClassCumulativeRecordFormMutation = {
  __typename?: 'Mutation';
  createCumulativeRecord: {
    __typename?: 'CumulativeRecordDto';
    id: string;
    classroomId: string;
    memberId: string;
    contents: string;
    recordedAt: any;
    createdAt: any;
  } | null;
};

export type ClassCumulativeRecordFormQueryVariables = Exact<{
  classroomId: Scalars['String'];
}>;

export type ClassCumulativeRecordFormQuery = {
  __typename?: 'Query';
  classroom: {
    __typename?: 'ClassroomClassroomDto';
    id: string;
    grade: ClassroommutationCreateClassroomGrade;
  } | null;
};

export type ClassCumulativeRecordItemQueryVariables = Exact<{
  classroomId: Scalars['String'];
  memberId: Scalars['String'];
}>;

export type ClassCumulativeRecordItemQuery = {
  __typename?: 'Query';
  member: {
    __typename?: 'ClassroomMemberDto';
    id: string;
    name: string | null;
  } | null;
};

export type ClassCumulativeRecordListQueryVariables = Exact<{
  classroomId: Scalars['String'];
  memberId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['PositiveFloat']>;
  cursor?: InputMaybe<Scalars['String']>;
}>;

export type ClassCumulativeRecordListQuery = {
  __typename?: 'Query';
  cumulativeRecordsCollection: {
    __typename?: 'CumulativeRecordsCollectionDto';
    cursor: string | null;
    cumulativeRecords: Array<{
      __typename?: 'CumulativeRecordDto';
      id: string;
      classroomId: string;
      memberId: string;
      contents: string;
      recordedAt: any;
      createdAt: any;
    } | null>;
  } | null;
};

export type CumulativeRecordFormMemberSelectorClassroomMemberFieldsFragment = {
  __typename?: 'ClassroomMemberDto';
  id: string;
  name: string | null;
  profileImageUrl: string | null;
};

export type CumulativeRecordFormMemberSelectorQueryVariables = Exact<{
  classroomId: Scalars['String'];
}>;

export type CumulativeRecordFormMemberSelectorQuery = {
  __typename?: 'Query';
  classroomMembers: {
    __typename?: 'ClassroomMembersCollectionDto';
    members: Array<{
      __typename?: 'ClassroomMemberDto';
      id: string;
      name: string | null;
      profileImageUrl: string | null;
    } | null>;
  } | null;
};

export type CumulativeRecordFormMemberSelectorClassroomMemberQueryVariables =
  Exact<{
    classroomId: Scalars['String'];
    memberId: Scalars['String'];
  }>;

export type CumulativeRecordFormMemberSelectorClassroomMemberQuery = {
  __typename?: 'Query';
  classroomMember: {
    __typename?: 'ClassroomMemberDto';
    id: string;
    name: string | null;
    profileImageUrl: string | null;
  } | null;
};

export type CumulativeRecordItemCumulativeRecordFieldsFragment = {
  __typename?: 'CumulativeRecordDto';
  id: string;
  classroomId: string;
  memberId: string;
  contents: string;
  recordedAt: any;
  createdAt: any;
};

export type CumulativeRecordItemActionsMutationVariables = Exact<{
  classroomId: Scalars['String'];
  cumulativeRecordId: Scalars['String'];
}>;

export type CumulativeRecordItemActionsMutation = {
  __typename?: 'Mutation';
  deleteCumulativeRecord: any | null;
};

export type CumulativeRecordItemActionsQueryVariables = Exact<{
  classroomId: Scalars['String'];
}>;

export type CumulativeRecordItemActionsQuery = {
  __typename?: 'Query';
  classroom: {
    __typename?: 'ClassroomClassroomDto';
    id: string;
    grade: ClassroommutationCreateClassroomGrade;
  } | null;
};

export type ExportCumulativeRecordQueryVariables = Exact<{
  classroomId: Scalars['String'];
}>;

export type ExportCumulativeRecordQuery = {
  __typename?: 'Query';
  classroom: {
    __typename?: 'ClassroomClassroomDto';
    id: string;
    name: string;
  } | null;
  classroomMembersCollection: {
    __typename?: 'ClassroomMembersCollectionDto';
    members: Array<{
      __typename?: 'ClassroomMemberDto';
      id: string;
      name: string | null;
    } | null>;
  } | null;
  cumulativeRecordsCollection: Array<{
    __typename?: 'CumulativeRecordDto2';
    id: string;
    contents: string;
    memberId: string;
    recordedAt: any;
    createdAt: any;
  } | null> | null;
};

export type ExportCumulativeRecordEventPropertiesQueryVariables = Exact<{
  classroomId: Scalars['String'];
}>;

export type ExportCumulativeRecordEventPropertiesQuery = {
  __typename?: 'Query';
  classroom: {
    __typename?: 'ClassroomClassroomDto';
    id: string;
    grade: ClassroommutationCreateClassroomGrade;
  } | null;
};

export type MemberCumulativeRecordFormMutationVariables = Exact<{
  classroomId: Scalars['String'];
  memberId: Scalars['String'];
  createCumulativeRecordDtoInput: CreateCumulativeRecordDtoInput;
}>;

export type MemberCumulativeRecordFormMutation = {
  __typename?: 'Mutation';
  createCumulativeRecord: {
    __typename?: 'CumulativeRecordDto';
    id: string;
    classroomId: string;
    memberId: string;
    contents: string;
    recordedAt: any;
    createdAt: any;
  } | null;
};

export type MemberCumulativeRecordFormQueryVariables = Exact<{
  classroomId: Scalars['String'];
}>;

export type MemberCumulativeRecordFormQuery = {
  __typename?: 'Query';
  classroom: {
    __typename?: 'ClassroomClassroomDto';
    id: string;
    grade: ClassroommutationCreateClassroomGrade;
  } | null;
};

export type MemberCumulativeRecordItemCumulativeRecordFieldsFragment = {
  __typename?: 'CumulativeRecordDto';
  id: string;
  classroomId: string;
  memberId: string;
  contents: string;
  recordedAt: any;
  createdAt: any;
};

export type MemberCumulativeRecordListQueryVariables = Exact<{
  classroomId: Scalars['String'];
  memberId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['PositiveFloat']>;
  cursor?: InputMaybe<Scalars['String']>;
}>;

export type MemberCumulativeRecordListQuery = {
  __typename?: 'Query';
  cumulativeRecordsCollection: {
    __typename?: 'CumulativeRecordsCollectionDto';
    cursor: string | null;
    cumulativeRecords: Array<{
      __typename?: 'CumulativeRecordDto';
      id: string;
      classroomId: string;
      memberId: string;
      contents: string;
      recordedAt: any;
      createdAt: any;
    } | null>;
  } | null;
};

export type AssignmentDashboardQueryVariables = Exact<{ [key: string]: never }>;

export type AssignmentDashboardQuery = {
  __typename?: 'Query';
  subscription: {
    __typename?: 'InsightSubscriptionDto';
    id: string;
    childIds: Array<string>;
  } | null;
  me: {
    __typename?: 'InsightsAccountDto';
    id: string;
    role: InsightsAccountRole;
  } | null;
};

export type AssignmentDashboardSubmissionQueryVariables = Exact<{
  userId: Scalars['String'];
  year?: InputMaybe<Scalars['Float']>;
}>;

export type AssignmentDashboardSubmissionQuery = {
  __typename?: 'Query';
  submission: {
    __typename?: 'AssignmentSubmissionRateDto';
    id: string | null;
    totalAssignmentCount: number | null;
    totalSubmissionCount: number | null;
    submissionRate: number | null;
  } | null;
};

export type DashboardDemoPostReadQueryVariables = Exact<{
  userId: Scalars['String'];
  orderBy?: InputMaybe<Scalars['String']>;
  orderByOption?: InputMaybe<Scalars['String']>;
}>;

export type DashboardDemoPostReadQuery = {
  __typename?: 'Query';
  postReads: Array<{
    __typename?: 'PostReadDto';
    userLevel: UserLevel | null;
    userEventCount: number | null;
  } | null> | null;
};

export type DashboardDemoPostReactionQueryVariables = Exact<{
  userId: Scalars['String'];
}>;

export type DashboardDemoPostReactionQuery = {
  __typename?: 'Query';
  postReactions: Array<{
    __typename?: 'PostReactionDto';
    userLevel: UserLevel | null;
    userEventCount: number | null;
  } | null> | null;
};

export type DashboardDemoRelationshipQueryVariables = Exact<{
  userId: Scalars['String'];
}>;

export type DashboardDemoRelationshipQuery = {
  __typename?: 'Query';
  friends: Array<{
    __typename?: 'Friend';
    id: string | null;
    avatarImage: string | null;
  } | null> | null;
};

export type DashboardDemoGuardianRelationshipQueryVariables = Exact<{
  type?: GuardianRelationshipFilter;
}>;

export type DashboardDemoGuardianRelationshipQuery = {
  __typename?: 'Query';
  relationships: Array<{
    __typename?: 'GuardianRelationshipDto';
    id: string;
    childAccount: {
      __typename?: 'ChildAccountDto';
      id: string;
      schoolInfo: { __typename?: 'SchoolInfoDto'; grade: SchoolGrade } | null;
    } | null;
  } | null> | null;
};

export type DashboardDemoEmptyQueryVariables = Exact<{ [key: string]: never }>;

export type DashboardDemoEmptyQuery = {
  __typename?: 'Query';
  relationships: Array<{
    __typename?: 'GuardianRelationshipDto';
    id: string;
    childAccount: { __typename?: 'ChildAccountDto'; id: string } | null;
  } | null> | null;
};

export type DashboardListSummaryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type DashboardListSummaryQuery = {
  __typename?: 'Query';
  subscription: {
    __typename?: 'InsightSubscriptionDto';
    id: string;
    childIds: Array<string>;
  } | null;
};

export type DashboardListSummaryChildInfoQueryVariables = Exact<{
  type?: GuardianRelationshipFilter;
  childId: Scalars['String'];
  activityLimit?: InputMaybe<Scalars['Float']>;
}>;

export type DashboardListSummaryChildInfoQuery = {
  __typename?: 'Query';
  relationships: Array<{
    __typename?: 'GuardianRelationshipDto';
    id: string;
    childAccount: {
      __typename?: 'ChildAccountDto';
      id: string;
      schoolInfo: { __typename?: 'SchoolInfoDto'; grade: SchoolGrade } | null;
    } | null;
  } | null> | null;
  activityCollection: {
    __typename?: 'ActivitiesCollectionDto';
    activities: Array<{
      __typename?: 'ActivityDto';
      id: string;
      publishedAt: any | null;
    } | null>;
  } | null;
};

export type DashboardListSummaryPostReadQueryVariables = Exact<{
  userId: Scalars['String'];
  orderBy?: InputMaybe<Scalars['String']>;
  orderByOption?: InputMaybe<Scalars['String']>;
}>;

export type DashboardListSummaryPostReadQuery = {
  __typename?: 'Query';
  postReads: Array<{
    __typename?: 'PostReadDto';
    userLevel: UserLevel | null;
    userEventCount: number | null;
  } | null> | null;
};

export type DashboardListSummaryPostReactionQueryVariables = Exact<{
  userId: Scalars['String'];
}>;

export type DashboardListSummaryPostReactionQuery = {
  __typename?: 'Query';
  postReactions: Array<{
    __typename?: 'PostReactionDto';
    userLevel: UserLevel | null;
    userEventCount: number | null;
  } | null> | null;
};

export type DashboardListSummaryRelationshipQueryVariables = Exact<{
  userId: Scalars['String'];
}>;

export type DashboardListSummaryRelationshipQuery = {
  __typename?: 'Query';
  friends: Array<{
    __typename?: 'Friend';
    id: string | null;
    avatarImage: string | null;
  } | null> | null;
};

export type MemberDashboardListQueryVariables = Exact<{
  userId: Scalars['String'];
  classroomId: Scalars['String'];
  memberId: Scalars['String'];
}>;

export type MemberDashboardListQuery = {
  __typename?: 'Query';
  member: {
    __typename?: 'ClassroomMemberDto';
    id: string;
    name: string | null;
  } | null;
  postReads: Array<{
    __typename?: 'PostReadDto';
    classroomId: string | null;
    classroomName: string | null;
    classAverage: number | null;
    postCount: number | null;
    userEventCount: number | null;
    userLevel: UserLevel | null;
  } | null> | null;
  postReactions: Array<{
    __typename?: 'PostReactionDto';
    classroomId: string | null;
    classroomName: string | null;
    classAverage: number | null;
    userEventCount: number | null;
    userLevel: UserLevel | null;
  } | null> | null;
};

export type MemberRelationshipDashboardQueryVariables = Exact<{
  classroomId: Scalars['String'];
  userId: Scalars['String'];
}>;

export type MemberRelationshipDashboardQuery = {
  __typename?: 'Query';
  listFriends: Array<{
    __typename?: 'ClassroomFriendDto';
    id: string;
    name: string | null;
    rank: number | null;
    profileImage: string | null;
  } | null> | null;
};

export type ParticipationDashboardListQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ParticipationDashboardListQuery = {
  __typename?: 'Query';
  subscription: {
    __typename?: 'InsightSubscriptionDto';
    id: string;
    childIds: Array<string>;
  } | null;
  me: {
    __typename?: 'InsightsAccountDto';
    id: string;
    role: InsightsAccountRole;
  } | null;
};

export type ParticipationDashboardListPostReadQueryVariables = Exact<{
  childId: Scalars['String'];
  userId: Scalars['String'];
  orderBy?: InputMaybe<Scalars['String']>;
  orderByOption?: InputMaybe<Scalars['String']>;
}>;

export type ParticipationDashboardListPostReadQuery = {
  __typename?: 'Query';
  classtingAccount: {
    __typename?: 'SimpleAccountDto';
    id: string;
    name: string | null;
  } | null;
  postReadsRate: Array<{
    __typename?: 'PostReadDto';
    classroomId: string | null;
    classroomName: string | null;
    classAverage: number | null;
    postCount: number | null;
    userEventCount: number | null;
    userLevel: UserLevel | null;
  } | null> | null;
};

export type ParticipationDashboardListPostReactionQueryVariables = Exact<{
  childId: Scalars['String'];
  userId: Scalars['String'];
}>;

export type ParticipationDashboardListPostReactionQuery = {
  __typename?: 'Query';
  classtingAccount: {
    __typename?: 'SimpleAccountDto';
    id: string;
    name: string | null;
  } | null;
  postReactionsRate: Array<{
    __typename?: 'PostReactionDto';
    classroomId: string | null;
    classroomName: string | null;
    classAverage: number | null;
    userEventCount: number | null;
    userLevel: UserLevel | null;
  } | null> | null;
};

export type PostReactionDashboardPostReactionFieldsFragment = {
  __typename?: 'PostReactionDto';
  classroomId: string | null;
  classroomName: string | null;
  classAverage: number | null;
  userEventCount: number | null;
  userLevel: UserLevel | null;
};

export type PostReadDashboardPostReadFieldsFragment = {
  __typename?: 'PostReadDto';
  classroomId: string | null;
  classroomName: string | null;
  classAverage: number | null;
  postCount: number | null;
  userEventCount: number | null;
  userLevel: UserLevel | null;
};

export type RelationshipDashboardFriendFieldsFragment = {
  __typename?: 'Friend';
  id: string | null;
  rank: number | null;
  avatarImage: string | null;
  profileImage: string | null;
  name: string | null;
  username: string | null;
  description: string | null;
};

export type RelationshipDashboardQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RelationshipDashboardQuery = {
  __typename?: 'Query';
  subscription: {
    __typename?: 'InsightSubscriptionDto';
    id: string;
    childIds: Array<string>;
  } | null;
  me: {
    __typename?: 'InsightsAccountDto';
    id: string;
    role: InsightsAccountRole;
  } | null;
};

export type RelationshipDashboardFriendsQueryVariables = Exact<{
  userId: Scalars['String'];
}>;

export type RelationshipDashboardFriendsQuery = {
  __typename?: 'Query';
  friends: Array<{
    __typename?: 'Friend';
    id: string | null;
    rank: number | null;
    avatarImage: string | null;
    profileImage: string | null;
    name: string | null;
    username: string | null;
    description: string | null;
  } | null> | null;
};

export type RelationshipFromFriendsDashboardQueryVariables = Exact<{
  childId: Scalars['String'];
}>;

export type RelationshipFromFriendsDashboardQuery = {
  __typename?: 'Query';
  me: { __typename?: 'AccountDto'; id: string; role: AccountRole } | null;
  friends: Array<{
    __typename?: 'Friend';
    id: string | null;
    rank: number | null;
    avatarImage: string | null;
    profileImage: string | null;
    name: string | null;
    username: string | null;
    description: string | null;
  } | null> | null;
};

export type RelationshipNetworkGraphFriendFieldsFragment = {
  __typename?: 'Friend';
  id: string | null;
  rank: number | null;
  avatarImage: string | null;
  profileImage: string | null;
  name: string | null;
  username: string | null;
  description: string | null;
};

export type RelationshipToFriendsDashboardQueryVariables = Exact<{
  childId: Scalars['String'];
}>;

export type RelationshipToFriendsDashboardQuery = {
  __typename?: 'Query';
  me: { __typename?: 'AccountDto'; id: string; role: AccountRole } | null;
  friends: Array<{
    __typename?: 'Friend';
    id: string | null;
    rank: number | null;
    avatarImage: string | null;
    profileImage: string | null;
    name: string | null;
    username: string | null;
    description: string | null;
  } | null> | null;
};

export type GoalResultQueryVariables = Exact<{ [key: string]: never }>;

export type GoalResultQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'InsightsAccountDto';
    id: string;
    role: InsightsAccountRole;
  } | null;
  subscription: {
    __typename?: 'InsightSubscriptionDto';
    id: string;
    childIds: Array<string>;
  } | null;
};

export type GoalResultGoalQueryVariables = Exact<{
  childId: Scalars['String'];
  goalId: Scalars['String'];
}>;

export type GoalResultGoalQuery = {
  __typename?: 'Query';
  goal: {
    __typename?: 'GoalDto';
    id: string;
    startAt: any | null;
    endAt: any | null;
    reward: string | null;
    status: GoalStatus;
    targetStampCount: number;
    stamps: Array<{ __typename?: 'StampDto'; type: StampType } | null>;
  } | null;
};

export type GoalSettingFormMutationVariables = Exact<{
  childId: Scalars['String'];
  createGoalDtoInput: CreateGoalDtoInput;
}>;

export type GoalSettingFormMutation = {
  __typename?: 'Mutation';
  goal: { __typename?: 'GoalDto'; id: string } | null;
};

export type GoalSettingFormQueryVariables = Exact<{ [key: string]: never }>;

export type GoalSettingFormQuery = {
  __typename?: 'Query';
  subscription: {
    __typename?: 'InsightSubscriptionDto';
    id: string;
    childIds: Array<string>;
  } | null;
};

export type PraiseStampBoardGoalFieldsFragment = {
  __typename?: 'GoalDto';
  id: string;
  startAt: any | null;
  endAt: any | null;
  status: GoalStatus;
  targetStampCount: number;
  reward: string | null;
  stamps: Array<{ __typename?: 'StampDto'; type: StampType } | null>;
};

export type PraiseStampBoardSuspendMutationVariables = Exact<{
  childId: Scalars['String'];
  goalId: Scalars['String'];
}>;

export type PraiseStampBoardSuspendMutation = {
  __typename?: 'Mutation';
  goal: {
    __typename?: 'GoalDto';
    id: string;
    startAt: any | null;
    endAt: any | null;
    status: GoalStatus;
    targetStampCount: number;
    reward: string | null;
    stamps: Array<{ __typename?: 'StampDto'; type: StampType } | null>;
  } | null;
};

export type PraiseStampBoardQueryVariables = Exact<{ [key: string]: never }>;

export type PraiseStampBoardQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'InsightsAccountDto';
    id: string;
    role: InsightsAccountRole;
    guardianIds: Array<string | null>;
  } | null;
};

export type PraiseStampBoardGoalQueryVariables = Exact<{
  childId: Scalars['String'];
  guardianId: Scalars['String'];
}>;

export type PraiseStampBoardGoalQuery = {
  __typename?: 'Query';
  goal: {
    __typename?: 'GoalDto';
    id: string;
    startAt: any | null;
    endAt: any | null;
    status: GoalStatus;
    targetStampCount: number;
    reward: string | null;
    stamps: Array<{ __typename?: 'StampDto'; type: StampType } | null>;
  } | null;
};

export type PraiseStampBoardDemoQueryVariables = Exact<{
  [key: string]: never;
}>;

export type PraiseStampBoardDemoQuery = {
  __typename?: 'Query';
  relationships: Array<{
    __typename?: 'GuardianRelationshipDto';
    id: string;
    childAccount: { __typename?: 'ChildAccountDto'; id: string } | null;
  } | null> | null;
};

export type PraiseStampBoardDemoMeQueryVariables = Exact<{
  [key: string]: never;
}>;

export type PraiseStampBoardDemoMeQuery = {
  __typename?: 'Query';
  me: { __typename?: 'AccountDto'; id: string; name: string | null } | null;
};

export type FreePaymentMutationVariables = Exact<{
  createFreePlanSubscriptionDtoInput: CreateFreePlanSubscriptionDtoInput;
}>;

export type FreePaymentMutation = {
  __typename?: 'Mutation';
  createFreePlanSubscription: {
    __typename?: 'InsightOrderDto';
    id: string;
  } | null;
};

export type MyBillingQueryVariables = Exact<{ [key: string]: never }>;

export type MyBillingQuery = {
  __typename?: 'Query';
  subscription: {
    __typename?: 'InsightSubscriptionDto';
    id: string;
    startAt: any | null;
    endAt: any | null;
    orderId: string;
  } | null;
};

export type MyBillingOrderQueryVariables = Exact<{
  orderId: Scalars['ID'];
}>;

export type MyBillingOrderQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'InsightOrderDto';
    id: string;
    price: number | null;
    billingPeriodDuration: InsightBillingPeriodDuration | null;
  };
};

export type MyBillingEstimatedOrderQueryVariables = Exact<{
  orderId: Scalars['String'];
}>;

export type MyBillingEstimatedOrderQuery = {
  __typename?: 'Query';
  estimatedOrder: { __typename?: 'EstimatedOrderDto'; price: number } | null;
};

export type DraftOrderMutationVariables = Exact<{
  childIds: Array<Scalars['ID']> | Scalars['ID'];
  paymentMethod: InsightPaymentMethod;
}>;

export type DraftOrderMutation = {
  __typename?: 'Mutation';
  draftOrder: {
    __typename?: 'InsightOrderDto';
    id: string;
    subscriptionId: string;
    ordererId: string;
    status: InsightOrderStatus;
    price: number | null;
    paymentMethod: InsightPaymentMethod;
    createdAt: any;
    updatedAt: any;
  };
};

export type PaymentQueryVariables = Exact<{
  type?: GuardianRelationshipFilter;
  packageName: Scalars['String'];
  productId: Scalars['String'];
  regionCode?: Scalars['String'];
  currencyCode?: Scalars['String'];
}>;

export type PaymentQuery = {
  __typename?: 'Query';
  relationships: Array<{
    __typename?: 'GuardianRelationshipDto';
    id: string;
    childAccount: {
      __typename?: 'ChildAccountDto';
      id: string;
      schoolInfo: { __typename?: 'SchoolInfoDto'; grade: SchoolGrade } | null;
    } | null;
  } | null> | null;
  products: Array<{
    __typename?: 'SubscriptionProductDto';
    id: string;
    plan: InsightSubscriptionPlan;
    basePlanId: string;
    offerId: string | null;
    fixedPrice: number;
    sellingPrice: number;
    billingPeriodDuration: InsightBillingPeriodDuration;
  } | null> | null;
};

export type SelectedChildInfoChildAccountFieldsFragment = {
  __typename?: 'ChildAccountDto';
  id: string;
  name: string;
  avatarImage: string | null;
  schoolInfo: {
    __typename?: 'SchoolInfoDto';
    grade: SchoolGrade;
    school: { __typename?: 'SchoolDto'; id: string; name: string };
  } | null;
};

export type SelectedChildInfoQueryVariables = Exact<{ [key: string]: never }>;

export type SelectedChildInfoQuery = {
  __typename?: 'Query';
  relationships: Array<{
    __typename?: 'GuardianRelationshipDto';
    id: string;
    childAccount: {
      __typename?: 'ChildAccountDto';
      id: string;
      name: string;
      avatarImage: string | null;
      schoolInfo: {
        __typename?: 'SchoolInfoDto';
        grade: SchoolGrade;
        school: { __typename?: 'SchoolDto'; id: string; name: string };
      } | null;
    } | null;
  } | null> | null;
};

export type SubscriptionSuccessQueryVariables = Exact<{
  orderId: Scalars['ID'];
}>;

export type SubscriptionSuccessQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'InsightOrderDto';
    id: string;
    price: number | null;
    billingPeriodDuration: InsightBillingPeriodDuration | null;
    subscription: {
      __typename?: 'InsightSubscriptionDto';
      id: string;
      startAt: any | null;
      endAt: any | null;
    } | null;
  };
};

export type TodoDemoQueryVariables = Exact<{
  childId: Scalars['String'];
}>;

export type TodoDemoQuery = {
  __typename?: 'Query';
  listTodos: {
    __typename?: 'TodosCollectionDto';
    todos: Array<{ __typename?: 'TodoDto'; id: string } | null>;
  } | null;
};

export type TodoItemTodoFieldsFragment = {
  __typename?: 'TodoDto';
  id: string;
  type: TodoType;
  completed: boolean;
  title: string;
  dueAt: any;
  completedAt: any | null;
  createdAt: any;
  originalUrl: string;
};

export type TodoListQueryVariables = Exact<{
  childId: Scalars['String'];
}>;

export type TodoListQuery = {
  __typename?: 'Query';
  listTodos: {
    __typename?: 'TodosCollectionDto';
    todos: Array<{
      __typename?: 'TodoDto';
      id: string;
      type: TodoType;
      completed: boolean;
      title: string;
      dueAt: any;
      completedAt: any | null;
      createdAt: any;
      originalUrl: string;
    } | null>;
  } | null;
};

export type RootPageQueryVariables = Exact<{ [key: string]: never }>;

export type RootPageQuery = {
  __typename?: 'Query';
  subscription: {
    __typename?: 'InsightSubscriptionDto';
    id: string;
    isAvailable: boolean;
  } | null;
  relationships: Array<{
    __typename?: 'GuardianRelationshipDto';
    id: string;
    childAccount: { __typename?: 'ChildAccountDto'; id: string } | null;
  } | null> | null;
};

export type AgreementPageQueryVariables = Exact<{ [key: string]: never }>;

export type AgreementPageQuery = {
  __typename?: 'Query';
  me: { __typename?: 'AccountDto'; id: string; role: AccountRole } | null;
};

export type AgreementPageListRelationshipsQueryVariables = Exact<{
  type: GuardianRelationshipFilter;
}>;

export type AgreementPageListRelationshipsQuery = {
  __typename?: 'Query';
  subscription: {
    __typename?: 'InsightSubscriptionDto';
    id: string;
    isAvailable: boolean;
  } | null;
  relationships: Array<{
    __typename?: 'GuardianRelationshipDto';
    id: string;
    createdAt: any;
    child: {
      __typename?: 'ChildAccountDto';
      id: string;
      name: string;
      avatarImage: string | null;
    } | null;
    guardian: {
      __typename?: 'GuardianAccountDto';
      id: string;
      name: string;
    } | null;
  } | null> | null;
};

export type BillingManagementPageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type BillingManagementPageQuery = {
  __typename?: 'Query';
  subscription: {
    __typename?: 'InsightSubscriptionDto';
    id: string;
    plan: InsightSubscriptionPlan | null;
  } | null;
};

export type DemoLinkRelationPageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type DemoLinkRelationPageQuery = {
  __typename?: 'Query';
  me: { __typename?: 'AccountDto'; id: string; role: AccountRole } | null;
};

export type DemoLinkRelationPageRelationshipQueryVariables = Exact<{
  type: GuardianRelationshipFilter;
}>;

export type DemoLinkRelationPageRelationshipQuery = {
  __typename?: 'Query';
  relationships: Array<{
    __typename?: 'GuardianRelationshipDto';
    id: string;
    childAccount: { __typename?: 'ChildAccountDto'; id: string } | null;
  } | null> | null;
};

export type DemoPageQueryVariables = Exact<{ [key: string]: never }>;

export type DemoPageQuery = {
  __typename?: 'Query';
  subscription: {
    __typename?: 'InsightSubscriptionDto';
    id: string;
    isAvailable: boolean;
  } | null;
};

export type DemoPageMeQueryVariables = Exact<{ [key: string]: never }>;

export type DemoPageMeQuery = {
  __typename?: 'Query';
  me: { __typename?: 'AccountDto'; id: string; role: AccountRole } | null;
};

export type DemoPageRelationshipQueryVariables = Exact<{
  type: GuardianRelationshipFilter;
}>;

export type DemoPageRelationshipQuery = {
  __typename?: 'Query';
  relationships: Array<{
    __typename?: 'GuardianRelationshipDto';
    id: string;
    guardianAccount: { __typename?: 'GuardianAccountDto'; id: string } | null;
    childAccount: { __typename?: 'ChildAccountDto'; id: string } | null;
  } | null> | null;
};

export type LinkRelationPageQueryVariables = Exact<{ [key: string]: never }>;

export type LinkRelationPageQuery = {
  __typename?: 'Query';
  me: { __typename?: 'AccountDto'; id: string; role: AccountRole } | null;
};

export type LinkRlationPageListRelationshipsQueryVariables = Exact<{
  type: GuardianRelationshipFilter;
}>;

export type LinkRlationPageListRelationshipsQuery = {
  __typename?: 'Query';
  relationships: Array<{
    __typename?: 'GuardianRelationshipDto';
    id: string;
    child: { __typename?: 'ChildAccountDto'; id: string; name: string } | null;
    guardian: {
      __typename?: 'GuardianAccountDto';
      id: string;
      name: string;
    } | null;
  } | null> | null;
};

export type Subscription2PageQueryVariables = Exact<{ [key: string]: never }>;

export type Subscription2PageQuery = {
  __typename?: 'Query';
  subscription: {
    __typename?: 'InsightSubscriptionDto';
    id: string;
    isAvailable: boolean;
  } | null;
  relationships: Array<{
    __typename?: 'GuardianRelationshipDto';
    id: string;
    childAccount: { __typename?: 'ChildAccountDto'; id: string } | null;
  } | null> | null;
};

export const ActivityItemActivityFieldsFragmentDoc = gql`
  fragment ActivityItemActivityFields on ActivityDto {
    id
    type
    summary
    content
    image
    publishedAt
    actor
    object
    target
    generator
    stickers {
      code
      senderId
    }
  }
`;
export const ActivityItemDemoActivityFieldsFragmentDoc = gql`
  fragment ActivityItemDemoActivityFields on ActivityDto {
    id
    type
    summary
    content
    image
    publishedAt
    actor
    object
    target
    generator
    stickers {
      code
      senderId
    }
  }
`;
export const ChildProfileDemoInsightChildDtoFieldsFragmentDoc = gql`
  fragment ChildProfileDemoInsightChildDtoFields on ChildAccountDto {
    id
    name
    avatarImage
    schoolInfo {
      school {
        id
        name
      }
      grade
    }
  }
`;
export const ActivityTableRowClassroomActivityCountByMemberFieldsFragmentDoc = gql`
  fragment ActivityTableRowClassroomActivityCountByMemberFields on ClassroomActivityCountByMemberDto {
    memberId
    memberName
    postCreationCount
    commentCount
    replyCount
    reactionCount
    lastVisitDate
  }
`;
export const FriendshipClassroomFriendshipCollectionFieldsFragmentDoc = gql`
  fragment FriendshipClassroomFriendshipCollectionFields on ClassroomFriendshipCollectionDto {
    classroomId
    friendshipWeightAverage
    friendships {
      id
      name
      profileImage
      rank
      friendshipActivityCount
      friendCountLevel
      friendshipWeightList {
        id
        friendshipWeightLevel
      }
    }
  }
`;
export const FriendshipWeightRankListFriendshipFieldsFragmentDoc = gql`
  fragment FriendshipWeightRankListFriendshipFields on Friendship {
    id
    name
    rank
    friendshipActivityCount
  }
`;
export const PostReadRateByMemberItemClassroomPostReadRateByMemberFieldsFragmentDoc = gql`
  fragment PostReadRateByMemberItemClassroomPostReadRateByMemberFields on ClassroomPostReadRateByMemberDto {
    memberId
    memberName
    postCount
    postReadCount
    postReadRate
  }
`;
export const PostReadRateByPostItemClassroomPostReadRateByPostFieldsFragmentDoc = gql`
  fragment PostReadRateByPostItemClassroomPostReadRateByPostFields on ClassroomPostReadRateByPostDto {
    postId
    postTitle
    postUrl
    postCreatedAt
    postReadRate
  }
`;
export const YesterdayActivityCountClassroomActivityCountFieldsFragmentDoc = gql`
  fragment YesterdayActivityCountClassroomActivityCountFields on ClassroomActivityCountDto {
    classroomId
    postCreationCount
    commentCount
    replyCount
    reactionCount
  }
`;
export const CumulativeRecordFormMemberSelectorClassroomMemberFieldsFragmentDoc = gql`
  fragment CumulativeRecordFormMemberSelectorClassroomMemberFields on ClassroomMemberDto {
    id
    name
    profileImageUrl
  }
`;
export const CumulativeRecordItemCumulativeRecordFieldsFragmentDoc = gql`
  fragment CumulativeRecordItemCumulativeRecordFields on CumulativeRecordDto {
    id
    classroomId
    memberId
    contents
    recordedAt
    createdAt
  }
`;
export const MemberCumulativeRecordItemCumulativeRecordFieldsFragmentDoc = gql`
  fragment MemberCumulativeRecordItemCumulativeRecordFields on CumulativeRecordDto {
    id
    classroomId
    memberId
    contents
    recordedAt
    createdAt
  }
`;
export const PostReactionDashboardPostReactionFieldsFragmentDoc = gql`
  fragment PostReactionDashboardPostReactionFields on PostReactionDto {
    classroomId
    classroomName
    classAverage
    userEventCount
    userLevel
  }
`;
export const PostReadDashboardPostReadFieldsFragmentDoc = gql`
  fragment PostReadDashboardPostReadFields on PostReadDto {
    classroomId
    classroomName
    classAverage
    postCount
    userEventCount
    userLevel
  }
`;
export const RelationshipDashboardFriendFieldsFragmentDoc = gql`
  fragment RelationshipDashboardFriendFields on Friend {
    id
    rank
    avatarImage
    profileImage
    name
    username
    description
  }
`;
export const RelationshipNetworkGraphFriendFieldsFragmentDoc = gql`
  fragment RelationshipNetworkGraphFriendFields on Friend {
    id
    rank
    avatarImage
    profileImage
    name
    username
    description
  }
`;
export const PraiseStampBoardGoalFieldsFragmentDoc = gql`
  fragment PraiseStampBoardGoalFields on GoalDto {
    id
    stamps {
      type
    }
    startAt
    endAt
    status
    targetStampCount
    reward
  }
`;
export const SelectedChildInfoChildAccountFieldsFragmentDoc = gql`
  fragment SelectedChildInfoChildAccountFields on ChildAccountDto {
    id
    name
    avatarImage
    schoolInfo {
      grade
      school {
        id
        name
      }
    }
  }
`;
export const TodoItemTodoFieldsFragmentDoc = gql`
  fragment TodoItemTodoFields on TodoDto {
    id
    type
    completed
    title
    dueAt
    completedAt
    createdAt
    originalUrl
  }
`;
export const ChildInfoQueryDocument = gql`
  query childInfoQuery {
    me: getMe {
      id
      role
    }
  }
`;

/**
 * __useChildInfoQuery__
 *
 * To run a query within a React component, call `useChildInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useChildInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChildInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useChildInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<ChildInfoQuery, ChildInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChildInfoQuery, ChildInfoQueryVariables>(
    ChildInfoQueryDocument,
    options
  );
}
export function useChildInfoQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChildInfoQuery,
    ChildInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChildInfoQuery, ChildInfoQueryVariables>(
    ChildInfoQueryDocument,
    options
  );
}
export type ChildInfoQueryHookResult = ReturnType<typeof useChildInfoQuery>;
export type ChildInfoQueryLazyQueryHookResult = ReturnType<
  typeof useChildInfoQueryLazyQuery
>;
export type ChildInfoQueryQueryResult = Apollo.QueryResult<
  ChildInfoQuery,
  ChildInfoQueryVariables
>;
export const ChildInfoForGuardianQueryDocument = gql`
  query childInfoForGuardianQuery {
    subscription: getLatestSubscription {
      id
      childIds
    }
    guardianRelationships: listGuardianRelationships(type: child) {
      childAccount {
        id
        name
        avatarImage
        schoolInfo {
          grade
          school {
            name
          }
        }
      }
    }
  }
`;

/**
 * __useChildInfoForGuardianQuery__
 *
 * To run a query within a React component, call `useChildInfoForGuardianQuery` and pass it any options that fit your needs.
 * When your component renders, `useChildInfoForGuardianQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChildInfoForGuardianQuery({
 *   variables: {
 *   },
 * });
 */
export function useChildInfoForGuardianQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ChildInfoForGuardianQuery,
    ChildInfoForGuardianQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ChildInfoForGuardianQuery,
    ChildInfoForGuardianQueryVariables
  >(ChildInfoForGuardianQueryDocument, options);
}
export function useChildInfoForGuardianQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChildInfoForGuardianQuery,
    ChildInfoForGuardianQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ChildInfoForGuardianQuery,
    ChildInfoForGuardianQueryVariables
  >(ChildInfoForGuardianQueryDocument, options);
}
export type ChildInfoForGuardianQueryHookResult = ReturnType<
  typeof useChildInfoForGuardianQuery
>;
export type ChildInfoForGuardianQueryLazyQueryHookResult = ReturnType<
  typeof useChildInfoForGuardianQueryLazyQuery
>;
export type ChildInfoForGuardianQueryQueryResult = Apollo.QueryResult<
  ChildInfoForGuardianQuery,
  ChildInfoForGuardianQueryVariables
>;
export const ChildInfoForChildQueryDocument = gql`
  query childInfoForChildQuery {
    meInfo: getMe {
      id
      name
      avatarImage
      schoolInfo: schoolInfoDto {
        grade
        school {
          name
        }
      }
    }
  }
`;

/**
 * __useChildInfoForChildQuery__
 *
 * To run a query within a React component, call `useChildInfoForChildQuery` and pass it any options that fit your needs.
 * When your component renders, `useChildInfoForChildQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChildInfoForChildQuery({
 *   variables: {
 *   },
 * });
 */
export function useChildInfoForChildQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ChildInfoForChildQuery,
    ChildInfoForChildQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ChildInfoForChildQuery,
    ChildInfoForChildQueryVariables
  >(ChildInfoForChildQueryDocument, options);
}
export function useChildInfoForChildQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChildInfoForChildQuery,
    ChildInfoForChildQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ChildInfoForChildQuery,
    ChildInfoForChildQueryVariables
  >(ChildInfoForChildQueryDocument, options);
}
export type ChildInfoForChildQueryHookResult = ReturnType<
  typeof useChildInfoForChildQuery
>;
export type ChildInfoForChildQueryLazyQueryHookResult = ReturnType<
  typeof useChildInfoForChildQueryLazyQuery
>;
export type ChildInfoForChildQueryQueryResult = Apollo.QueryResult<
  ChildInfoForChildQuery,
  ChildInfoForChildQueryVariables
>;
export const ChildTrackingInfoDocument = gql`
  query ChildTrackingInfo {
    relationships: listGuardianRelationships(type: child) {
      id
      childAccount {
        id
        schoolInfo {
          grade
        }
      }
    }
  }
`;

/**
 * __useChildTrackingInfoQuery__
 *
 * To run a query within a React component, call `useChildTrackingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useChildTrackingInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChildTrackingInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useChildTrackingInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ChildTrackingInfoQuery,
    ChildTrackingInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ChildTrackingInfoQuery,
    ChildTrackingInfoQueryVariables
  >(ChildTrackingInfoDocument, options);
}
export function useChildTrackingInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChildTrackingInfoQuery,
    ChildTrackingInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ChildTrackingInfoQuery,
    ChildTrackingInfoQueryVariables
  >(ChildTrackingInfoDocument, options);
}
export type ChildTrackingInfoQueryHookResult = ReturnType<
  typeof useChildTrackingInfoQuery
>;
export type ChildTrackingInfoLazyQueryHookResult = ReturnType<
  typeof useChildTrackingInfoLazyQuery
>;
export type ChildTrackingInfoQueryResult = Apollo.QueryResult<
  ChildTrackingInfoQuery,
  ChildTrackingInfoQueryVariables
>;
export const ActivityItemQueryDocument = gql`
  query ActivityItemQuery {
    me: getInsightsAccount {
      id
      role
    }
  }
`;

/**
 * __useActivityItemQuery__
 *
 * To run a query within a React component, call `useActivityItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityItemQuery({
 *   variables: {
 *   },
 * });
 */
export function useActivityItemQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ActivityItemQuery,
    ActivityItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActivityItemQuery, ActivityItemQueryVariables>(
    ActivityItemQueryDocument,
    options
  );
}
export function useActivityItemQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActivityItemQuery,
    ActivityItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActivityItemQuery, ActivityItemQueryVariables>(
    ActivityItemQueryDocument,
    options
  );
}
export type ActivityItemQueryHookResult = ReturnType<
  typeof useActivityItemQuery
>;
export type ActivityItemQueryLazyQueryHookResult = ReturnType<
  typeof useActivityItemQueryLazyQuery
>;
export type ActivityItemQueryQueryResult = Apollo.QueryResult<
  ActivityItemQuery,
  ActivityItemQueryVariables
>;
export const ActivityItemDemoQueryDocument = gql`
  query ActivityItemDemoQuery {
    me: getMe {
      id
      role
    }
  }
`;

/**
 * __useActivityItemDemoQuery__
 *
 * To run a query within a React component, call `useActivityItemDemoQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityItemDemoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityItemDemoQuery({
 *   variables: {
 *   },
 * });
 */
export function useActivityItemDemoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ActivityItemDemoQuery,
    ActivityItemDemoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActivityItemDemoQuery, ActivityItemDemoQueryVariables>(
    ActivityItemDemoQueryDocument,
    options
  );
}
export function useActivityItemDemoQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActivityItemDemoQuery,
    ActivityItemDemoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ActivityItemDemoQuery,
    ActivityItemDemoQueryVariables
  >(ActivityItemDemoQueryDocument, options);
}
export type ActivityItemDemoQueryHookResult = ReturnType<
  typeof useActivityItemDemoQuery
>;
export type ActivityItemDemoQueryLazyQueryHookResult = ReturnType<
  typeof useActivityItemDemoQueryLazyQuery
>;
export type ActivityItemDemoQueryQueryResult = Apollo.QueryResult<
  ActivityItemDemoQuery,
  ActivityItemDemoQueryVariables
>;
export const ActivityItemDemoAccountQueryDocument = gql`
  query ActivityItemDemoAccountQuery($id: String!) {
    account: getAccount(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useActivityItemDemoAccountQuery__
 *
 * To run a query within a React component, call `useActivityItemDemoAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityItemDemoAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityItemDemoAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivityItemDemoAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    ActivityItemDemoAccountQuery,
    ActivityItemDemoAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ActivityItemDemoAccountQuery,
    ActivityItemDemoAccountQueryVariables
  >(ActivityItemDemoAccountQueryDocument, options);
}
export function useActivityItemDemoAccountQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActivityItemDemoAccountQuery,
    ActivityItemDemoAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ActivityItemDemoAccountQuery,
    ActivityItemDemoAccountQueryVariables
  >(ActivityItemDemoAccountQueryDocument, options);
}
export type ActivityItemDemoAccountQueryHookResult = ReturnType<
  typeof useActivityItemDemoAccountQuery
>;
export type ActivityItemDemoAccountQueryLazyQueryHookResult = ReturnType<
  typeof useActivityItemDemoAccountQueryLazyQuery
>;
export type ActivityItemDemoAccountQueryQueryResult = Apollo.QueryResult<
  ActivityItemDemoAccountQuery,
  ActivityItemDemoAccountQueryVariables
>;
export const ActivityItemFooterMutationDocument = gql`
  mutation ActivityItemFooterMutation(
    $activityId: String!
    $childId: String!
    $praiseChildActivityDtoInput: PraiseChildActivityDtoInput
  ) {
    praiseChildActivity(
      activityId: $activityId
      childId: $childId
      input: $praiseChildActivityDtoInput
    ) {
      ...ActivityItemActivityFields
    }
  }
  ${ActivityItemActivityFieldsFragmentDoc}
`;
export type ActivityItemFooterMutationMutationFn = Apollo.MutationFunction<
  ActivityItemFooterMutation,
  ActivityItemFooterMutationVariables
>;

/**
 * __useActivityItemFooterMutation__
 *
 * To run a mutation, you first call `useActivityItemFooterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivityItemFooterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activityItemFooterMutation, { data, loading, error }] = useActivityItemFooterMutation({
 *   variables: {
 *      activityId: // value for 'activityId'
 *      childId: // value for 'childId'
 *      praiseChildActivityDtoInput: // value for 'praiseChildActivityDtoInput'
 *   },
 * });
 */
export function useActivityItemFooterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivityItemFooterMutation,
    ActivityItemFooterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ActivityItemFooterMutation,
    ActivityItemFooterMutationVariables
  >(ActivityItemFooterMutationDocument, options);
}
export type ActivityItemFooterMutationHookResult = ReturnType<
  typeof useActivityItemFooterMutation
>;
export type ActivityItemFooterMutationMutationResult =
  Apollo.MutationResult<ActivityItemFooterMutation>;
export type ActivityItemFooterMutationMutationOptions =
  Apollo.BaseMutationOptions<
    ActivityItemFooterMutation,
    ActivityItemFooterMutationVariables
  >;
export const ActivityItemFooterQueryDocument = gql`
  query ActivityItemFooterQuery {
    me: getInsightsAccount {
      id
      role
    }
  }
`;

/**
 * __useActivityItemFooterQuery__
 *
 * To run a query within a React component, call `useActivityItemFooterQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityItemFooterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityItemFooterQuery({
 *   variables: {
 *   },
 * });
 */
export function useActivityItemFooterQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ActivityItemFooterQuery,
    ActivityItemFooterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ActivityItemFooterQuery,
    ActivityItemFooterQueryVariables
  >(ActivityItemFooterQueryDocument, options);
}
export function useActivityItemFooterQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActivityItemFooterQuery,
    ActivityItemFooterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ActivityItemFooterQuery,
    ActivityItemFooterQueryVariables
  >(ActivityItemFooterQueryDocument, options);
}
export type ActivityItemFooterQueryHookResult = ReturnType<
  typeof useActivityItemFooterQuery
>;
export type ActivityItemFooterQueryLazyQueryHookResult = ReturnType<
  typeof useActivityItemFooterQueryLazyQuery
>;
export type ActivityItemFooterQueryQueryResult = Apollo.QueryResult<
  ActivityItemFooterQuery,
  ActivityItemFooterQueryVariables
>;
export const ActivityListQueryDocument = gql`
  query ActivityListQuery(
    $childId: String!
    $limit: Float = 30
    $startDate: DateTime
    $endDate: DateTime
    $cursor: String
  ) {
    activitiesCollection: listChildActivities(
      childId: $childId
      limit: $limit
      startDate: $startDate
      endDate: $endDate
      cursor: $cursor
    ) {
      activities {
        ...ActivityItemActivityFields
      }
      cursor
    }
  }
  ${ActivityItemActivityFieldsFragmentDoc}
`;

/**
 * __useActivityListQuery__
 *
 * To run a query within a React component, call `useActivityListQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityListQuery({
 *   variables: {
 *      childId: // value for 'childId'
 *      limit: // value for 'limit'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useActivityListQuery(
  baseOptions: Apollo.QueryHookOptions<
    ActivityListQuery,
    ActivityListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActivityListQuery, ActivityListQueryVariables>(
    ActivityListQueryDocument,
    options
  );
}
export function useActivityListQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActivityListQuery,
    ActivityListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActivityListQuery, ActivityListQueryVariables>(
    ActivityListQueryDocument,
    options
  );
}
export type ActivityListQueryHookResult = ReturnType<
  typeof useActivityListQuery
>;
export type ActivityListQueryLazyQueryHookResult = ReturnType<
  typeof useActivityListQueryLazyQuery
>;
export type ActivityListQueryQueryResult = Apollo.QueryResult<
  ActivityListQuery,
  ActivityListQueryVariables
>;
export const ClassActivitiesQueryDocument = gql`
  query ClassActivitiesQuery {
    subscription: getLatestSubscription {
      id
      childIds
    }
    me: getMe {
      id
      signedUpAt
      role
    }
  }
`;

/**
 * __useClassActivitiesQuery__
 *
 * To run a query within a React component, call `useClassActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassActivitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useClassActivitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ClassActivitiesQuery,
    ClassActivitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClassActivitiesQuery, ClassActivitiesQueryVariables>(
    ClassActivitiesQueryDocument,
    options
  );
}
export function useClassActivitiesQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClassActivitiesQuery,
    ClassActivitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClassActivitiesQuery,
    ClassActivitiesQueryVariables
  >(ClassActivitiesQueryDocument, options);
}
export type ClassActivitiesQueryHookResult = ReturnType<
  typeof useClassActivitiesQuery
>;
export type ClassActivitiesQueryLazyQueryHookResult = ReturnType<
  typeof useClassActivitiesQueryLazyQuery
>;
export type ClassActivitiesQueryQueryResult = Apollo.QueryResult<
  ClassActivitiesQuery,
  ClassActivitiesQueryVariables
>;
export const ClassActivitiesGoalQueryDocument = gql`
  query ClassActivitiesGoalQuery($guardianId: String!, $childId: String!) {
    goal: getLatestGoal(guardianId: $guardianId, childId: $childId) {
      id
      status
    }
  }
`;

/**
 * __useClassActivitiesGoalQuery__
 *
 * To run a query within a React component, call `useClassActivitiesGoalQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassActivitiesGoalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassActivitiesGoalQuery({
 *   variables: {
 *      guardianId: // value for 'guardianId'
 *      childId: // value for 'childId'
 *   },
 * });
 */
export function useClassActivitiesGoalQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClassActivitiesGoalQuery,
    ClassActivitiesGoalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClassActivitiesGoalQuery,
    ClassActivitiesGoalQueryVariables
  >(ClassActivitiesGoalQueryDocument, options);
}
export function useClassActivitiesGoalQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClassActivitiesGoalQuery,
    ClassActivitiesGoalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClassActivitiesGoalQuery,
    ClassActivitiesGoalQueryVariables
  >(ClassActivitiesGoalQueryDocument, options);
}
export type ClassActivitiesGoalQueryHookResult = ReturnType<
  typeof useClassActivitiesGoalQuery
>;
export type ClassActivitiesGoalQueryLazyQueryHookResult = ReturnType<
  typeof useClassActivitiesGoalQueryLazyQuery
>;
export type ClassActivitiesGoalQueryQueryResult = Apollo.QueryResult<
  ClassActivitiesGoalQuery,
  ClassActivitiesGoalQueryVariables
>;
export const ClassActivitiesRelationshipQueryDocument = gql`
  query ClassActivitiesRelationshipQuery(
    $type: GuardianRelationshipFilter = child
  ) {
    relationships: listGuardianRelationships(type: $type) {
      id
      childAccount {
        id
        signedUpAt
      }
    }
  }
`;

/**
 * __useClassActivitiesRelationshipQuery__
 *
 * To run a query within a React component, call `useClassActivitiesRelationshipQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassActivitiesRelationshipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassActivitiesRelationshipQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useClassActivitiesRelationshipQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ClassActivitiesRelationshipQuery,
    ClassActivitiesRelationshipQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClassActivitiesRelationshipQuery,
    ClassActivitiesRelationshipQueryVariables
  >(ClassActivitiesRelationshipQueryDocument, options);
}
export function useClassActivitiesRelationshipQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClassActivitiesRelationshipQuery,
    ClassActivitiesRelationshipQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClassActivitiesRelationshipQuery,
    ClassActivitiesRelationshipQueryVariables
  >(ClassActivitiesRelationshipQueryDocument, options);
}
export type ClassActivitiesRelationshipQueryHookResult = ReturnType<
  typeof useClassActivitiesRelationshipQuery
>;
export type ClassActivitiesRelationshipQueryLazyQueryHookResult = ReturnType<
  typeof useClassActivitiesRelationshipQueryLazyQuery
>;
export type ClassActivitiesRelationshipQueryQueryResult = Apollo.QueryResult<
  ClassActivitiesRelationshipQuery,
  ClassActivitiesRelationshipQueryVariables
>;
export const ClassActivitiesDemoQueryDocument = gql`
  query ClassActivitiesDemoQuery(
    $type: GuardianRelationshipFilter = child
    $childId: String!
    $limit: Float = 20
  ) {
    relationships: listGuardianRelationships(type: $type) {
      id
      childAccount {
        id
        signedUpAt
      }
    }
    activitiesCollection: listChildActivities(
      childId: $childId
      limit: $limit
    ) {
      activities {
        ...ActivityItemActivityFields
      }
    }
  }
  ${ActivityItemActivityFieldsFragmentDoc}
`;

/**
 * __useClassActivitiesDemoQuery__
 *
 * To run a query within a React component, call `useClassActivitiesDemoQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassActivitiesDemoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassActivitiesDemoQuery({
 *   variables: {
 *      type: // value for 'type'
 *      childId: // value for 'childId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useClassActivitiesDemoQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClassActivitiesDemoQuery,
    ClassActivitiesDemoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClassActivitiesDemoQuery,
    ClassActivitiesDemoQueryVariables
  >(ClassActivitiesDemoQueryDocument, options);
}
export function useClassActivitiesDemoQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClassActivitiesDemoQuery,
    ClassActivitiesDemoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClassActivitiesDemoQuery,
    ClassActivitiesDemoQueryVariables
  >(ClassActivitiesDemoQueryDocument, options);
}
export type ClassActivitiesDemoQueryHookResult = ReturnType<
  typeof useClassActivitiesDemoQuery
>;
export type ClassActivitiesDemoQueryLazyQueryHookResult = ReturnType<
  typeof useClassActivitiesDemoQueryLazyQuery
>;
export type ClassActivitiesDemoQueryQueryResult = Apollo.QueryResult<
  ClassActivitiesDemoQuery,
  ClassActivitiesDemoQueryVariables
>;
export const SubscriptionGuideQueryDocument = gql`
  query SubscriptionGuideQuery {
    relationships: listGuardianRelationships(type: child) {
      id
      childAccount {
        id
        schoolInfo {
          grade
        }
      }
    }
  }
`;

/**
 * __useSubscriptionGuideQuery__
 *
 * To run a query within a React component, call `useSubscriptionGuideQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionGuideQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionGuideQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionGuideQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SubscriptionGuideQuery,
    SubscriptionGuideQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SubscriptionGuideQuery,
    SubscriptionGuideQueryVariables
  >(SubscriptionGuideQueryDocument, options);
}
export function useSubscriptionGuideQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubscriptionGuideQuery,
    SubscriptionGuideQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SubscriptionGuideQuery,
    SubscriptionGuideQueryVariables
  >(SubscriptionGuideQueryDocument, options);
}
export type SubscriptionGuideQueryHookResult = ReturnType<
  typeof useSubscriptionGuideQuery
>;
export type SubscriptionGuideQueryLazyQueryHookResult = ReturnType<
  typeof useSubscriptionGuideQueryLazyQuery
>;
export type SubscriptionGuideQueryQueryResult = Apollo.QueryResult<
  SubscriptionGuideQuery,
  SubscriptionGuideQueryVariables
>;
export const ClassActivitiesSummaryQueryDocument = gql`
  query ClassActivitiesSummaryQuery {
    subscription: getLatestSubscription {
      id
      childIds
    }
    me: getInsightsAccount {
      id
      role
    }
  }
`;

/**
 * __useClassActivitiesSummaryQuery__
 *
 * To run a query within a React component, call `useClassActivitiesSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassActivitiesSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassActivitiesSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useClassActivitiesSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ClassActivitiesSummaryQuery,
    ClassActivitiesSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClassActivitiesSummaryQuery,
    ClassActivitiesSummaryQueryVariables
  >(ClassActivitiesSummaryQueryDocument, options);
}
export function useClassActivitiesSummaryQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClassActivitiesSummaryQuery,
    ClassActivitiesSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClassActivitiesSummaryQuery,
    ClassActivitiesSummaryQueryVariables
  >(ClassActivitiesSummaryQueryDocument, options);
}
export type ClassActivitiesSummaryQueryHookResult = ReturnType<
  typeof useClassActivitiesSummaryQuery
>;
export type ClassActivitiesSummaryQueryLazyQueryHookResult = ReturnType<
  typeof useClassActivitiesSummaryQueryLazyQuery
>;
export type ClassActivitiesSummaryQueryQueryResult = Apollo.QueryResult<
  ClassActivitiesSummaryQuery,
  ClassActivitiesSummaryQueryVariables
>;
export const ClassActivitiesSummaryActivityQueryDocument = gql`
  query ClassActivitiesSummaryActivityQuery(
    $childId: String!
    $limit: Float = 10
    $startDate: DateTime
    $endDate: DateTime
  ) {
    activitiesCollection: listChildActivities(
      childId: $childId
      startDate: $startDate
      endDate: $endDate
      limit: $limit
    ) {
      activities {
        ...ActivityItemActivityFields
      }
    }
  }
  ${ActivityItemActivityFieldsFragmentDoc}
`;

/**
 * __useClassActivitiesSummaryActivityQuery__
 *
 * To run a query within a React component, call `useClassActivitiesSummaryActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassActivitiesSummaryActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassActivitiesSummaryActivityQuery({
 *   variables: {
 *      childId: // value for 'childId'
 *      limit: // value for 'limit'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useClassActivitiesSummaryActivityQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClassActivitiesSummaryActivityQuery,
    ClassActivitiesSummaryActivityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClassActivitiesSummaryActivityQuery,
    ClassActivitiesSummaryActivityQueryVariables
  >(ClassActivitiesSummaryActivityQueryDocument, options);
}
export function useClassActivitiesSummaryActivityQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClassActivitiesSummaryActivityQuery,
    ClassActivitiesSummaryActivityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClassActivitiesSummaryActivityQuery,
    ClassActivitiesSummaryActivityQueryVariables
  >(ClassActivitiesSummaryActivityQueryDocument, options);
}
export type ClassActivitiesSummaryActivityQueryHookResult = ReturnType<
  typeof useClassActivitiesSummaryActivityQuery
>;
export type ClassActivitiesSummaryActivityQueryLazyQueryHookResult = ReturnType<
  typeof useClassActivitiesSummaryActivityQueryLazyQuery
>;
export type ClassActivitiesSummaryActivityQueryQueryResult = Apollo.QueryResult<
  ClassActivitiesSummaryActivityQuery,
  ClassActivitiesSummaryActivityQueryVariables
>;
export const ClassActivitiesSummaryDemoQueryDocument = gql`
  query ClassActivitiesSummaryDemoQuery(
    $childId: String!
    $limit: Float = 10
    $startDate: DateTime
    $endDate: DateTime
  ) {
    activitiesCollection: listChildActivities(
      childId: $childId
      startDate: $startDate
      endDate: $endDate
      limit: $limit
    ) {
      activities {
        ...ActivityItemActivityFields
      }
    }
    relationships: listGuardianRelationships(type: child) {
      id
      childAccount {
        id
        schoolInfo {
          grade
        }
      }
    }
  }
  ${ActivityItemActivityFieldsFragmentDoc}
`;

/**
 * __useClassActivitiesSummaryDemoQuery__
 *
 * To run a query within a React component, call `useClassActivitiesSummaryDemoQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassActivitiesSummaryDemoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassActivitiesSummaryDemoQuery({
 *   variables: {
 *      childId: // value for 'childId'
 *      limit: // value for 'limit'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useClassActivitiesSummaryDemoQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClassActivitiesSummaryDemoQuery,
    ClassActivitiesSummaryDemoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClassActivitiesSummaryDemoQuery,
    ClassActivitiesSummaryDemoQueryVariables
  >(ClassActivitiesSummaryDemoQueryDocument, options);
}
export function useClassActivitiesSummaryDemoQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClassActivitiesSummaryDemoQuery,
    ClassActivitiesSummaryDemoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClassActivitiesSummaryDemoQuery,
    ClassActivitiesSummaryDemoQueryVariables
  >(ClassActivitiesSummaryDemoQueryDocument, options);
}
export type ClassActivitiesSummaryDemoQueryHookResult = ReturnType<
  typeof useClassActivitiesSummaryDemoQuery
>;
export type ClassActivitiesSummaryDemoQueryLazyQueryHookResult = ReturnType<
  typeof useClassActivitiesSummaryDemoQueryLazyQuery
>;
export type ClassActivitiesSummaryDemoQueryQueryResult = Apollo.QueryResult<
  ClassActivitiesSummaryDemoQuery,
  ClassActivitiesSummaryDemoQueryVariables
>;
export const MemberActivityListQueryDocument = gql`
  query MemberActivityListQuery(
    $memberId: String!
    $generatorId: String!
    $generatorType: String! = "Classroom"
    $limit: PositiveFloat
    $cursor: String
  ) {
    activitiesCollection: listMemberActivities(
      generatorId: $generatorId
      generatorType: $generatorType
      memberId: $memberId
      limit: $limit
      cursor: $cursor
    ) {
      activities {
        ...ActivityItemActivityFields
      }
      cursor
    }
  }
  ${ActivityItemActivityFieldsFragmentDoc}
`;

/**
 * __useMemberActivityListQuery__
 *
 * To run a query within a React component, call `useMemberActivityListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberActivityListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberActivityListQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      generatorId: // value for 'generatorId'
 *      generatorType: // value for 'generatorType'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useMemberActivityListQuery(
  baseOptions: Apollo.QueryHookOptions<
    MemberActivityListQuery,
    MemberActivityListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MemberActivityListQuery,
    MemberActivityListQueryVariables
  >(MemberActivityListQueryDocument, options);
}
export function useMemberActivityListQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberActivityListQuery,
    MemberActivityListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MemberActivityListQuery,
    MemberActivityListQueryVariables
  >(MemberActivityListQueryDocument, options);
}
export type MemberActivityListQueryHookResult = ReturnType<
  typeof useMemberActivityListQuery
>;
export type MemberActivityListQueryLazyQueryHookResult = ReturnType<
  typeof useMemberActivityListQueryLazyQuery
>;
export type MemberActivityListQueryQueryResult = Apollo.QueryResult<
  MemberActivityListQuery,
  MemberActivityListQueryVariables
>;
export const ChildProfileQueryDocument = gql`
  query ChildProfileQuery {
    me: getInsightsAccount {
      id
      role
    }
    subscription: getLatestSubscription {
      id
      plan
    }
  }
`;

/**
 * __useChildProfileQuery__
 *
 * To run a query within a React component, call `useChildProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useChildProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChildProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useChildProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ChildProfileQuery,
    ChildProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChildProfileQuery, ChildProfileQueryVariables>(
    ChildProfileQueryDocument,
    options
  );
}
export function useChildProfileQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChildProfileQuery,
    ChildProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChildProfileQuery, ChildProfileQueryVariables>(
    ChildProfileQueryDocument,
    options
  );
}
export type ChildProfileQueryHookResult = ReturnType<
  typeof useChildProfileQuery
>;
export type ChildProfileQueryLazyQueryHookResult = ReturnType<
  typeof useChildProfileQueryLazyQuery
>;
export type ChildProfileQueryQueryResult = Apollo.QueryResult<
  ChildProfileQuery,
  ChildProfileQueryVariables
>;
export const ChildProfileDemoQueryDocument = gql`
  query ChildProfileDemoQuery {
    guardianRelationships: listGuardianRelationships(type: child) {
      childAccount {
        ...ChildProfileDemoInsightChildDtoFields
      }
    }
  }
  ${ChildProfileDemoInsightChildDtoFieldsFragmentDoc}
`;

/**
 * __useChildProfileDemoQuery__
 *
 * To run a query within a React component, call `useChildProfileDemoQuery` and pass it any options that fit your needs.
 * When your component renders, `useChildProfileDemoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChildProfileDemoQuery({
 *   variables: {
 *   },
 * });
 */
export function useChildProfileDemoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ChildProfileDemoQuery,
    ChildProfileDemoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChildProfileDemoQuery, ChildProfileDemoQueryVariables>(
    ChildProfileDemoQueryDocument,
    options
  );
}
export function useChildProfileDemoQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChildProfileDemoQuery,
    ChildProfileDemoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ChildProfileDemoQuery,
    ChildProfileDemoQueryVariables
  >(ChildProfileDemoQueryDocument, options);
}
export type ChildProfileDemoQueryHookResult = ReturnType<
  typeof useChildProfileDemoQuery
>;
export type ChildProfileDemoQueryLazyQueryHookResult = ReturnType<
  typeof useChildProfileDemoQueryLazyQuery
>;
export type ChildProfileDemoQueryQueryResult = Apollo.QueryResult<
  ChildProfileDemoQuery,
  ChildProfileDemoQueryVariables
>;
export const ChildReconnectionQueryDocument = gql`
  query ChildReconnectionQuery {
    subscription: getLatestSubscription {
      id
      isAvailable
      childIds
    }
    guardianRelationships: listGuardianRelationships(type: child) {
      id
      childAccount {
        id
      }
    }
  }
`;

/**
 * __useChildReconnectionQuery__
 *
 * To run a query within a React component, call `useChildReconnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useChildReconnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChildReconnectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useChildReconnectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ChildReconnectionQuery,
    ChildReconnectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ChildReconnectionQuery,
    ChildReconnectionQueryVariables
  >(ChildReconnectionQueryDocument, options);
}
export function useChildReconnectionQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChildReconnectionQuery,
    ChildReconnectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ChildReconnectionQuery,
    ChildReconnectionQueryVariables
  >(ChildReconnectionQueryDocument, options);
}
export type ChildReconnectionQueryHookResult = ReturnType<
  typeof useChildReconnectionQuery
>;
export type ChildReconnectionQueryLazyQueryHookResult = ReturnType<
  typeof useChildReconnectionQueryLazyQuery
>;
export type ChildReconnectionQueryQueryResult = Apollo.QueryResult<
  ChildReconnectionQuery,
  ChildReconnectionQueryVariables
>;
export const ChildReconnectionAccountQueryDocument = gql`
  query ChildReconnectionAccountQuery($id: String!) {
    account: getAccount(id: $id) {
      id
      name
      avatarImage
    }
  }
`;

/**
 * __useChildReconnectionAccountQuery__
 *
 * To run a query within a React component, call `useChildReconnectionAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useChildReconnectionAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChildReconnectionAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChildReconnectionAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChildReconnectionAccountQuery,
    ChildReconnectionAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ChildReconnectionAccountQuery,
    ChildReconnectionAccountQueryVariables
  >(ChildReconnectionAccountQueryDocument, options);
}
export function useChildReconnectionAccountQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChildReconnectionAccountQuery,
    ChildReconnectionAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ChildReconnectionAccountQuery,
    ChildReconnectionAccountQueryVariables
  >(ChildReconnectionAccountQueryDocument, options);
}
export type ChildReconnectionAccountQueryHookResult = ReturnType<
  typeof useChildReconnectionAccountQuery
>;
export type ChildReconnectionAccountQueryLazyQueryHookResult = ReturnType<
  typeof useChildReconnectionAccountQueryLazyQuery
>;
export type ChildReconnectionAccountQueryQueryResult = Apollo.QueryResult<
  ChildReconnectionAccountQuery,
  ChildReconnectionAccountQueryVariables
>;
export const ChildSelectPopupQueryDocument = gql`
  query ChildSelectPopupQuery {
    relationships: listGuardianRelationships(type: child) {
      id
      childAccount {
        id
        schoolInfo {
          grade
        }
      }
    }
  }
`;

/**
 * __useChildSelectPopupQuery__
 *
 * To run a query within a React component, call `useChildSelectPopupQuery` and pass it any options that fit your needs.
 * When your component renders, `useChildSelectPopupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChildSelectPopupQuery({
 *   variables: {
 *   },
 * });
 */
export function useChildSelectPopupQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ChildSelectPopupQuery,
    ChildSelectPopupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChildSelectPopupQuery, ChildSelectPopupQueryVariables>(
    ChildSelectPopupQueryDocument,
    options
  );
}
export function useChildSelectPopupQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChildSelectPopupQuery,
    ChildSelectPopupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ChildSelectPopupQuery,
    ChildSelectPopupQueryVariables
  >(ChildSelectPopupQueryDocument, options);
}
export type ChildSelectPopupQueryHookResult = ReturnType<
  typeof useChildSelectPopupQuery
>;
export type ChildSelectPopupQueryLazyQueryHookResult = ReturnType<
  typeof useChildSelectPopupQueryLazyQuery
>;
export type ChildSelectPopupQueryQueryResult = Apollo.QueryResult<
  ChildSelectPopupQuery,
  ChildSelectPopupQueryVariables
>;
export const MyProfileDemoDocument = gql`
  query MyProfileDemo {
    me: getMe {
      id
      name
      schoolInfo: schoolInfoDto {
        school {
          name
        }
        grade
      }
      profileImage
    }
  }
`;

/**
 * __useMyProfileDemoQuery__
 *
 * To run a query within a React component, call `useMyProfileDemoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyProfileDemoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyProfileDemoQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyProfileDemoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyProfileDemoQuery,
    MyProfileDemoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyProfileDemoQuery, MyProfileDemoQueryVariables>(
    MyProfileDemoDocument,
    options
  );
}
export function useMyProfileDemoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyProfileDemoQuery,
    MyProfileDemoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyProfileDemoQuery, MyProfileDemoQueryVariables>(
    MyProfileDemoDocument,
    options
  );
}
export type MyProfileDemoQueryHookResult = ReturnType<
  typeof useMyProfileDemoQuery
>;
export type MyProfileDemoLazyQueryHookResult = ReturnType<
  typeof useMyProfileDemoLazyQuery
>;
export type MyProfileDemoQueryResult = Apollo.QueryResult<
  MyProfileDemoQuery,
  MyProfileDemoQueryVariables
>;
export const ActivityTableStudentQueryDocument = gql`
  query ActivityTableStudentQuery(
    $classroomId: String!
    $group: String! = "student"
  ) {
    studentActivityCountsCollection: listClassroomActivityCountsByMember(
      classroomId: $classroomId
      group: $group
    ) {
      classroomId
      totalMemberCount
      activityCounts {
        ...ActivityTableRowClassroomActivityCountByMemberFields
      }
    }
  }
  ${ActivityTableRowClassroomActivityCountByMemberFieldsFragmentDoc}
`;

/**
 * __useActivityTableStudentQuery__
 *
 * To run a query within a React component, call `useActivityTableStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityTableStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityTableStudentQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useActivityTableStudentQuery(
  baseOptions: Apollo.QueryHookOptions<
    ActivityTableStudentQuery,
    ActivityTableStudentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ActivityTableStudentQuery,
    ActivityTableStudentQueryVariables
  >(ActivityTableStudentQueryDocument, options);
}
export function useActivityTableStudentQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActivityTableStudentQuery,
    ActivityTableStudentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ActivityTableStudentQuery,
    ActivityTableStudentQueryVariables
  >(ActivityTableStudentQueryDocument, options);
}
export type ActivityTableStudentQueryHookResult = ReturnType<
  typeof useActivityTableStudentQuery
>;
export type ActivityTableStudentQueryLazyQueryHookResult = ReturnType<
  typeof useActivityTableStudentQueryLazyQuery
>;
export type ActivityTableStudentQueryQueryResult = Apollo.QueryResult<
  ActivityTableStudentQuery,
  ActivityTableStudentQueryVariables
>;
export const ActivityTableTeacherQueryDocument = gql`
  query ActivityTableTeacherQuery(
    $classroomId: String!
    $group: String! = "teacher"
  ) {
    teacherActivityCountsCollection: listClassroomActivityCountsByMember(
      classroomId: $classroomId
      group: $group
    ) {
      classroomId
      totalMemberCount
      activityCounts {
        ...ActivityTableRowClassroomActivityCountByMemberFields
      }
    }
  }
  ${ActivityTableRowClassroomActivityCountByMemberFieldsFragmentDoc}
`;

/**
 * __useActivityTableTeacherQuery__
 *
 * To run a query within a React component, call `useActivityTableTeacherQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityTableTeacherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityTableTeacherQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useActivityTableTeacherQuery(
  baseOptions: Apollo.QueryHookOptions<
    ActivityTableTeacherQuery,
    ActivityTableTeacherQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ActivityTableTeacherQuery,
    ActivityTableTeacherQueryVariables
  >(ActivityTableTeacherQueryDocument, options);
}
export function useActivityTableTeacherQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActivityTableTeacherQuery,
    ActivityTableTeacherQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ActivityTableTeacherQuery,
    ActivityTableTeacherQueryVariables
  >(ActivityTableTeacherQueryDocument, options);
}
export type ActivityTableTeacherQueryHookResult = ReturnType<
  typeof useActivityTableTeacherQuery
>;
export type ActivityTableTeacherQueryLazyQueryHookResult = ReturnType<
  typeof useActivityTableTeacherQueryLazyQuery
>;
export type ActivityTableTeacherQueryQueryResult = Apollo.QueryResult<
  ActivityTableTeacherQuery,
  ActivityTableTeacherQueryVariables
>;
export const ActivityTableParentQueryDocument = gql`
  query ActivityTableParentQuery(
    $classroomId: String!
    $group: String! = "guardian"
  ) {
    parentActivityCountsCollection: listClassroomActivityCountsByMember(
      classroomId: $classroomId
      group: $group
    ) {
      classroomId
      totalMemberCount
      activityCounts {
        ...ActivityTableRowClassroomActivityCountByMemberFields
      }
    }
  }
  ${ActivityTableRowClassroomActivityCountByMemberFieldsFragmentDoc}
`;

/**
 * __useActivityTableParentQuery__
 *
 * To run a query within a React component, call `useActivityTableParentQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityTableParentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityTableParentQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useActivityTableParentQuery(
  baseOptions: Apollo.QueryHookOptions<
    ActivityTableParentQuery,
    ActivityTableParentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ActivityTableParentQuery,
    ActivityTableParentQueryVariables
  >(ActivityTableParentQueryDocument, options);
}
export function useActivityTableParentQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActivityTableParentQuery,
    ActivityTableParentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ActivityTableParentQuery,
    ActivityTableParentQueryVariables
  >(ActivityTableParentQueryDocument, options);
}
export type ActivityTableParentQueryHookResult = ReturnType<
  typeof useActivityTableParentQuery
>;
export type ActivityTableParentQueryLazyQueryHookResult = ReturnType<
  typeof useActivityTableParentQueryLazyQuery
>;
export type ActivityTableParentQueryQueryResult = Apollo.QueryResult<
  ActivityTableParentQuery,
  ActivityTableParentQueryVariables
>;
export const ActivityTableRowQueryDocument = gql`
  query ActivityTableRowQuery($classroomId: String!, $memberId: String!) {
    member: getClassroomMember(classroomId: $classroomId, memberId: $memberId) {
      id
      profileImageUrl
    }
  }
`;

/**
 * __useActivityTableRowQuery__
 *
 * To run a query within a React component, call `useActivityTableRowQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityTableRowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityTableRowQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useActivityTableRowQuery(
  baseOptions: Apollo.QueryHookOptions<
    ActivityTableRowQuery,
    ActivityTableRowQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActivityTableRowQuery, ActivityTableRowQueryVariables>(
    ActivityTableRowQueryDocument,
    options
  );
}
export function useActivityTableRowQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActivityTableRowQuery,
    ActivityTableRowQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ActivityTableRowQuery,
    ActivityTableRowQueryVariables
  >(ActivityTableRowQueryDocument, options);
}
export type ActivityTableRowQueryHookResult = ReturnType<
  typeof useActivityTableRowQuery
>;
export type ActivityTableRowQueryLazyQueryHookResult = ReturnType<
  typeof useActivityTableRowQueryLazyQuery
>;
export type ActivityTableRowQueryQueryResult = Apollo.QueryResult<
  ActivityTableRowQuery,
  ActivityTableRowQueryVariables
>;
export const FriendshipQueryDocument = gql`
  query FriendshipQuery($classroomId: String!, $groups: String = "student") {
    memberCollection: listClassroomMembers(
      classroomId: $classroomId
      groups: $groups
    ) {
      members {
        id
      }
      cursor
    }
  }
`;

/**
 * __useFriendshipQuery__
 *
 * To run a query within a React component, call `useFriendshipQuery` and pass it any options that fit your needs.
 * When your component renders, `useFriendshipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFriendshipQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *      groups: // value for 'groups'
 *   },
 * });
 */
export function useFriendshipQuery(
  baseOptions: Apollo.QueryHookOptions<
    FriendshipQuery,
    FriendshipQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FriendshipQuery, FriendshipQueryVariables>(
    FriendshipQueryDocument,
    options
  );
}
export function useFriendshipQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FriendshipQuery,
    FriendshipQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FriendshipQuery, FriendshipQueryVariables>(
    FriendshipQueryDocument,
    options
  );
}
export type FriendshipQueryHookResult = ReturnType<typeof useFriendshipQuery>;
export type FriendshipQueryLazyQueryHookResult = ReturnType<
  typeof useFriendshipQueryLazyQuery
>;
export type FriendshipQueryQueryResult = Apollo.QueryResult<
  FriendshipQuery,
  FriendshipQueryVariables
>;
export const FriendshipInfoQueryDocument = gql`
  query FriendshipInfoQuery($classroomId: String!) {
    friendshipCollection: listClassroomFriendships(classroomId: $classroomId) {
      classroomId
      friendshipWeightAverage
      friendships {
        ...FriendshipWeightRankListFriendshipFields
      }
    }
  }
  ${FriendshipWeightRankListFriendshipFieldsFragmentDoc}
`;

/**
 * __useFriendshipInfoQuery__
 *
 * To run a query within a React component, call `useFriendshipInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useFriendshipInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFriendshipInfoQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *   },
 * });
 */
export function useFriendshipInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    FriendshipInfoQuery,
    FriendshipInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FriendshipInfoQuery, FriendshipInfoQueryVariables>(
    FriendshipInfoQueryDocument,
    options
  );
}
export function useFriendshipInfoQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FriendshipInfoQuery,
    FriendshipInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FriendshipInfoQuery, FriendshipInfoQueryVariables>(
    FriendshipInfoQueryDocument,
    options
  );
}
export type FriendshipInfoQueryHookResult = ReturnType<
  typeof useFriendshipInfoQuery
>;
export type FriendshipInfoQueryLazyQueryHookResult = ReturnType<
  typeof useFriendshipInfoQueryLazyQuery
>;
export type FriendshipInfoQueryQueryResult = Apollo.QueryResult<
  FriendshipInfoQuery,
  FriendshipInfoQueryVariables
>;
export const FriendshipNetworkGraphQueryDocument = gql`
  query FriendshipNetworkGraphQuery($classroomId: String!) {
    ClassroomFriendshipCollection: listClassroomFriendships(
      classroomId: $classroomId
    ) {
      classroomId
      friendshipWeightAverage
      friendships {
        id
        name
        profileImage
        rank
        friendshipActivityCount
        friendCountLevel
        friendshipWeightList {
          id
          friendshipWeightLevel
        }
      }
    }
  }
`;

/**
 * __useFriendshipNetworkGraphQuery__
 *
 * To run a query within a React component, call `useFriendshipNetworkGraphQuery` and pass it any options that fit your needs.
 * When your component renders, `useFriendshipNetworkGraphQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFriendshipNetworkGraphQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *   },
 * });
 */
export function useFriendshipNetworkGraphQuery(
  baseOptions: Apollo.QueryHookOptions<
    FriendshipNetworkGraphQuery,
    FriendshipNetworkGraphQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FriendshipNetworkGraphQuery,
    FriendshipNetworkGraphQueryVariables
  >(FriendshipNetworkGraphQueryDocument, options);
}
export function useFriendshipNetworkGraphQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FriendshipNetworkGraphQuery,
    FriendshipNetworkGraphQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FriendshipNetworkGraphQuery,
    FriendshipNetworkGraphQueryVariables
  >(FriendshipNetworkGraphQueryDocument, options);
}
export type FriendshipNetworkGraphQueryHookResult = ReturnType<
  typeof useFriendshipNetworkGraphQuery
>;
export type FriendshipNetworkGraphQueryLazyQueryHookResult = ReturnType<
  typeof useFriendshipNetworkGraphQueryLazyQuery
>;
export type FriendshipNetworkGraphQueryQueryResult = Apollo.QueryResult<
  FriendshipNetworkGraphQuery,
  FriendshipNetworkGraphQueryVariables
>;
export const PostReadRateRankListByMemberQueryDocument = gql`
  query PostReadRateRankListByMemberQuery($classroomId: String!) {
    postReadsByMemberCollection: listClassroomPostReadRatesByMember(
      classroomId: $classroomId
    ) {
      classroomId
      totalMemberCount
      postReadRates {
        ...PostReadRateByMemberItemClassroomPostReadRateByMemberFields
      }
    }
  }
  ${PostReadRateByMemberItemClassroomPostReadRateByMemberFieldsFragmentDoc}
`;

/**
 * __usePostReadRateRankListByMemberQuery__
 *
 * To run a query within a React component, call `usePostReadRateRankListByMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostReadRateRankListByMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostReadRateRankListByMemberQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *   },
 * });
 */
export function usePostReadRateRankListByMemberQuery(
  baseOptions: Apollo.QueryHookOptions<
    PostReadRateRankListByMemberQuery,
    PostReadRateRankListByMemberQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PostReadRateRankListByMemberQuery,
    PostReadRateRankListByMemberQueryVariables
  >(PostReadRateRankListByMemberQueryDocument, options);
}
export function usePostReadRateRankListByMemberQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PostReadRateRankListByMemberQuery,
    PostReadRateRankListByMemberQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PostReadRateRankListByMemberQuery,
    PostReadRateRankListByMemberQueryVariables
  >(PostReadRateRankListByMemberQueryDocument, options);
}
export type PostReadRateRankListByMemberQueryHookResult = ReturnType<
  typeof usePostReadRateRankListByMemberQuery
>;
export type PostReadRateRankListByMemberQueryLazyQueryHookResult = ReturnType<
  typeof usePostReadRateRankListByMemberQueryLazyQuery
>;
export type PostReadRateRankListByMemberQueryQueryResult = Apollo.QueryResult<
  PostReadRateRankListByMemberQuery,
  PostReadRateRankListByMemberQueryVariables
>;
export const PostReadRateRankListByPostQueryDocument = gql`
  query PostReadRateRankListByPostQuery($classroomId: String!) {
    postReadsByPostCollection: listClassroomPostReadRatesByPost(
      classroomId: $classroomId
    ) {
      classroomId
      totalPostCount
      postReadRates {
        ...PostReadRateByPostItemClassroomPostReadRateByPostFields
      }
    }
  }
  ${PostReadRateByPostItemClassroomPostReadRateByPostFieldsFragmentDoc}
`;

/**
 * __usePostReadRateRankListByPostQuery__
 *
 * To run a query within a React component, call `usePostReadRateRankListByPostQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostReadRateRankListByPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostReadRateRankListByPostQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *   },
 * });
 */
export function usePostReadRateRankListByPostQuery(
  baseOptions: Apollo.QueryHookOptions<
    PostReadRateRankListByPostQuery,
    PostReadRateRankListByPostQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PostReadRateRankListByPostQuery,
    PostReadRateRankListByPostQueryVariables
  >(PostReadRateRankListByPostQueryDocument, options);
}
export function usePostReadRateRankListByPostQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PostReadRateRankListByPostQuery,
    PostReadRateRankListByPostQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PostReadRateRankListByPostQuery,
    PostReadRateRankListByPostQueryVariables
  >(PostReadRateRankListByPostQueryDocument, options);
}
export type PostReadRateRankListByPostQueryHookResult = ReturnType<
  typeof usePostReadRateRankListByPostQuery
>;
export type PostReadRateRankListByPostQueryLazyQueryHookResult = ReturnType<
  typeof usePostReadRateRankListByPostQueryLazyQuery
>;
export type PostReadRateRankListByPostQueryQueryResult = Apollo.QueryResult<
  PostReadRateRankListByPostQuery,
  PostReadRateRankListByPostQueryVariables
>;
export const YesterdayActivityCountQueryDocument = gql`
  query YesterdayActivityCountQuery(
    $classroomId: String!
    $dateToCompare: String
  ) {
    lastWeekClassActivityCountInfo: getClassroomActivityCount(
      classroomId: $classroomId
      targetDate: $dateToCompare
    ) {
      ...YesterdayActivityCountClassroomActivityCountFields
    }
    yesterdayClassActivityCountInfo: getClassroomActivityCount(
      classroomId: $classroomId
    ) {
      ...YesterdayActivityCountClassroomActivityCountFields
    }
    classroom: getClassroom(classroomId: $classroomId) {
      id
      schoolId
      grade
      schoolYear
      createdAt
    }
  }
  ${YesterdayActivityCountClassroomActivityCountFieldsFragmentDoc}
`;

/**
 * __useYesterdayActivityCountQuery__
 *
 * To run a query within a React component, call `useYesterdayActivityCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useYesterdayActivityCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYesterdayActivityCountQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *      dateToCompare: // value for 'dateToCompare'
 *   },
 * });
 */
export function useYesterdayActivityCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    YesterdayActivityCountQuery,
    YesterdayActivityCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    YesterdayActivityCountQuery,
    YesterdayActivityCountQueryVariables
  >(YesterdayActivityCountQueryDocument, options);
}
export function useYesterdayActivityCountQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    YesterdayActivityCountQuery,
    YesterdayActivityCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    YesterdayActivityCountQuery,
    YesterdayActivityCountQueryVariables
  >(YesterdayActivityCountQueryDocument, options);
}
export type YesterdayActivityCountQueryHookResult = ReturnType<
  typeof useYesterdayActivityCountQuery
>;
export type YesterdayActivityCountQueryLazyQueryHookResult = ReturnType<
  typeof useYesterdayActivityCountQueryLazyQuery
>;
export type YesterdayActivityCountQueryQueryResult = Apollo.QueryResult<
  YesterdayActivityCountQuery,
  YesterdayActivityCountQueryVariables
>;
export const YesterdayVisitRateQueryDocument = gql`
  query YesterdayVisitRateQuery($classroomId: String!, $dateToCompare: String) {
    lastWeekClassVisitRate: getClassroomVisitRate(
      classroomId: $classroomId
      targetDate: $dateToCompare
    ) {
      classroomId
      visitRate
    }
    yesterdayClassVisitRate: getClassroomVisitRate(classroomId: $classroomId) {
      classroomId
      visitRate
    }
    classroom: getClassroom(classroomId: $classroomId) {
      id
      createdAt
    }
  }
`;

/**
 * __useYesterdayVisitRateQuery__
 *
 * To run a query within a React component, call `useYesterdayVisitRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useYesterdayVisitRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYesterdayVisitRateQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *      dateToCompare: // value for 'dateToCompare'
 *   },
 * });
 */
export function useYesterdayVisitRateQuery(
  baseOptions: Apollo.QueryHookOptions<
    YesterdayVisitRateQuery,
    YesterdayVisitRateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    YesterdayVisitRateQuery,
    YesterdayVisitRateQueryVariables
  >(YesterdayVisitRateQueryDocument, options);
}
export function useYesterdayVisitRateQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    YesterdayVisitRateQuery,
    YesterdayVisitRateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    YesterdayVisitRateQuery,
    YesterdayVisitRateQueryVariables
  >(YesterdayVisitRateQueryDocument, options);
}
export type YesterdayVisitRateQueryHookResult = ReturnType<
  typeof useYesterdayVisitRateQuery
>;
export type YesterdayVisitRateQueryLazyQueryHookResult = ReturnType<
  typeof useYesterdayVisitRateQueryLazyQuery
>;
export type YesterdayVisitRateQueryQueryResult = Apollo.QueryResult<
  YesterdayVisitRateQuery,
  YesterdayVisitRateQueryVariables
>;
export const InduceSubscriptionPopupQueryDocument = gql`
  query InduceSubscriptionPopupQuery {
    relationships: listGuardianRelationships(type: child) {
      id
      childAccount {
        id
      }
    }
  }
`;

/**
 * __useInduceSubscriptionPopupQuery__
 *
 * To run a query within a React component, call `useInduceSubscriptionPopupQuery` and pass it any options that fit your needs.
 * When your component renders, `useInduceSubscriptionPopupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInduceSubscriptionPopupQuery({
 *   variables: {
 *   },
 * });
 */
export function useInduceSubscriptionPopupQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InduceSubscriptionPopupQuery,
    InduceSubscriptionPopupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InduceSubscriptionPopupQuery,
    InduceSubscriptionPopupQueryVariables
  >(InduceSubscriptionPopupQueryDocument, options);
}
export function useInduceSubscriptionPopupQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InduceSubscriptionPopupQuery,
    InduceSubscriptionPopupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InduceSubscriptionPopupQuery,
    InduceSubscriptionPopupQueryVariables
  >(InduceSubscriptionPopupQueryDocument, options);
}
export type InduceSubscriptionPopupQueryHookResult = ReturnType<
  typeof useInduceSubscriptionPopupQuery
>;
export type InduceSubscriptionPopupQueryLazyQueryHookResult = ReturnType<
  typeof useInduceSubscriptionPopupQueryLazyQuery
>;
export type InduceSubscriptionPopupQueryQueryResult = Apollo.QueryResult<
  InduceSubscriptionPopupQuery,
  InduceSubscriptionPopupQueryVariables
>;
export const InduceSubscriptionPopupMeQueryDocument = gql`
  query InduceSubscriptionPopupMeQuery {
    me: getMe {
      id
      name
    }
  }
`;

/**
 * __useInduceSubscriptionPopupMeQuery__
 *
 * To run a query within a React component, call `useInduceSubscriptionPopupMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useInduceSubscriptionPopupMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInduceSubscriptionPopupMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useInduceSubscriptionPopupMeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InduceSubscriptionPopupMeQuery,
    InduceSubscriptionPopupMeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InduceSubscriptionPopupMeQuery,
    InduceSubscriptionPopupMeQueryVariables
  >(InduceSubscriptionPopupMeQueryDocument, options);
}
export function useInduceSubscriptionPopupMeQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InduceSubscriptionPopupMeQuery,
    InduceSubscriptionPopupMeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InduceSubscriptionPopupMeQuery,
    InduceSubscriptionPopupMeQueryVariables
  >(InduceSubscriptionPopupMeQueryDocument, options);
}
export type InduceSubscriptionPopupMeQueryHookResult = ReturnType<
  typeof useInduceSubscriptionPopupMeQuery
>;
export type InduceSubscriptionPopupMeQueryLazyQueryHookResult = ReturnType<
  typeof useInduceSubscriptionPopupMeQueryLazyQuery
>;
export type InduceSubscriptionPopupMeQueryQueryResult = Apollo.QueryResult<
  InduceSubscriptionPopupMeQuery,
  InduceSubscriptionPopupMeQueryVariables
>;
export const SubscriptionQueryDocument = gql`
  query SubscriptionQuery {
    subscription: getLatestSubscription {
      id
      isAvailable
      childIds
    }
    guardianRelationships: listGuardianRelationships(type: child) {
      id
      childAccount {
        id
      }
    }
    me: getMe {
      id
      role
    }
  }
`;

/**
 * __useSubscriptionQuery__
 *
 * To run a query within a React component, call `useSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SubscriptionQuery,
    SubscriptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubscriptionQuery, SubscriptionQueryVariables>(
    SubscriptionQueryDocument,
    options
  );
}
export function useSubscriptionQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubscriptionQuery,
    SubscriptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubscriptionQuery, SubscriptionQueryVariables>(
    SubscriptionQueryDocument,
    options
  );
}
export type SubscriptionQueryHookResult = ReturnType<
  typeof useSubscriptionQuery
>;
export type SubscriptionQueryLazyQueryHookResult = ReturnType<
  typeof useSubscriptionQueryLazyQuery
>;
export type SubscriptionQueryQueryResult = Apollo.QueryResult<
  SubscriptionQuery,
  SubscriptionQueryVariables
>;
export const TrackingPixelQueryDocument = gql`
  query TrackingPixelQuery {
    subscription: getLatestSubscription {
      id
      isAvailable
    }
  }
`;

/**
 * __useTrackingPixelQuery__
 *
 * To run a query within a React component, call `useTrackingPixelQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackingPixelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackingPixelQuery({
 *   variables: {
 *   },
 * });
 */
export function useTrackingPixelQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TrackingPixelQuery,
    TrackingPixelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TrackingPixelQuery, TrackingPixelQueryVariables>(
    TrackingPixelQueryDocument,
    options
  );
}
export function useTrackingPixelQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TrackingPixelQuery,
    TrackingPixelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TrackingPixelQuery, TrackingPixelQueryVariables>(
    TrackingPixelQueryDocument,
    options
  );
}
export type TrackingPixelQueryHookResult = ReturnType<
  typeof useTrackingPixelQuery
>;
export type TrackingPixelQueryLazyQueryHookResult = ReturnType<
  typeof useTrackingPixelQueryLazyQuery
>;
export type TrackingPixelQueryQueryResult = Apollo.QueryResult<
  TrackingPixelQuery,
  TrackingPixelQueryVariables
>;
export const ClassCumulativeRecordFormMutationDocument = gql`
  mutation ClassCumulativeRecordFormMutation(
    $classroomId: String!
    $memberId: String!
    $createCumulativeRecordDtoInput: CreateCumulativeRecordDtoInput!
  ) {
    createCumulativeRecord(
      classroomId: $classroomId
      memberId: $memberId
      input: $createCumulativeRecordDtoInput
    ) {
      ...CumulativeRecordItemCumulativeRecordFields
    }
  }
  ${CumulativeRecordItemCumulativeRecordFieldsFragmentDoc}
`;
export type ClassCumulativeRecordFormMutationMutationFn =
  Apollo.MutationFunction<
    ClassCumulativeRecordFormMutation,
    ClassCumulativeRecordFormMutationVariables
  >;

/**
 * __useClassCumulativeRecordFormMutation__
 *
 * To run a mutation, you first call `useClassCumulativeRecordFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClassCumulativeRecordFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [classCumulativeRecordFormMutation, { data, loading, error }] = useClassCumulativeRecordFormMutation({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *      memberId: // value for 'memberId'
 *      createCumulativeRecordDtoInput: // value for 'createCumulativeRecordDtoInput'
 *   },
 * });
 */
export function useClassCumulativeRecordFormMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClassCumulativeRecordFormMutation,
    ClassCumulativeRecordFormMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ClassCumulativeRecordFormMutation,
    ClassCumulativeRecordFormMutationVariables
  >(ClassCumulativeRecordFormMutationDocument, options);
}
export type ClassCumulativeRecordFormMutationHookResult = ReturnType<
  typeof useClassCumulativeRecordFormMutation
>;
export type ClassCumulativeRecordFormMutationMutationResult =
  Apollo.MutationResult<ClassCumulativeRecordFormMutation>;
export type ClassCumulativeRecordFormMutationMutationOptions =
  Apollo.BaseMutationOptions<
    ClassCumulativeRecordFormMutation,
    ClassCumulativeRecordFormMutationVariables
  >;
export const ClassCumulativeRecordFormQueryDocument = gql`
  query ClassCumulativeRecordFormQuery($classroomId: String!) {
    classroom: getClassroom(classroomId: $classroomId) {
      id
      grade
    }
  }
`;

/**
 * __useClassCumulativeRecordFormQuery__
 *
 * To run a query within a React component, call `useClassCumulativeRecordFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassCumulativeRecordFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassCumulativeRecordFormQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *   },
 * });
 */
export function useClassCumulativeRecordFormQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClassCumulativeRecordFormQuery,
    ClassCumulativeRecordFormQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClassCumulativeRecordFormQuery,
    ClassCumulativeRecordFormQueryVariables
  >(ClassCumulativeRecordFormQueryDocument, options);
}
export function useClassCumulativeRecordFormQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClassCumulativeRecordFormQuery,
    ClassCumulativeRecordFormQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClassCumulativeRecordFormQuery,
    ClassCumulativeRecordFormQueryVariables
  >(ClassCumulativeRecordFormQueryDocument, options);
}
export type ClassCumulativeRecordFormQueryHookResult = ReturnType<
  typeof useClassCumulativeRecordFormQuery
>;
export type ClassCumulativeRecordFormQueryLazyQueryHookResult = ReturnType<
  typeof useClassCumulativeRecordFormQueryLazyQuery
>;
export type ClassCumulativeRecordFormQueryQueryResult = Apollo.QueryResult<
  ClassCumulativeRecordFormQuery,
  ClassCumulativeRecordFormQueryVariables
>;
export const ClassCumulativeRecordItemQueryDocument = gql`
  query ClassCumulativeRecordItemQuery(
    $classroomId: String!
    $memberId: String!
  ) {
    member: getClassroomMember(classroomId: $classroomId, memberId: $memberId) {
      id
      name
    }
  }
`;

/**
 * __useClassCumulativeRecordItemQuery__
 *
 * To run a query within a React component, call `useClassCumulativeRecordItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassCumulativeRecordItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassCumulativeRecordItemQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useClassCumulativeRecordItemQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClassCumulativeRecordItemQuery,
    ClassCumulativeRecordItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClassCumulativeRecordItemQuery,
    ClassCumulativeRecordItemQueryVariables
  >(ClassCumulativeRecordItemQueryDocument, options);
}
export function useClassCumulativeRecordItemQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClassCumulativeRecordItemQuery,
    ClassCumulativeRecordItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClassCumulativeRecordItemQuery,
    ClassCumulativeRecordItemQueryVariables
  >(ClassCumulativeRecordItemQueryDocument, options);
}
export type ClassCumulativeRecordItemQueryHookResult = ReturnType<
  typeof useClassCumulativeRecordItemQuery
>;
export type ClassCumulativeRecordItemQueryLazyQueryHookResult = ReturnType<
  typeof useClassCumulativeRecordItemQueryLazyQuery
>;
export type ClassCumulativeRecordItemQueryQueryResult = Apollo.QueryResult<
  ClassCumulativeRecordItemQuery,
  ClassCumulativeRecordItemQueryVariables
>;
export const ClassCumulativeRecordListQueryDocument = gql`
  query ClassCumulativeRecordListQuery(
    $classroomId: String!
    $memberId: String
    $limit: PositiveFloat = 30
    $cursor: String
  ) {
    cumulativeRecordsCollection: listCumulativeRecords(
      classroomId: $classroomId
      memberId: $memberId
      limit: $limit
      cursor: $cursor
    ) {
      cumulativeRecords {
        ...MemberCumulativeRecordItemCumulativeRecordFields
      }
      cursor
    }
  }
  ${MemberCumulativeRecordItemCumulativeRecordFieldsFragmentDoc}
`;

/**
 * __useClassCumulativeRecordListQuery__
 *
 * To run a query within a React component, call `useClassCumulativeRecordListQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassCumulativeRecordListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassCumulativeRecordListQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *      memberId: // value for 'memberId'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useClassCumulativeRecordListQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClassCumulativeRecordListQuery,
    ClassCumulativeRecordListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClassCumulativeRecordListQuery,
    ClassCumulativeRecordListQueryVariables
  >(ClassCumulativeRecordListQueryDocument, options);
}
export function useClassCumulativeRecordListQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClassCumulativeRecordListQuery,
    ClassCumulativeRecordListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClassCumulativeRecordListQuery,
    ClassCumulativeRecordListQueryVariables
  >(ClassCumulativeRecordListQueryDocument, options);
}
export type ClassCumulativeRecordListQueryHookResult = ReturnType<
  typeof useClassCumulativeRecordListQuery
>;
export type ClassCumulativeRecordListQueryLazyQueryHookResult = ReturnType<
  typeof useClassCumulativeRecordListQueryLazyQuery
>;
export type ClassCumulativeRecordListQueryQueryResult = Apollo.QueryResult<
  ClassCumulativeRecordListQuery,
  ClassCumulativeRecordListQueryVariables
>;
export const CumulativeRecordFormMemberSelectorQueryDocument = gql`
  query CumulativeRecordFormMemberSelectorQuery($classroomId: String!) {
    classroomMembers: listClassroomMembers(
      classroomId: $classroomId
      roles: "member"
      groups: "student"
      limit: 2000
    ) {
      members {
        ...CumulativeRecordFormMemberSelectorClassroomMemberFields
      }
    }
  }
  ${CumulativeRecordFormMemberSelectorClassroomMemberFieldsFragmentDoc}
`;

/**
 * __useCumulativeRecordFormMemberSelectorQuery__
 *
 * To run a query within a React component, call `useCumulativeRecordFormMemberSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCumulativeRecordFormMemberSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCumulativeRecordFormMemberSelectorQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *   },
 * });
 */
export function useCumulativeRecordFormMemberSelectorQuery(
  baseOptions: Apollo.QueryHookOptions<
    CumulativeRecordFormMemberSelectorQuery,
    CumulativeRecordFormMemberSelectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CumulativeRecordFormMemberSelectorQuery,
    CumulativeRecordFormMemberSelectorQueryVariables
  >(CumulativeRecordFormMemberSelectorQueryDocument, options);
}
export function useCumulativeRecordFormMemberSelectorQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CumulativeRecordFormMemberSelectorQuery,
    CumulativeRecordFormMemberSelectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CumulativeRecordFormMemberSelectorQuery,
    CumulativeRecordFormMemberSelectorQueryVariables
  >(CumulativeRecordFormMemberSelectorQueryDocument, options);
}
export type CumulativeRecordFormMemberSelectorQueryHookResult = ReturnType<
  typeof useCumulativeRecordFormMemberSelectorQuery
>;
export type CumulativeRecordFormMemberSelectorQueryLazyQueryHookResult =
  ReturnType<typeof useCumulativeRecordFormMemberSelectorQueryLazyQuery>;
export type CumulativeRecordFormMemberSelectorQueryQueryResult =
  Apollo.QueryResult<
    CumulativeRecordFormMemberSelectorQuery,
    CumulativeRecordFormMemberSelectorQueryVariables
  >;
export const CumulativeRecordFormMemberSelectorClassroomMemberQueryDocument = gql`
  query CumulativeRecordFormMemberSelectorClassroomMemberQuery(
    $classroomId: String!
    $memberId: String!
  ) {
    classroomMember: getClassroomMember(
      classroomId: $classroomId
      memberId: $memberId
    ) {
      ...CumulativeRecordFormMemberSelectorClassroomMemberFields
    }
  }
  ${CumulativeRecordFormMemberSelectorClassroomMemberFieldsFragmentDoc}
`;

/**
 * __useCumulativeRecordFormMemberSelectorClassroomMemberQuery__
 *
 * To run a query within a React component, call `useCumulativeRecordFormMemberSelectorClassroomMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useCumulativeRecordFormMemberSelectorClassroomMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCumulativeRecordFormMemberSelectorClassroomMemberQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useCumulativeRecordFormMemberSelectorClassroomMemberQuery(
  baseOptions: Apollo.QueryHookOptions<
    CumulativeRecordFormMemberSelectorClassroomMemberQuery,
    CumulativeRecordFormMemberSelectorClassroomMemberQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CumulativeRecordFormMemberSelectorClassroomMemberQuery,
    CumulativeRecordFormMemberSelectorClassroomMemberQueryVariables
  >(CumulativeRecordFormMemberSelectorClassroomMemberQueryDocument, options);
}
export function useCumulativeRecordFormMemberSelectorClassroomMemberQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CumulativeRecordFormMemberSelectorClassroomMemberQuery,
    CumulativeRecordFormMemberSelectorClassroomMemberQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CumulativeRecordFormMemberSelectorClassroomMemberQuery,
    CumulativeRecordFormMemberSelectorClassroomMemberQueryVariables
  >(CumulativeRecordFormMemberSelectorClassroomMemberQueryDocument, options);
}
export type CumulativeRecordFormMemberSelectorClassroomMemberQueryHookResult =
  ReturnType<typeof useCumulativeRecordFormMemberSelectorClassroomMemberQuery>;
export type CumulativeRecordFormMemberSelectorClassroomMemberQueryLazyQueryHookResult =
  ReturnType<
    typeof useCumulativeRecordFormMemberSelectorClassroomMemberQueryLazyQuery
  >;
export type CumulativeRecordFormMemberSelectorClassroomMemberQueryQueryResult =
  Apollo.QueryResult<
    CumulativeRecordFormMemberSelectorClassroomMemberQuery,
    CumulativeRecordFormMemberSelectorClassroomMemberQueryVariables
  >;
export const CumulativeRecordItemActionsMutationDocument = gql`
  mutation CumulativeRecordItemActionsMutation(
    $classroomId: String!
    $cumulativeRecordId: String!
  ) {
    deleteCumulativeRecord(
      classroomId: $classroomId
      cumulativeRecordId: $cumulativeRecordId
    )
  }
`;
export type CumulativeRecordItemActionsMutationMutationFn =
  Apollo.MutationFunction<
    CumulativeRecordItemActionsMutation,
    CumulativeRecordItemActionsMutationVariables
  >;

/**
 * __useCumulativeRecordItemActionsMutation__
 *
 * To run a mutation, you first call `useCumulativeRecordItemActionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCumulativeRecordItemActionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cumulativeRecordItemActionsMutation, { data, loading, error }] = useCumulativeRecordItemActionsMutation({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *      cumulativeRecordId: // value for 'cumulativeRecordId'
 *   },
 * });
 */
export function useCumulativeRecordItemActionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CumulativeRecordItemActionsMutation,
    CumulativeRecordItemActionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CumulativeRecordItemActionsMutation,
    CumulativeRecordItemActionsMutationVariables
  >(CumulativeRecordItemActionsMutationDocument, options);
}
export type CumulativeRecordItemActionsMutationHookResult = ReturnType<
  typeof useCumulativeRecordItemActionsMutation
>;
export type CumulativeRecordItemActionsMutationMutationResult =
  Apollo.MutationResult<CumulativeRecordItemActionsMutation>;
export type CumulativeRecordItemActionsMutationMutationOptions =
  Apollo.BaseMutationOptions<
    CumulativeRecordItemActionsMutation,
    CumulativeRecordItemActionsMutationVariables
  >;
export const CumulativeRecordItemActionsQueryDocument = gql`
  query CumulativeRecordItemActionsQuery($classroomId: String!) {
    classroom: getClassroom(classroomId: $classroomId) {
      id
      grade
    }
  }
`;

/**
 * __useCumulativeRecordItemActionsQuery__
 *
 * To run a query within a React component, call `useCumulativeRecordItemActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCumulativeRecordItemActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCumulativeRecordItemActionsQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *   },
 * });
 */
export function useCumulativeRecordItemActionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CumulativeRecordItemActionsQuery,
    CumulativeRecordItemActionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CumulativeRecordItemActionsQuery,
    CumulativeRecordItemActionsQueryVariables
  >(CumulativeRecordItemActionsQueryDocument, options);
}
export function useCumulativeRecordItemActionsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CumulativeRecordItemActionsQuery,
    CumulativeRecordItemActionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CumulativeRecordItemActionsQuery,
    CumulativeRecordItemActionsQueryVariables
  >(CumulativeRecordItemActionsQueryDocument, options);
}
export type CumulativeRecordItemActionsQueryHookResult = ReturnType<
  typeof useCumulativeRecordItemActionsQuery
>;
export type CumulativeRecordItemActionsQueryLazyQueryHookResult = ReturnType<
  typeof useCumulativeRecordItemActionsQueryLazyQuery
>;
export type CumulativeRecordItemActionsQueryQueryResult = Apollo.QueryResult<
  CumulativeRecordItemActionsQuery,
  CumulativeRecordItemActionsQueryVariables
>;
export const ExportCumulativeRecordQueryDocument = gql`
  query ExportCumulativeRecordQuery($classroomId: String!) {
    classroom: getClassroom(classroomId: $classroomId) {
      id
      name
    }
    classroomMembersCollection: listClassroomMembers(
      classroomId: $classroomId
      roles: "member"
      groups: "student"
      limit: 2000
    ) {
      members {
        id
        name
      }
    }
    cumulativeRecordsCollection: listAllCumulativeRecords(
      classroomId: $classroomId
    ) {
      id
      contents
      memberId
      recordedAt
      createdAt
    }
  }
`;

/**
 * __useExportCumulativeRecordQuery__
 *
 * To run a query within a React component, call `useExportCumulativeRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportCumulativeRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportCumulativeRecordQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *   },
 * });
 */
export function useExportCumulativeRecordQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExportCumulativeRecordQuery,
    ExportCumulativeRecordQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExportCumulativeRecordQuery,
    ExportCumulativeRecordQueryVariables
  >(ExportCumulativeRecordQueryDocument, options);
}
export function useExportCumulativeRecordQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExportCumulativeRecordQuery,
    ExportCumulativeRecordQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExportCumulativeRecordQuery,
    ExportCumulativeRecordQueryVariables
  >(ExportCumulativeRecordQueryDocument, options);
}
export type ExportCumulativeRecordQueryHookResult = ReturnType<
  typeof useExportCumulativeRecordQuery
>;
export type ExportCumulativeRecordQueryLazyQueryHookResult = ReturnType<
  typeof useExportCumulativeRecordQueryLazyQuery
>;
export type ExportCumulativeRecordQueryQueryResult = Apollo.QueryResult<
  ExportCumulativeRecordQuery,
  ExportCumulativeRecordQueryVariables
>;
export const ExportCumulativeRecordEventPropertiesQueryDocument = gql`
  query ExportCumulativeRecordEventPropertiesQuery($classroomId: String!) {
    classroom: getClassroom(classroomId: $classroomId) {
      id
      grade
    }
  }
`;

/**
 * __useExportCumulativeRecordEventPropertiesQuery__
 *
 * To run a query within a React component, call `useExportCumulativeRecordEventPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportCumulativeRecordEventPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportCumulativeRecordEventPropertiesQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *   },
 * });
 */
export function useExportCumulativeRecordEventPropertiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExportCumulativeRecordEventPropertiesQuery,
    ExportCumulativeRecordEventPropertiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExportCumulativeRecordEventPropertiesQuery,
    ExportCumulativeRecordEventPropertiesQueryVariables
  >(ExportCumulativeRecordEventPropertiesQueryDocument, options);
}
export function useExportCumulativeRecordEventPropertiesQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExportCumulativeRecordEventPropertiesQuery,
    ExportCumulativeRecordEventPropertiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExportCumulativeRecordEventPropertiesQuery,
    ExportCumulativeRecordEventPropertiesQueryVariables
  >(ExportCumulativeRecordEventPropertiesQueryDocument, options);
}
export type ExportCumulativeRecordEventPropertiesQueryHookResult = ReturnType<
  typeof useExportCumulativeRecordEventPropertiesQuery
>;
export type ExportCumulativeRecordEventPropertiesQueryLazyQueryHookResult =
  ReturnType<typeof useExportCumulativeRecordEventPropertiesQueryLazyQuery>;
export type ExportCumulativeRecordEventPropertiesQueryQueryResult =
  Apollo.QueryResult<
    ExportCumulativeRecordEventPropertiesQuery,
    ExportCumulativeRecordEventPropertiesQueryVariables
  >;
export const MemberCumulativeRecordFormMutationDocument = gql`
  mutation MemberCumulativeRecordFormMutation(
    $classroomId: String!
    $memberId: String!
    $createCumulativeRecordDtoInput: CreateCumulativeRecordDtoInput!
  ) {
    createCumulativeRecord(
      classroomId: $classroomId
      memberId: $memberId
      input: $createCumulativeRecordDtoInput
    ) {
      ...MemberCumulativeRecordItemCumulativeRecordFields
    }
  }
  ${MemberCumulativeRecordItemCumulativeRecordFieldsFragmentDoc}
`;
export type MemberCumulativeRecordFormMutationMutationFn =
  Apollo.MutationFunction<
    MemberCumulativeRecordFormMutation,
    MemberCumulativeRecordFormMutationVariables
  >;

/**
 * __useMemberCumulativeRecordFormMutation__
 *
 * To run a mutation, you first call `useMemberCumulativeRecordFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMemberCumulativeRecordFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [memberCumulativeRecordFormMutation, { data, loading, error }] = useMemberCumulativeRecordFormMutation({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *      memberId: // value for 'memberId'
 *      createCumulativeRecordDtoInput: // value for 'createCumulativeRecordDtoInput'
 *   },
 * });
 */
export function useMemberCumulativeRecordFormMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MemberCumulativeRecordFormMutation,
    MemberCumulativeRecordFormMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MemberCumulativeRecordFormMutation,
    MemberCumulativeRecordFormMutationVariables
  >(MemberCumulativeRecordFormMutationDocument, options);
}
export type MemberCumulativeRecordFormMutationHookResult = ReturnType<
  typeof useMemberCumulativeRecordFormMutation
>;
export type MemberCumulativeRecordFormMutationMutationResult =
  Apollo.MutationResult<MemberCumulativeRecordFormMutation>;
export type MemberCumulativeRecordFormMutationMutationOptions =
  Apollo.BaseMutationOptions<
    MemberCumulativeRecordFormMutation,
    MemberCumulativeRecordFormMutationVariables
  >;
export const MemberCumulativeRecordFormQueryDocument = gql`
  query MemberCumulativeRecordFormQuery($classroomId: String!) {
    classroom: getClassroom(classroomId: $classroomId) {
      id
      grade
    }
  }
`;

/**
 * __useMemberCumulativeRecordFormQuery__
 *
 * To run a query within a React component, call `useMemberCumulativeRecordFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberCumulativeRecordFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberCumulativeRecordFormQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *   },
 * });
 */
export function useMemberCumulativeRecordFormQuery(
  baseOptions: Apollo.QueryHookOptions<
    MemberCumulativeRecordFormQuery,
    MemberCumulativeRecordFormQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MemberCumulativeRecordFormQuery,
    MemberCumulativeRecordFormQueryVariables
  >(MemberCumulativeRecordFormQueryDocument, options);
}
export function useMemberCumulativeRecordFormQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberCumulativeRecordFormQuery,
    MemberCumulativeRecordFormQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MemberCumulativeRecordFormQuery,
    MemberCumulativeRecordFormQueryVariables
  >(MemberCumulativeRecordFormQueryDocument, options);
}
export type MemberCumulativeRecordFormQueryHookResult = ReturnType<
  typeof useMemberCumulativeRecordFormQuery
>;
export type MemberCumulativeRecordFormQueryLazyQueryHookResult = ReturnType<
  typeof useMemberCumulativeRecordFormQueryLazyQuery
>;
export type MemberCumulativeRecordFormQueryQueryResult = Apollo.QueryResult<
  MemberCumulativeRecordFormQuery,
  MemberCumulativeRecordFormQueryVariables
>;
export const MemberCumulativeRecordListQueryDocument = gql`
  query MemberCumulativeRecordListQuery(
    $classroomId: String!
    $memberId: String
    $limit: PositiveFloat = 20
    $cursor: String
  ) {
    cumulativeRecordsCollection: listCumulativeRecords(
      classroomId: $classroomId
      memberId: $memberId
      limit: $limit
      cursor: $cursor
    ) {
      cumulativeRecords {
        ...MemberCumulativeRecordItemCumulativeRecordFields
      }
      cursor
    }
  }
  ${MemberCumulativeRecordItemCumulativeRecordFieldsFragmentDoc}
`;

/**
 * __useMemberCumulativeRecordListQuery__
 *
 * To run a query within a React component, call `useMemberCumulativeRecordListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberCumulativeRecordListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberCumulativeRecordListQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *      memberId: // value for 'memberId'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useMemberCumulativeRecordListQuery(
  baseOptions: Apollo.QueryHookOptions<
    MemberCumulativeRecordListQuery,
    MemberCumulativeRecordListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MemberCumulativeRecordListQuery,
    MemberCumulativeRecordListQueryVariables
  >(MemberCumulativeRecordListQueryDocument, options);
}
export function useMemberCumulativeRecordListQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberCumulativeRecordListQuery,
    MemberCumulativeRecordListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MemberCumulativeRecordListQuery,
    MemberCumulativeRecordListQueryVariables
  >(MemberCumulativeRecordListQueryDocument, options);
}
export type MemberCumulativeRecordListQueryHookResult = ReturnType<
  typeof useMemberCumulativeRecordListQuery
>;
export type MemberCumulativeRecordListQueryLazyQueryHookResult = ReturnType<
  typeof useMemberCumulativeRecordListQueryLazyQuery
>;
export type MemberCumulativeRecordListQueryQueryResult = Apollo.QueryResult<
  MemberCumulativeRecordListQuery,
  MemberCumulativeRecordListQueryVariables
>;
export const AssignmentDashboardQueryDocument = gql`
  query AssignmentDashboardQuery {
    subscription: getLatestSubscription {
      id
      childIds
    }
    me: getInsightsAccount {
      id
      role
    }
  }
`;

/**
 * __useAssignmentDashboardQuery__
 *
 * To run a query within a React component, call `useAssignmentDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useAssignmentDashboardQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AssignmentDashboardQuery,
    AssignmentDashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AssignmentDashboardQuery,
    AssignmentDashboardQueryVariables
  >(AssignmentDashboardQueryDocument, options);
}
export function useAssignmentDashboardQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AssignmentDashboardQuery,
    AssignmentDashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AssignmentDashboardQuery,
    AssignmentDashboardQueryVariables
  >(AssignmentDashboardQueryDocument, options);
}
export type AssignmentDashboardQueryHookResult = ReturnType<
  typeof useAssignmentDashboardQuery
>;
export type AssignmentDashboardQueryLazyQueryHookResult = ReturnType<
  typeof useAssignmentDashboardQueryLazyQuery
>;
export type AssignmentDashboardQueryQueryResult = Apollo.QueryResult<
  AssignmentDashboardQuery,
  AssignmentDashboardQueryVariables
>;
export const AssignmentDashboardSubmissionQueryDocument = gql`
  query AssignmentDashboardSubmissionQuery($userId: String!, $year: Float) {
    submission: getAssignmentSubmissionRate(userId: $userId, year: $year) {
      id
      totalAssignmentCount
      totalSubmissionCount
      submissionRate
    }
  }
`;

/**
 * __useAssignmentDashboardSubmissionQuery__
 *
 * To run a query within a React component, call `useAssignmentDashboardSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentDashboardSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentDashboardSubmissionQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useAssignmentDashboardSubmissionQuery(
  baseOptions: Apollo.QueryHookOptions<
    AssignmentDashboardSubmissionQuery,
    AssignmentDashboardSubmissionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AssignmentDashboardSubmissionQuery,
    AssignmentDashboardSubmissionQueryVariables
  >(AssignmentDashboardSubmissionQueryDocument, options);
}
export function useAssignmentDashboardSubmissionQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AssignmentDashboardSubmissionQuery,
    AssignmentDashboardSubmissionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AssignmentDashboardSubmissionQuery,
    AssignmentDashboardSubmissionQueryVariables
  >(AssignmentDashboardSubmissionQueryDocument, options);
}
export type AssignmentDashboardSubmissionQueryHookResult = ReturnType<
  typeof useAssignmentDashboardSubmissionQuery
>;
export type AssignmentDashboardSubmissionQueryLazyQueryHookResult = ReturnType<
  typeof useAssignmentDashboardSubmissionQueryLazyQuery
>;
export type AssignmentDashboardSubmissionQueryQueryResult = Apollo.QueryResult<
  AssignmentDashboardSubmissionQuery,
  AssignmentDashboardSubmissionQueryVariables
>;
export const DashboardDemoPostReadQueryDocument = gql`
  query DashboardDemoPostReadQuery(
    $userId: String!
    $orderBy: String = "userEventCount"
    $orderByOption: String = "desc"
  ) {
    postReads: listPostReadsRate(
      userId: $userId
      orderBy: $orderBy
      orderByOption: $orderByOption
    ) {
      userLevel
      userEventCount
    }
  }
`;

/**
 * __useDashboardDemoPostReadQuery__
 *
 * To run a query within a React component, call `useDashboardDemoPostReadQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardDemoPostReadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardDemoPostReadQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      orderBy: // value for 'orderBy'
 *      orderByOption: // value for 'orderByOption'
 *   },
 * });
 */
export function useDashboardDemoPostReadQuery(
  baseOptions: Apollo.QueryHookOptions<
    DashboardDemoPostReadQuery,
    DashboardDemoPostReadQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DashboardDemoPostReadQuery,
    DashboardDemoPostReadQueryVariables
  >(DashboardDemoPostReadQueryDocument, options);
}
export function useDashboardDemoPostReadQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardDemoPostReadQuery,
    DashboardDemoPostReadQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DashboardDemoPostReadQuery,
    DashboardDemoPostReadQueryVariables
  >(DashboardDemoPostReadQueryDocument, options);
}
export type DashboardDemoPostReadQueryHookResult = ReturnType<
  typeof useDashboardDemoPostReadQuery
>;
export type DashboardDemoPostReadQueryLazyQueryHookResult = ReturnType<
  typeof useDashboardDemoPostReadQueryLazyQuery
>;
export type DashboardDemoPostReadQueryQueryResult = Apollo.QueryResult<
  DashboardDemoPostReadQuery,
  DashboardDemoPostReadQueryVariables
>;
export const DashboardDemoPostReactionQueryDocument = gql`
  query DashboardDemoPostReactionQuery($userId: String!) {
    postReactions: listPostReactionsRate(userId: $userId) {
      userLevel
      userEventCount
    }
  }
`;

/**
 * __useDashboardDemoPostReactionQuery__
 *
 * To run a query within a React component, call `useDashboardDemoPostReactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardDemoPostReactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardDemoPostReactionQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDashboardDemoPostReactionQuery(
  baseOptions: Apollo.QueryHookOptions<
    DashboardDemoPostReactionQuery,
    DashboardDemoPostReactionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DashboardDemoPostReactionQuery,
    DashboardDemoPostReactionQueryVariables
  >(DashboardDemoPostReactionQueryDocument, options);
}
export function useDashboardDemoPostReactionQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardDemoPostReactionQuery,
    DashboardDemoPostReactionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DashboardDemoPostReactionQuery,
    DashboardDemoPostReactionQueryVariables
  >(DashboardDemoPostReactionQueryDocument, options);
}
export type DashboardDemoPostReactionQueryHookResult = ReturnType<
  typeof useDashboardDemoPostReactionQuery
>;
export type DashboardDemoPostReactionQueryLazyQueryHookResult = ReturnType<
  typeof useDashboardDemoPostReactionQueryLazyQuery
>;
export type DashboardDemoPostReactionQueryQueryResult = Apollo.QueryResult<
  DashboardDemoPostReactionQuery,
  DashboardDemoPostReactionQueryVariables
>;
export const DashboardDemoRelationshipQueryDocument = gql`
  query DashboardDemoRelationshipQuery($userId: String!) {
    friends: listFriends(userId: $userId) {
      id
      avatarImage
    }
  }
`;

/**
 * __useDashboardDemoRelationshipQuery__
 *
 * To run a query within a React component, call `useDashboardDemoRelationshipQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardDemoRelationshipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardDemoRelationshipQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDashboardDemoRelationshipQuery(
  baseOptions: Apollo.QueryHookOptions<
    DashboardDemoRelationshipQuery,
    DashboardDemoRelationshipQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DashboardDemoRelationshipQuery,
    DashboardDemoRelationshipQueryVariables
  >(DashboardDemoRelationshipQueryDocument, options);
}
export function useDashboardDemoRelationshipQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardDemoRelationshipQuery,
    DashboardDemoRelationshipQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DashboardDemoRelationshipQuery,
    DashboardDemoRelationshipQueryVariables
  >(DashboardDemoRelationshipQueryDocument, options);
}
export type DashboardDemoRelationshipQueryHookResult = ReturnType<
  typeof useDashboardDemoRelationshipQuery
>;
export type DashboardDemoRelationshipQueryLazyQueryHookResult = ReturnType<
  typeof useDashboardDemoRelationshipQueryLazyQuery
>;
export type DashboardDemoRelationshipQueryQueryResult = Apollo.QueryResult<
  DashboardDemoRelationshipQuery,
  DashboardDemoRelationshipQueryVariables
>;
export const DashboardDemoGuardianRelationshipQueryDocument = gql`
  query DashboardDemoGuardianRelationshipQuery(
    $type: GuardianRelationshipFilter! = child
  ) {
    relationships: listGuardianRelationships(type: $type) {
      id
      childAccount {
        id
        schoolInfo {
          grade
        }
      }
    }
  }
`;

/**
 * __useDashboardDemoGuardianRelationshipQuery__
 *
 * To run a query within a React component, call `useDashboardDemoGuardianRelationshipQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardDemoGuardianRelationshipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardDemoGuardianRelationshipQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useDashboardDemoGuardianRelationshipQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DashboardDemoGuardianRelationshipQuery,
    DashboardDemoGuardianRelationshipQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DashboardDemoGuardianRelationshipQuery,
    DashboardDemoGuardianRelationshipQueryVariables
  >(DashboardDemoGuardianRelationshipQueryDocument, options);
}
export function useDashboardDemoGuardianRelationshipQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardDemoGuardianRelationshipQuery,
    DashboardDemoGuardianRelationshipQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DashboardDemoGuardianRelationshipQuery,
    DashboardDemoGuardianRelationshipQueryVariables
  >(DashboardDemoGuardianRelationshipQueryDocument, options);
}
export type DashboardDemoGuardianRelationshipQueryHookResult = ReturnType<
  typeof useDashboardDemoGuardianRelationshipQuery
>;
export type DashboardDemoGuardianRelationshipQueryLazyQueryHookResult =
  ReturnType<typeof useDashboardDemoGuardianRelationshipQueryLazyQuery>;
export type DashboardDemoGuardianRelationshipQueryQueryResult =
  Apollo.QueryResult<
    DashboardDemoGuardianRelationshipQuery,
    DashboardDemoGuardianRelationshipQueryVariables
  >;
export const DashboardDemoEmptyQueryDocument = gql`
  query DashboardDemoEmptyQuery {
    relationships: listGuardianRelationships(type: child) {
      id
      childAccount {
        id
      }
    }
  }
`;

/**
 * __useDashboardDemoEmptyQuery__
 *
 * To run a query within a React component, call `useDashboardDemoEmptyQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardDemoEmptyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardDemoEmptyQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardDemoEmptyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DashboardDemoEmptyQuery,
    DashboardDemoEmptyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DashboardDemoEmptyQuery,
    DashboardDemoEmptyQueryVariables
  >(DashboardDemoEmptyQueryDocument, options);
}
export function useDashboardDemoEmptyQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardDemoEmptyQuery,
    DashboardDemoEmptyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DashboardDemoEmptyQuery,
    DashboardDemoEmptyQueryVariables
  >(DashboardDemoEmptyQueryDocument, options);
}
export type DashboardDemoEmptyQueryHookResult = ReturnType<
  typeof useDashboardDemoEmptyQuery
>;
export type DashboardDemoEmptyQueryLazyQueryHookResult = ReturnType<
  typeof useDashboardDemoEmptyQueryLazyQuery
>;
export type DashboardDemoEmptyQueryQueryResult = Apollo.QueryResult<
  DashboardDemoEmptyQuery,
  DashboardDemoEmptyQueryVariables
>;
export const DashboardListSummaryQueryDocument = gql`
  query DashboardListSummaryQuery {
    subscription: getLatestSubscription {
      id
      childIds
    }
  }
`;

/**
 * __useDashboardListSummaryQuery__
 *
 * To run a query within a React component, call `useDashboardListSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardListSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardListSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardListSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DashboardListSummaryQuery,
    DashboardListSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DashboardListSummaryQuery,
    DashboardListSummaryQueryVariables
  >(DashboardListSummaryQueryDocument, options);
}
export function useDashboardListSummaryQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardListSummaryQuery,
    DashboardListSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DashboardListSummaryQuery,
    DashboardListSummaryQueryVariables
  >(DashboardListSummaryQueryDocument, options);
}
export type DashboardListSummaryQueryHookResult = ReturnType<
  typeof useDashboardListSummaryQuery
>;
export type DashboardListSummaryQueryLazyQueryHookResult = ReturnType<
  typeof useDashboardListSummaryQueryLazyQuery
>;
export type DashboardListSummaryQueryQueryResult = Apollo.QueryResult<
  DashboardListSummaryQuery,
  DashboardListSummaryQueryVariables
>;
export const DashboardListSummaryChildInfoQueryDocument = gql`
  query DashboardListSummaryChildInfoQuery(
    $type: GuardianRelationshipFilter! = child
    $childId: String!
    $activityLimit: Float = 10
  ) {
    relationships: listGuardianRelationships(type: $type) {
      id
      childAccount {
        id
        schoolInfo {
          grade
        }
      }
    }
    activityCollection: listChildActivities(
      childId: $childId
      limit: $activityLimit
    ) {
      activities {
        id
        publishedAt
      }
    }
  }
`;

/**
 * __useDashboardListSummaryChildInfoQuery__
 *
 * To run a query within a React component, call `useDashboardListSummaryChildInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardListSummaryChildInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardListSummaryChildInfoQuery({
 *   variables: {
 *      type: // value for 'type'
 *      childId: // value for 'childId'
 *      activityLimit: // value for 'activityLimit'
 *   },
 * });
 */
export function useDashboardListSummaryChildInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    DashboardListSummaryChildInfoQuery,
    DashboardListSummaryChildInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DashboardListSummaryChildInfoQuery,
    DashboardListSummaryChildInfoQueryVariables
  >(DashboardListSummaryChildInfoQueryDocument, options);
}
export function useDashboardListSummaryChildInfoQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardListSummaryChildInfoQuery,
    DashboardListSummaryChildInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DashboardListSummaryChildInfoQuery,
    DashboardListSummaryChildInfoQueryVariables
  >(DashboardListSummaryChildInfoQueryDocument, options);
}
export type DashboardListSummaryChildInfoQueryHookResult = ReturnType<
  typeof useDashboardListSummaryChildInfoQuery
>;
export type DashboardListSummaryChildInfoQueryLazyQueryHookResult = ReturnType<
  typeof useDashboardListSummaryChildInfoQueryLazyQuery
>;
export type DashboardListSummaryChildInfoQueryQueryResult = Apollo.QueryResult<
  DashboardListSummaryChildInfoQuery,
  DashboardListSummaryChildInfoQueryVariables
>;
export const DashboardListSummaryPostReadQueryDocument = gql`
  query DashboardListSummaryPostReadQuery(
    $userId: String!
    $orderBy: String = "userEventCount"
    $orderByOption: String = "desc"
  ) {
    postReads: listPostReadsRate(
      userId: $userId
      orderBy: $orderBy
      orderByOption: $orderByOption
    ) {
      userLevel
      userEventCount
    }
  }
`;

/**
 * __useDashboardListSummaryPostReadQuery__
 *
 * To run a query within a React component, call `useDashboardListSummaryPostReadQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardListSummaryPostReadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardListSummaryPostReadQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      orderBy: // value for 'orderBy'
 *      orderByOption: // value for 'orderByOption'
 *   },
 * });
 */
export function useDashboardListSummaryPostReadQuery(
  baseOptions: Apollo.QueryHookOptions<
    DashboardListSummaryPostReadQuery,
    DashboardListSummaryPostReadQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DashboardListSummaryPostReadQuery,
    DashboardListSummaryPostReadQueryVariables
  >(DashboardListSummaryPostReadQueryDocument, options);
}
export function useDashboardListSummaryPostReadQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardListSummaryPostReadQuery,
    DashboardListSummaryPostReadQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DashboardListSummaryPostReadQuery,
    DashboardListSummaryPostReadQueryVariables
  >(DashboardListSummaryPostReadQueryDocument, options);
}
export type DashboardListSummaryPostReadQueryHookResult = ReturnType<
  typeof useDashboardListSummaryPostReadQuery
>;
export type DashboardListSummaryPostReadQueryLazyQueryHookResult = ReturnType<
  typeof useDashboardListSummaryPostReadQueryLazyQuery
>;
export type DashboardListSummaryPostReadQueryQueryResult = Apollo.QueryResult<
  DashboardListSummaryPostReadQuery,
  DashboardListSummaryPostReadQueryVariables
>;
export const DashboardListSummaryPostReactionQueryDocument = gql`
  query DashboardListSummaryPostReactionQuery($userId: String!) {
    postReactions: listPostReactionsRate(userId: $userId) {
      userLevel
      userEventCount
    }
  }
`;

/**
 * __useDashboardListSummaryPostReactionQuery__
 *
 * To run a query within a React component, call `useDashboardListSummaryPostReactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardListSummaryPostReactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardListSummaryPostReactionQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDashboardListSummaryPostReactionQuery(
  baseOptions: Apollo.QueryHookOptions<
    DashboardListSummaryPostReactionQuery,
    DashboardListSummaryPostReactionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DashboardListSummaryPostReactionQuery,
    DashboardListSummaryPostReactionQueryVariables
  >(DashboardListSummaryPostReactionQueryDocument, options);
}
export function useDashboardListSummaryPostReactionQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardListSummaryPostReactionQuery,
    DashboardListSummaryPostReactionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DashboardListSummaryPostReactionQuery,
    DashboardListSummaryPostReactionQueryVariables
  >(DashboardListSummaryPostReactionQueryDocument, options);
}
export type DashboardListSummaryPostReactionQueryHookResult = ReturnType<
  typeof useDashboardListSummaryPostReactionQuery
>;
export type DashboardListSummaryPostReactionQueryLazyQueryHookResult =
  ReturnType<typeof useDashboardListSummaryPostReactionQueryLazyQuery>;
export type DashboardListSummaryPostReactionQueryQueryResult =
  Apollo.QueryResult<
    DashboardListSummaryPostReactionQuery,
    DashboardListSummaryPostReactionQueryVariables
  >;
export const DashboardListSummaryRelationshipQueryDocument = gql`
  query DashboardListSummaryRelationshipQuery($userId: String!) {
    friends: listFriends(userId: $userId) {
      id
      avatarImage
    }
  }
`;

/**
 * __useDashboardListSummaryRelationshipQuery__
 *
 * To run a query within a React component, call `useDashboardListSummaryRelationshipQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardListSummaryRelationshipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardListSummaryRelationshipQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDashboardListSummaryRelationshipQuery(
  baseOptions: Apollo.QueryHookOptions<
    DashboardListSummaryRelationshipQuery,
    DashboardListSummaryRelationshipQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DashboardListSummaryRelationshipQuery,
    DashboardListSummaryRelationshipQueryVariables
  >(DashboardListSummaryRelationshipQueryDocument, options);
}
export function useDashboardListSummaryRelationshipQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardListSummaryRelationshipQuery,
    DashboardListSummaryRelationshipQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DashboardListSummaryRelationshipQuery,
    DashboardListSummaryRelationshipQueryVariables
  >(DashboardListSummaryRelationshipQueryDocument, options);
}
export type DashboardListSummaryRelationshipQueryHookResult = ReturnType<
  typeof useDashboardListSummaryRelationshipQuery
>;
export type DashboardListSummaryRelationshipQueryLazyQueryHookResult =
  ReturnType<typeof useDashboardListSummaryRelationshipQueryLazyQuery>;
export type DashboardListSummaryRelationshipQueryQueryResult =
  Apollo.QueryResult<
    DashboardListSummaryRelationshipQuery,
    DashboardListSummaryRelationshipQueryVariables
  >;
export const MemberDashboardListQueryDocument = gql`
  query MemberDashboardListQuery(
    $userId: String!
    $classroomId: String!
    $memberId: String!
  ) {
    member: getClassroomMember(classroomId: $classroomId, memberId: $memberId) {
      id
      name
    }
    postReads: listPostReadsRate(userId: $userId) {
      ...PostReadDashboardPostReadFields
    }
    postReactions: listPostReactionsRate(userId: $userId) {
      ...PostReactionDashboardPostReactionFields
    }
  }
  ${PostReadDashboardPostReadFieldsFragmentDoc}
  ${PostReactionDashboardPostReactionFieldsFragmentDoc}
`;

/**
 * __useMemberDashboardListQuery__
 *
 * To run a query within a React component, call `useMemberDashboardListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberDashboardListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberDashboardListQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      classroomId: // value for 'classroomId'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useMemberDashboardListQuery(
  baseOptions: Apollo.QueryHookOptions<
    MemberDashboardListQuery,
    MemberDashboardListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MemberDashboardListQuery,
    MemberDashboardListQueryVariables
  >(MemberDashboardListQueryDocument, options);
}
export function useMemberDashboardListQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberDashboardListQuery,
    MemberDashboardListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MemberDashboardListQuery,
    MemberDashboardListQueryVariables
  >(MemberDashboardListQueryDocument, options);
}
export type MemberDashboardListQueryHookResult = ReturnType<
  typeof useMemberDashboardListQuery
>;
export type MemberDashboardListQueryLazyQueryHookResult = ReturnType<
  typeof useMemberDashboardListQueryLazyQuery
>;
export type MemberDashboardListQueryQueryResult = Apollo.QueryResult<
  MemberDashboardListQuery,
  MemberDashboardListQueryVariables
>;
export const MemberRelationshipDashboardQueryDocument = gql`
  query MemberRelationshipDashboardQuery(
    $classroomId: String!
    $userId: String!
  ) {
    listFriends: listClassroomFriends(
      classroomId: $classroomId
      userId: $userId
    ) {
      id
      name
      rank
      profileImage: profileImageUrl
    }
  }
`;

/**
 * __useMemberRelationshipDashboardQuery__
 *
 * To run a query within a React component, call `useMemberRelationshipDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberRelationshipDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberRelationshipDashboardQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useMemberRelationshipDashboardQuery(
  baseOptions: Apollo.QueryHookOptions<
    MemberRelationshipDashboardQuery,
    MemberRelationshipDashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MemberRelationshipDashboardQuery,
    MemberRelationshipDashboardQueryVariables
  >(MemberRelationshipDashboardQueryDocument, options);
}
export function useMemberRelationshipDashboardQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberRelationshipDashboardQuery,
    MemberRelationshipDashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MemberRelationshipDashboardQuery,
    MemberRelationshipDashboardQueryVariables
  >(MemberRelationshipDashboardQueryDocument, options);
}
export type MemberRelationshipDashboardQueryHookResult = ReturnType<
  typeof useMemberRelationshipDashboardQuery
>;
export type MemberRelationshipDashboardQueryLazyQueryHookResult = ReturnType<
  typeof useMemberRelationshipDashboardQueryLazyQuery
>;
export type MemberRelationshipDashboardQueryQueryResult = Apollo.QueryResult<
  MemberRelationshipDashboardQuery,
  MemberRelationshipDashboardQueryVariables
>;
export const ParticipationDashboardListQueryDocument = gql`
  query ParticipationDashboardListQuery {
    subscription: getLatestSubscription {
      id
      childIds
    }
    me: getInsightsAccount {
      id
      role
    }
  }
`;

/**
 * __useParticipationDashboardListQuery__
 *
 * To run a query within a React component, call `useParticipationDashboardListQuery` and pass it any options that fit your needs.
 * When your component renders, `useParticipationDashboardListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParticipationDashboardListQuery({
 *   variables: {
 *   },
 * });
 */
export function useParticipationDashboardListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ParticipationDashboardListQuery,
    ParticipationDashboardListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ParticipationDashboardListQuery,
    ParticipationDashboardListQueryVariables
  >(ParticipationDashboardListQueryDocument, options);
}
export function useParticipationDashboardListQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ParticipationDashboardListQuery,
    ParticipationDashboardListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ParticipationDashboardListQuery,
    ParticipationDashboardListQueryVariables
  >(ParticipationDashboardListQueryDocument, options);
}
export type ParticipationDashboardListQueryHookResult = ReturnType<
  typeof useParticipationDashboardListQuery
>;
export type ParticipationDashboardListQueryLazyQueryHookResult = ReturnType<
  typeof useParticipationDashboardListQueryLazyQuery
>;
export type ParticipationDashboardListQueryQueryResult = Apollo.QueryResult<
  ParticipationDashboardListQuery,
  ParticipationDashboardListQueryVariables
>;
export const ParticipationDashboardListPostReadQueryDocument = gql`
  query ParticipationDashboardListPostReadQuery(
    $childId: String!
    $userId: String!
    $orderBy: String = "userEventCount"
    $orderByOption: String = "desc"
  ) {
    classtingAccount: getAccount(id: $childId) {
      id
      name
    }
    postReadsRate: listPostReadsRate(
      userId: $userId
      orderBy: $orderBy
      orderByOption: $orderByOption
    ) {
      ...PostReadDashboardPostReadFields
    }
  }
  ${PostReadDashboardPostReadFieldsFragmentDoc}
`;

/**
 * __useParticipationDashboardListPostReadQuery__
 *
 * To run a query within a React component, call `useParticipationDashboardListPostReadQuery` and pass it any options that fit your needs.
 * When your component renders, `useParticipationDashboardListPostReadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParticipationDashboardListPostReadQuery({
 *   variables: {
 *      childId: // value for 'childId'
 *      userId: // value for 'userId'
 *      orderBy: // value for 'orderBy'
 *      orderByOption: // value for 'orderByOption'
 *   },
 * });
 */
export function useParticipationDashboardListPostReadQuery(
  baseOptions: Apollo.QueryHookOptions<
    ParticipationDashboardListPostReadQuery,
    ParticipationDashboardListPostReadQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ParticipationDashboardListPostReadQuery,
    ParticipationDashboardListPostReadQueryVariables
  >(ParticipationDashboardListPostReadQueryDocument, options);
}
export function useParticipationDashboardListPostReadQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ParticipationDashboardListPostReadQuery,
    ParticipationDashboardListPostReadQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ParticipationDashboardListPostReadQuery,
    ParticipationDashboardListPostReadQueryVariables
  >(ParticipationDashboardListPostReadQueryDocument, options);
}
export type ParticipationDashboardListPostReadQueryHookResult = ReturnType<
  typeof useParticipationDashboardListPostReadQuery
>;
export type ParticipationDashboardListPostReadQueryLazyQueryHookResult =
  ReturnType<typeof useParticipationDashboardListPostReadQueryLazyQuery>;
export type ParticipationDashboardListPostReadQueryQueryResult =
  Apollo.QueryResult<
    ParticipationDashboardListPostReadQuery,
    ParticipationDashboardListPostReadQueryVariables
  >;
export const ParticipationDashboardListPostReactionQueryDocument = gql`
  query ParticipationDashboardListPostReactionQuery(
    $childId: String!
    $userId: String!
  ) {
    classtingAccount: getAccount(id: $childId) {
      id
      name
    }
    postReactionsRate: listPostReactionsRate(userId: $userId) {
      ...PostReactionDashboardPostReactionFields
    }
  }
  ${PostReactionDashboardPostReactionFieldsFragmentDoc}
`;

/**
 * __useParticipationDashboardListPostReactionQuery__
 *
 * To run a query within a React component, call `useParticipationDashboardListPostReactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useParticipationDashboardListPostReactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParticipationDashboardListPostReactionQuery({
 *   variables: {
 *      childId: // value for 'childId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useParticipationDashboardListPostReactionQuery(
  baseOptions: Apollo.QueryHookOptions<
    ParticipationDashboardListPostReactionQuery,
    ParticipationDashboardListPostReactionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ParticipationDashboardListPostReactionQuery,
    ParticipationDashboardListPostReactionQueryVariables
  >(ParticipationDashboardListPostReactionQueryDocument, options);
}
export function useParticipationDashboardListPostReactionQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ParticipationDashboardListPostReactionQuery,
    ParticipationDashboardListPostReactionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ParticipationDashboardListPostReactionQuery,
    ParticipationDashboardListPostReactionQueryVariables
  >(ParticipationDashboardListPostReactionQueryDocument, options);
}
export type ParticipationDashboardListPostReactionQueryHookResult = ReturnType<
  typeof useParticipationDashboardListPostReactionQuery
>;
export type ParticipationDashboardListPostReactionQueryLazyQueryHookResult =
  ReturnType<typeof useParticipationDashboardListPostReactionQueryLazyQuery>;
export type ParticipationDashboardListPostReactionQueryQueryResult =
  Apollo.QueryResult<
    ParticipationDashboardListPostReactionQuery,
    ParticipationDashboardListPostReactionQueryVariables
  >;
export const RelationshipDashboardQueryDocument = gql`
  query RelationshipDashboardQuery {
    subscription: getLatestSubscription {
      id
      childIds
    }
    me: getInsightsAccount {
      id
      role
    }
  }
`;

/**
 * __useRelationshipDashboardQuery__
 *
 * To run a query within a React component, call `useRelationshipDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelationshipDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelationshipDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useRelationshipDashboardQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RelationshipDashboardQuery,
    RelationshipDashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RelationshipDashboardQuery,
    RelationshipDashboardQueryVariables
  >(RelationshipDashboardQueryDocument, options);
}
export function useRelationshipDashboardQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RelationshipDashboardQuery,
    RelationshipDashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RelationshipDashboardQuery,
    RelationshipDashboardQueryVariables
  >(RelationshipDashboardQueryDocument, options);
}
export type RelationshipDashboardQueryHookResult = ReturnType<
  typeof useRelationshipDashboardQuery
>;
export type RelationshipDashboardQueryLazyQueryHookResult = ReturnType<
  typeof useRelationshipDashboardQueryLazyQuery
>;
export type RelationshipDashboardQueryQueryResult = Apollo.QueryResult<
  RelationshipDashboardQuery,
  RelationshipDashboardQueryVariables
>;
export const RelationshipDashboardFriendsQueryDocument = gql`
  query RelationshipDashboardFriendsQuery($userId: String!) {
    friends: listFriends(userId: $userId) {
      ...RelationshipDashboardFriendFields
    }
  }
  ${RelationshipDashboardFriendFieldsFragmentDoc}
`;

/**
 * __useRelationshipDashboardFriendsQuery__
 *
 * To run a query within a React component, call `useRelationshipDashboardFriendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelationshipDashboardFriendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelationshipDashboardFriendsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRelationshipDashboardFriendsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RelationshipDashboardFriendsQuery,
    RelationshipDashboardFriendsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RelationshipDashboardFriendsQuery,
    RelationshipDashboardFriendsQueryVariables
  >(RelationshipDashboardFriendsQueryDocument, options);
}
export function useRelationshipDashboardFriendsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RelationshipDashboardFriendsQuery,
    RelationshipDashboardFriendsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RelationshipDashboardFriendsQuery,
    RelationshipDashboardFriendsQueryVariables
  >(RelationshipDashboardFriendsQueryDocument, options);
}
export type RelationshipDashboardFriendsQueryHookResult = ReturnType<
  typeof useRelationshipDashboardFriendsQuery
>;
export type RelationshipDashboardFriendsQueryLazyQueryHookResult = ReturnType<
  typeof useRelationshipDashboardFriendsQueryLazyQuery
>;
export type RelationshipDashboardFriendsQueryQueryResult = Apollo.QueryResult<
  RelationshipDashboardFriendsQuery,
  RelationshipDashboardFriendsQueryVariables
>;
export const RelationshipFromFriendsDashboardQueryDocument = gql`
  query RelationshipFromFriendsDashboardQuery($childId: String!) {
    me: getMe {
      id
      role
    }
    friends: listFriendsInterestedTo(childId: $childId) {
      ...RelationshipNetworkGraphFriendFields
    }
  }
  ${RelationshipNetworkGraphFriendFieldsFragmentDoc}
`;

/**
 * __useRelationshipFromFriendsDashboardQuery__
 *
 * To run a query within a React component, call `useRelationshipFromFriendsDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelationshipFromFriendsDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelationshipFromFriendsDashboardQuery({
 *   variables: {
 *      childId: // value for 'childId'
 *   },
 * });
 */
export function useRelationshipFromFriendsDashboardQuery(
  baseOptions: Apollo.QueryHookOptions<
    RelationshipFromFriendsDashboardQuery,
    RelationshipFromFriendsDashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RelationshipFromFriendsDashboardQuery,
    RelationshipFromFriendsDashboardQueryVariables
  >(RelationshipFromFriendsDashboardQueryDocument, options);
}
export function useRelationshipFromFriendsDashboardQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RelationshipFromFriendsDashboardQuery,
    RelationshipFromFriendsDashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RelationshipFromFriendsDashboardQuery,
    RelationshipFromFriendsDashboardQueryVariables
  >(RelationshipFromFriendsDashboardQueryDocument, options);
}
export type RelationshipFromFriendsDashboardQueryHookResult = ReturnType<
  typeof useRelationshipFromFriendsDashboardQuery
>;
export type RelationshipFromFriendsDashboardQueryLazyQueryHookResult =
  ReturnType<typeof useRelationshipFromFriendsDashboardQueryLazyQuery>;
export type RelationshipFromFriendsDashboardQueryQueryResult =
  Apollo.QueryResult<
    RelationshipFromFriendsDashboardQuery,
    RelationshipFromFriendsDashboardQueryVariables
  >;
export const RelationshipToFriendsDashboardQueryDocument = gql`
  query RelationshipToFriendsDashboardQuery($childId: String!) {
    me: getMe {
      id
      role
    }
    friends: listFriendsInterestedFrom(childId: $childId) {
      ...RelationshipNetworkGraphFriendFields
    }
  }
  ${RelationshipNetworkGraphFriendFieldsFragmentDoc}
`;

/**
 * __useRelationshipToFriendsDashboardQuery__
 *
 * To run a query within a React component, call `useRelationshipToFriendsDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelationshipToFriendsDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelationshipToFriendsDashboardQuery({
 *   variables: {
 *      childId: // value for 'childId'
 *   },
 * });
 */
export function useRelationshipToFriendsDashboardQuery(
  baseOptions: Apollo.QueryHookOptions<
    RelationshipToFriendsDashboardQuery,
    RelationshipToFriendsDashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RelationshipToFriendsDashboardQuery,
    RelationshipToFriendsDashboardQueryVariables
  >(RelationshipToFriendsDashboardQueryDocument, options);
}
export function useRelationshipToFriendsDashboardQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RelationshipToFriendsDashboardQuery,
    RelationshipToFriendsDashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RelationshipToFriendsDashboardQuery,
    RelationshipToFriendsDashboardQueryVariables
  >(RelationshipToFriendsDashboardQueryDocument, options);
}
export type RelationshipToFriendsDashboardQueryHookResult = ReturnType<
  typeof useRelationshipToFriendsDashboardQuery
>;
export type RelationshipToFriendsDashboardQueryLazyQueryHookResult = ReturnType<
  typeof useRelationshipToFriendsDashboardQueryLazyQuery
>;
export type RelationshipToFriendsDashboardQueryQueryResult = Apollo.QueryResult<
  RelationshipToFriendsDashboardQuery,
  RelationshipToFriendsDashboardQueryVariables
>;
export const GoalResultQueryDocument = gql`
  query GoalResultQuery {
    me: getInsightsAccount {
      id
      role
    }
    subscription: getLatestSubscription {
      id
      childIds
    }
  }
`;

/**
 * __useGoalResultQuery__
 *
 * To run a query within a React component, call `useGoalResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoalResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoalResultQuery({
 *   variables: {
 *   },
 * });
 */
export function useGoalResultQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GoalResultQuery,
    GoalResultQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GoalResultQuery, GoalResultQueryVariables>(
    GoalResultQueryDocument,
    options
  );
}
export function useGoalResultQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GoalResultQuery,
    GoalResultQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GoalResultQuery, GoalResultQueryVariables>(
    GoalResultQueryDocument,
    options
  );
}
export type GoalResultQueryHookResult = ReturnType<typeof useGoalResultQuery>;
export type GoalResultQueryLazyQueryHookResult = ReturnType<
  typeof useGoalResultQueryLazyQuery
>;
export type GoalResultQueryQueryResult = Apollo.QueryResult<
  GoalResultQuery,
  GoalResultQueryVariables
>;
export const GoalResultGoalQueryDocument = gql`
  query GoalResultGoalQuery($childId: String!, $goalId: String!) {
    goal: getGoal(childId: $childId, goalId: $goalId) {
      id
      startAt
      endAt
      reward
      stamps {
        type
      }
      status
      targetStampCount
    }
  }
`;

/**
 * __useGoalResultGoalQuery__
 *
 * To run a query within a React component, call `useGoalResultGoalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoalResultGoalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoalResultGoalQuery({
 *   variables: {
 *      childId: // value for 'childId'
 *      goalId: // value for 'goalId'
 *   },
 * });
 */
export function useGoalResultGoalQuery(
  baseOptions: Apollo.QueryHookOptions<
    GoalResultGoalQuery,
    GoalResultGoalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GoalResultGoalQuery, GoalResultGoalQueryVariables>(
    GoalResultGoalQueryDocument,
    options
  );
}
export function useGoalResultGoalQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GoalResultGoalQuery,
    GoalResultGoalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GoalResultGoalQuery, GoalResultGoalQueryVariables>(
    GoalResultGoalQueryDocument,
    options
  );
}
export type GoalResultGoalQueryHookResult = ReturnType<
  typeof useGoalResultGoalQuery
>;
export type GoalResultGoalQueryLazyQueryHookResult = ReturnType<
  typeof useGoalResultGoalQueryLazyQuery
>;
export type GoalResultGoalQueryQueryResult = Apollo.QueryResult<
  GoalResultGoalQuery,
  GoalResultGoalQueryVariables
>;
export const GoalSettingFormMutationDocument = gql`
  mutation GoalSettingFormMutation(
    $childId: String!
    $createGoalDtoInput: CreateGoalDtoInput!
  ) {
    goal: createGoal(childId: $childId, input: $createGoalDtoInput) {
      id
    }
  }
`;
export type GoalSettingFormMutationMutationFn = Apollo.MutationFunction<
  GoalSettingFormMutation,
  GoalSettingFormMutationVariables
>;

/**
 * __useGoalSettingFormMutation__
 *
 * To run a mutation, you first call `useGoalSettingFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoalSettingFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [goalSettingFormMutation, { data, loading, error }] = useGoalSettingFormMutation({
 *   variables: {
 *      childId: // value for 'childId'
 *      createGoalDtoInput: // value for 'createGoalDtoInput'
 *   },
 * });
 */
export function useGoalSettingFormMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GoalSettingFormMutation,
    GoalSettingFormMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GoalSettingFormMutation,
    GoalSettingFormMutationVariables
  >(GoalSettingFormMutationDocument, options);
}
export type GoalSettingFormMutationHookResult = ReturnType<
  typeof useGoalSettingFormMutation
>;
export type GoalSettingFormMutationMutationResult =
  Apollo.MutationResult<GoalSettingFormMutation>;
export type GoalSettingFormMutationMutationOptions = Apollo.BaseMutationOptions<
  GoalSettingFormMutation,
  GoalSettingFormMutationVariables
>;
export const GoalSettingFormQueryDocument = gql`
  query GoalSettingFormQuery {
    subscription: getLatestSubscription {
      id
      childIds
    }
  }
`;

/**
 * __useGoalSettingFormQuery__
 *
 * To run a query within a React component, call `useGoalSettingFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoalSettingFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoalSettingFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useGoalSettingFormQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GoalSettingFormQuery,
    GoalSettingFormQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GoalSettingFormQuery, GoalSettingFormQueryVariables>(
    GoalSettingFormQueryDocument,
    options
  );
}
export function useGoalSettingFormQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GoalSettingFormQuery,
    GoalSettingFormQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GoalSettingFormQuery,
    GoalSettingFormQueryVariables
  >(GoalSettingFormQueryDocument, options);
}
export type GoalSettingFormQueryHookResult = ReturnType<
  typeof useGoalSettingFormQuery
>;
export type GoalSettingFormQueryLazyQueryHookResult = ReturnType<
  typeof useGoalSettingFormQueryLazyQuery
>;
export type GoalSettingFormQueryQueryResult = Apollo.QueryResult<
  GoalSettingFormQuery,
  GoalSettingFormQueryVariables
>;
export const PraiseStampBoardSuspendMutationDocument = gql`
  mutation PraiseStampBoardSuspendMutation(
    $childId: String!
    $goalId: String!
  ) {
    goal: suspendGoal(childId: $childId, goalId: $goalId) {
      ...PraiseStampBoardGoalFields
    }
  }
  ${PraiseStampBoardGoalFieldsFragmentDoc}
`;
export type PraiseStampBoardSuspendMutationMutationFn = Apollo.MutationFunction<
  PraiseStampBoardSuspendMutation,
  PraiseStampBoardSuspendMutationVariables
>;

/**
 * __usePraiseStampBoardSuspendMutation__
 *
 * To run a mutation, you first call `usePraiseStampBoardSuspendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePraiseStampBoardSuspendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [praiseStampBoardSuspendMutation, { data, loading, error }] = usePraiseStampBoardSuspendMutation({
 *   variables: {
 *      childId: // value for 'childId'
 *      goalId: // value for 'goalId'
 *   },
 * });
 */
export function usePraiseStampBoardSuspendMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PraiseStampBoardSuspendMutation,
    PraiseStampBoardSuspendMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PraiseStampBoardSuspendMutation,
    PraiseStampBoardSuspendMutationVariables
  >(PraiseStampBoardSuspendMutationDocument, options);
}
export type PraiseStampBoardSuspendMutationHookResult = ReturnType<
  typeof usePraiseStampBoardSuspendMutation
>;
export type PraiseStampBoardSuspendMutationMutationResult =
  Apollo.MutationResult<PraiseStampBoardSuspendMutation>;
export type PraiseStampBoardSuspendMutationMutationOptions =
  Apollo.BaseMutationOptions<
    PraiseStampBoardSuspendMutation,
    PraiseStampBoardSuspendMutationVariables
  >;
export const PraiseStampBoardQueryDocument = gql`
  query PraiseStampBoardQuery {
    me: getInsightsAccount {
      id
      role
      guardianIds
    }
  }
`;

/**
 * __usePraiseStampBoardQuery__
 *
 * To run a query within a React component, call `usePraiseStampBoardQuery` and pass it any options that fit your needs.
 * When your component renders, `usePraiseStampBoardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePraiseStampBoardQuery({
 *   variables: {
 *   },
 * });
 */
export function usePraiseStampBoardQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PraiseStampBoardQuery,
    PraiseStampBoardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PraiseStampBoardQuery, PraiseStampBoardQueryVariables>(
    PraiseStampBoardQueryDocument,
    options
  );
}
export function usePraiseStampBoardQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PraiseStampBoardQuery,
    PraiseStampBoardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PraiseStampBoardQuery,
    PraiseStampBoardQueryVariables
  >(PraiseStampBoardQueryDocument, options);
}
export type PraiseStampBoardQueryHookResult = ReturnType<
  typeof usePraiseStampBoardQuery
>;
export type PraiseStampBoardQueryLazyQueryHookResult = ReturnType<
  typeof usePraiseStampBoardQueryLazyQuery
>;
export type PraiseStampBoardQueryQueryResult = Apollo.QueryResult<
  PraiseStampBoardQuery,
  PraiseStampBoardQueryVariables
>;
export const PraiseStampBoardGoalQueryDocument = gql`
  query PraiseStampBoardGoalQuery($childId: String!, $guardianId: String!) {
    goal: getLatestGoal(childId: $childId, guardianId: $guardianId) {
      ...PraiseStampBoardGoalFields
    }
  }
  ${PraiseStampBoardGoalFieldsFragmentDoc}
`;

/**
 * __usePraiseStampBoardGoalQuery__
 *
 * To run a query within a React component, call `usePraiseStampBoardGoalQuery` and pass it any options that fit your needs.
 * When your component renders, `usePraiseStampBoardGoalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePraiseStampBoardGoalQuery({
 *   variables: {
 *      childId: // value for 'childId'
 *      guardianId: // value for 'guardianId'
 *   },
 * });
 */
export function usePraiseStampBoardGoalQuery(
  baseOptions: Apollo.QueryHookOptions<
    PraiseStampBoardGoalQuery,
    PraiseStampBoardGoalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PraiseStampBoardGoalQuery,
    PraiseStampBoardGoalQueryVariables
  >(PraiseStampBoardGoalQueryDocument, options);
}
export function usePraiseStampBoardGoalQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PraiseStampBoardGoalQuery,
    PraiseStampBoardGoalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PraiseStampBoardGoalQuery,
    PraiseStampBoardGoalQueryVariables
  >(PraiseStampBoardGoalQueryDocument, options);
}
export type PraiseStampBoardGoalQueryHookResult = ReturnType<
  typeof usePraiseStampBoardGoalQuery
>;
export type PraiseStampBoardGoalQueryLazyQueryHookResult = ReturnType<
  typeof usePraiseStampBoardGoalQueryLazyQuery
>;
export type PraiseStampBoardGoalQueryQueryResult = Apollo.QueryResult<
  PraiseStampBoardGoalQuery,
  PraiseStampBoardGoalQueryVariables
>;
export const PraiseStampBoardDemoQueryDocument = gql`
  query PraiseStampBoardDemoQuery {
    relationships: listGuardianRelationships(type: child) {
      id
      childAccount {
        id
      }
    }
  }
`;

/**
 * __usePraiseStampBoardDemoQuery__
 *
 * To run a query within a React component, call `usePraiseStampBoardDemoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePraiseStampBoardDemoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePraiseStampBoardDemoQuery({
 *   variables: {
 *   },
 * });
 */
export function usePraiseStampBoardDemoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PraiseStampBoardDemoQuery,
    PraiseStampBoardDemoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PraiseStampBoardDemoQuery,
    PraiseStampBoardDemoQueryVariables
  >(PraiseStampBoardDemoQueryDocument, options);
}
export function usePraiseStampBoardDemoQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PraiseStampBoardDemoQuery,
    PraiseStampBoardDemoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PraiseStampBoardDemoQuery,
    PraiseStampBoardDemoQueryVariables
  >(PraiseStampBoardDemoQueryDocument, options);
}
export type PraiseStampBoardDemoQueryHookResult = ReturnType<
  typeof usePraiseStampBoardDemoQuery
>;
export type PraiseStampBoardDemoQueryLazyQueryHookResult = ReturnType<
  typeof usePraiseStampBoardDemoQueryLazyQuery
>;
export type PraiseStampBoardDemoQueryQueryResult = Apollo.QueryResult<
  PraiseStampBoardDemoQuery,
  PraiseStampBoardDemoQueryVariables
>;
export const PraiseStampBoardDemoMeQueryDocument = gql`
  query PraiseStampBoardDemoMeQuery {
    me: getMe {
      id
      name
    }
  }
`;

/**
 * __usePraiseStampBoardDemoMeQuery__
 *
 * To run a query within a React component, call `usePraiseStampBoardDemoMeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePraiseStampBoardDemoMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePraiseStampBoardDemoMeQuery({
 *   variables: {
 *   },
 * });
 */
export function usePraiseStampBoardDemoMeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PraiseStampBoardDemoMeQuery,
    PraiseStampBoardDemoMeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PraiseStampBoardDemoMeQuery,
    PraiseStampBoardDemoMeQueryVariables
  >(PraiseStampBoardDemoMeQueryDocument, options);
}
export function usePraiseStampBoardDemoMeQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PraiseStampBoardDemoMeQuery,
    PraiseStampBoardDemoMeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PraiseStampBoardDemoMeQuery,
    PraiseStampBoardDemoMeQueryVariables
  >(PraiseStampBoardDemoMeQueryDocument, options);
}
export type PraiseStampBoardDemoMeQueryHookResult = ReturnType<
  typeof usePraiseStampBoardDemoMeQuery
>;
export type PraiseStampBoardDemoMeQueryLazyQueryHookResult = ReturnType<
  typeof usePraiseStampBoardDemoMeQueryLazyQuery
>;
export type PraiseStampBoardDemoMeQueryQueryResult = Apollo.QueryResult<
  PraiseStampBoardDemoMeQuery,
  PraiseStampBoardDemoMeQueryVariables
>;
export const FreePaymentMutationDocument = gql`
  mutation FreePaymentMutation(
    $createFreePlanSubscriptionDtoInput: CreateFreePlanSubscriptionDtoInput!
  ) {
    createFreePlanSubscription(input: $createFreePlanSubscriptionDtoInput) {
      id
    }
  }
`;
export type FreePaymentMutationMutationFn = Apollo.MutationFunction<
  FreePaymentMutation,
  FreePaymentMutationVariables
>;

/**
 * __useFreePaymentMutation__
 *
 * To run a mutation, you first call `useFreePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFreePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [freePaymentMutation, { data, loading, error }] = useFreePaymentMutation({
 *   variables: {
 *      createFreePlanSubscriptionDtoInput: // value for 'createFreePlanSubscriptionDtoInput'
 *   },
 * });
 */
export function useFreePaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FreePaymentMutation,
    FreePaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FreePaymentMutation, FreePaymentMutationVariables>(
    FreePaymentMutationDocument,
    options
  );
}
export type FreePaymentMutationHookResult = ReturnType<
  typeof useFreePaymentMutation
>;
export type FreePaymentMutationMutationResult =
  Apollo.MutationResult<FreePaymentMutation>;
export type FreePaymentMutationMutationOptions = Apollo.BaseMutationOptions<
  FreePaymentMutation,
  FreePaymentMutationVariables
>;
export const MyBillingQueryDocument = gql`
  query MyBillingQuery {
    subscription: getLatestSubscription {
      id
      startAt
      endAt
      orderId
    }
  }
`;

/**
 * __useMyBillingQuery__
 *
 * To run a query within a React component, call `useMyBillingQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyBillingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyBillingQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyBillingQuery(
  baseOptions?: Apollo.QueryHookOptions<MyBillingQuery, MyBillingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyBillingQuery, MyBillingQueryVariables>(
    MyBillingQueryDocument,
    options
  );
}
export function useMyBillingQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyBillingQuery,
    MyBillingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyBillingQuery, MyBillingQueryVariables>(
    MyBillingQueryDocument,
    options
  );
}
export type MyBillingQueryHookResult = ReturnType<typeof useMyBillingQuery>;
export type MyBillingQueryLazyQueryHookResult = ReturnType<
  typeof useMyBillingQueryLazyQuery
>;
export type MyBillingQueryQueryResult = Apollo.QueryResult<
  MyBillingQuery,
  MyBillingQueryVariables
>;
export const MyBillingOrderQueryDocument = gql`
  query MyBillingOrderQuery($orderId: ID!) {
    order: getOrder(orderId: $orderId) {
      id
      price
      billingPeriodDuration
    }
  }
`;

/**
 * __useMyBillingOrderQuery__
 *
 * To run a query within a React component, call `useMyBillingOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyBillingOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyBillingOrderQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useMyBillingOrderQuery(
  baseOptions: Apollo.QueryHookOptions<
    MyBillingOrderQuery,
    MyBillingOrderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyBillingOrderQuery, MyBillingOrderQueryVariables>(
    MyBillingOrderQueryDocument,
    options
  );
}
export function useMyBillingOrderQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyBillingOrderQuery,
    MyBillingOrderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyBillingOrderQuery, MyBillingOrderQueryVariables>(
    MyBillingOrderQueryDocument,
    options
  );
}
export type MyBillingOrderQueryHookResult = ReturnType<
  typeof useMyBillingOrderQuery
>;
export type MyBillingOrderQueryLazyQueryHookResult = ReturnType<
  typeof useMyBillingOrderQueryLazyQuery
>;
export type MyBillingOrderQueryQueryResult = Apollo.QueryResult<
  MyBillingOrderQuery,
  MyBillingOrderQueryVariables
>;
export const MyBillingEstimatedOrderQueryDocument = gql`
  query MyBillingEstimatedOrderQuery($orderId: String!) {
    estimatedOrder: getEstimatedOrder(orderId: $orderId) {
      price
    }
  }
`;

/**
 * __useMyBillingEstimatedOrderQuery__
 *
 * To run a query within a React component, call `useMyBillingEstimatedOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyBillingEstimatedOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyBillingEstimatedOrderQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useMyBillingEstimatedOrderQuery(
  baseOptions: Apollo.QueryHookOptions<
    MyBillingEstimatedOrderQuery,
    MyBillingEstimatedOrderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MyBillingEstimatedOrderQuery,
    MyBillingEstimatedOrderQueryVariables
  >(MyBillingEstimatedOrderQueryDocument, options);
}
export function useMyBillingEstimatedOrderQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyBillingEstimatedOrderQuery,
    MyBillingEstimatedOrderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MyBillingEstimatedOrderQuery,
    MyBillingEstimatedOrderQueryVariables
  >(MyBillingEstimatedOrderQueryDocument, options);
}
export type MyBillingEstimatedOrderQueryHookResult = ReturnType<
  typeof useMyBillingEstimatedOrderQuery
>;
export type MyBillingEstimatedOrderQueryLazyQueryHookResult = ReturnType<
  typeof useMyBillingEstimatedOrderQueryLazyQuery
>;
export type MyBillingEstimatedOrderQueryQueryResult = Apollo.QueryResult<
  MyBillingEstimatedOrderQuery,
  MyBillingEstimatedOrderQueryVariables
>;
export const DraftOrderMutationDocument = gql`
  mutation DraftOrderMutation(
    $childIds: [ID!]!
    $paymentMethod: InsightPaymentMethod!
  ) {
    draftOrder(childIds: $childIds, paymentMethod: $paymentMethod) {
      id
      subscriptionId
      ordererId
      status
      price
      paymentMethod
      createdAt
      updatedAt
    }
  }
`;
export type DraftOrderMutationMutationFn = Apollo.MutationFunction<
  DraftOrderMutation,
  DraftOrderMutationVariables
>;

/**
 * __useDraftOrderMutation__
 *
 * To run a mutation, you first call `useDraftOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDraftOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [draftOrderMutation, { data, loading, error }] = useDraftOrderMutation({
 *   variables: {
 *      childIds: // value for 'childIds'
 *      paymentMethod: // value for 'paymentMethod'
 *   },
 * });
 */
export function useDraftOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DraftOrderMutation,
    DraftOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DraftOrderMutation, DraftOrderMutationVariables>(
    DraftOrderMutationDocument,
    options
  );
}
export type DraftOrderMutationHookResult = ReturnType<
  typeof useDraftOrderMutation
>;
export type DraftOrderMutationMutationResult =
  Apollo.MutationResult<DraftOrderMutation>;
export type DraftOrderMutationMutationOptions = Apollo.BaseMutationOptions<
  DraftOrderMutation,
  DraftOrderMutationVariables
>;
export const PaymentQueryDocument = gql`
  query PaymentQuery(
    $type: GuardianRelationshipFilter! = child
    $packageName: String!
    $productId: String!
    $regionCode: String! = "KR"
    $currencyCode: String! = "KRW"
  ) {
    relationships: listGuardianRelationships(type: $type) {
      id
      childAccount {
        id
        schoolInfo {
          grade
        }
      }
    }
    products: listSubscriptionProducts(
      packageName: $packageName
      productId: $productId
      regionCode: $regionCode
      currencyCode: $currencyCode
    ) {
      id
      plan
      basePlanId
      offerId
      fixedPrice
      sellingPrice
      billingPeriodDuration
    }
  }
`;

/**
 * __usePaymentQuery__
 *
 * To run a query within a React component, call `usePaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentQuery({
 *   variables: {
 *      type: // value for 'type'
 *      packageName: // value for 'packageName'
 *      productId: // value for 'productId'
 *      regionCode: // value for 'regionCode'
 *      currencyCode: // value for 'currencyCode'
 *   },
 * });
 */
export function usePaymentQuery(
  baseOptions: Apollo.QueryHookOptions<PaymentQuery, PaymentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentQuery, PaymentQueryVariables>(
    PaymentQueryDocument,
    options
  );
}
export function usePaymentQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentQuery, PaymentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentQuery, PaymentQueryVariables>(
    PaymentQueryDocument,
    options
  );
}
export type PaymentQueryHookResult = ReturnType<typeof usePaymentQuery>;
export type PaymentQueryLazyQueryHookResult = ReturnType<
  typeof usePaymentQueryLazyQuery
>;
export type PaymentQueryQueryResult = Apollo.QueryResult<
  PaymentQuery,
  PaymentQueryVariables
>;
export const SelectedChildInfoQueryDocument = gql`
  query SelectedChildInfoQuery {
    relationships: listGuardianRelationships(type: child) {
      id
      childAccount {
        ...SelectedChildInfoChildAccountFields
      }
    }
  }
  ${SelectedChildInfoChildAccountFieldsFragmentDoc}
`;

/**
 * __useSelectedChildInfoQuery__
 *
 * To run a query within a React component, call `useSelectedChildInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectedChildInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectedChildInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelectedChildInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SelectedChildInfoQuery,
    SelectedChildInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SelectedChildInfoQuery,
    SelectedChildInfoQueryVariables
  >(SelectedChildInfoQueryDocument, options);
}
export function useSelectedChildInfoQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SelectedChildInfoQuery,
    SelectedChildInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SelectedChildInfoQuery,
    SelectedChildInfoQueryVariables
  >(SelectedChildInfoQueryDocument, options);
}
export type SelectedChildInfoQueryHookResult = ReturnType<
  typeof useSelectedChildInfoQuery
>;
export type SelectedChildInfoQueryLazyQueryHookResult = ReturnType<
  typeof useSelectedChildInfoQueryLazyQuery
>;
export type SelectedChildInfoQueryQueryResult = Apollo.QueryResult<
  SelectedChildInfoQuery,
  SelectedChildInfoQueryVariables
>;
export const SubscriptionSuccessQueryDocument = gql`
  query SubscriptionSuccessQuery($orderId: ID!) {
    order: getOrder(orderId: $orderId) {
      id
      price
      billingPeriodDuration
      subscription {
        id
        startAt
        endAt
      }
    }
  }
`;

/**
 * __useSubscriptionSuccessQuery__
 *
 * To run a query within a React component, call `useSubscriptionSuccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionSuccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionSuccessQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useSubscriptionSuccessQuery(
  baseOptions: Apollo.QueryHookOptions<
    SubscriptionSuccessQuery,
    SubscriptionSuccessQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SubscriptionSuccessQuery,
    SubscriptionSuccessQueryVariables
  >(SubscriptionSuccessQueryDocument, options);
}
export function useSubscriptionSuccessQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubscriptionSuccessQuery,
    SubscriptionSuccessQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SubscriptionSuccessQuery,
    SubscriptionSuccessQueryVariables
  >(SubscriptionSuccessQueryDocument, options);
}
export type SubscriptionSuccessQueryHookResult = ReturnType<
  typeof useSubscriptionSuccessQuery
>;
export type SubscriptionSuccessQueryLazyQueryHookResult = ReturnType<
  typeof useSubscriptionSuccessQueryLazyQuery
>;
export type SubscriptionSuccessQueryQueryResult = Apollo.QueryResult<
  SubscriptionSuccessQuery,
  SubscriptionSuccessQueryVariables
>;
export const TodoDemoQueryDocument = gql`
  query TodoDemoQuery($childId: String!) {
    listTodos(childId: $childId) {
      todos {
        id
      }
    }
  }
`;

/**
 * __useTodoDemoQuery__
 *
 * To run a query within a React component, call `useTodoDemoQuery` and pass it any options that fit your needs.
 * When your component renders, `useTodoDemoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTodoDemoQuery({
 *   variables: {
 *      childId: // value for 'childId'
 *   },
 * });
 */
export function useTodoDemoQuery(
  baseOptions: Apollo.QueryHookOptions<TodoDemoQuery, TodoDemoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TodoDemoQuery, TodoDemoQueryVariables>(
    TodoDemoQueryDocument,
    options
  );
}
export function useTodoDemoQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TodoDemoQuery,
    TodoDemoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TodoDemoQuery, TodoDemoQueryVariables>(
    TodoDemoQueryDocument,
    options
  );
}
export type TodoDemoQueryHookResult = ReturnType<typeof useTodoDemoQuery>;
export type TodoDemoQueryLazyQueryHookResult = ReturnType<
  typeof useTodoDemoQueryLazyQuery
>;
export type TodoDemoQueryQueryResult = Apollo.QueryResult<
  TodoDemoQuery,
  TodoDemoQueryVariables
>;
export const TodoListQueryDocument = gql`
  query TodoListQuery($childId: String!) {
    listTodos(childId: $childId) {
      todos {
        ...TodoItemTodoFields
      }
    }
  }
  ${TodoItemTodoFieldsFragmentDoc}
`;

/**
 * __useTodoListQuery__
 *
 * To run a query within a React component, call `useTodoListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTodoListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTodoListQuery({
 *   variables: {
 *      childId: // value for 'childId'
 *   },
 * });
 */
export function useTodoListQuery(
  baseOptions: Apollo.QueryHookOptions<TodoListQuery, TodoListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TodoListQuery, TodoListQueryVariables>(
    TodoListQueryDocument,
    options
  );
}
export function useTodoListQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TodoListQuery,
    TodoListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TodoListQuery, TodoListQueryVariables>(
    TodoListQueryDocument,
    options
  );
}
export type TodoListQueryHookResult = ReturnType<typeof useTodoListQuery>;
export type TodoListQueryLazyQueryHookResult = ReturnType<
  typeof useTodoListQueryLazyQuery
>;
export type TodoListQueryQueryResult = Apollo.QueryResult<
  TodoListQuery,
  TodoListQueryVariables
>;
export const RootPageQueryDocument = gql`
  query RootPageQuery {
    subscription: getLatestSubscription {
      id
      isAvailable
    }
    relationships: listGuardianRelationships(type: child) {
      id
      childAccount {
        id
      }
    }
  }
`;

/**
 * __useRootPageQuery__
 *
 * To run a query within a React component, call `useRootPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useRootPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRootPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useRootPageQuery(
  baseOptions?: Apollo.QueryHookOptions<RootPageQuery, RootPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RootPageQuery, RootPageQueryVariables>(
    RootPageQueryDocument,
    options
  );
}
export function useRootPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RootPageQuery,
    RootPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RootPageQuery, RootPageQueryVariables>(
    RootPageQueryDocument,
    options
  );
}
export type RootPageQueryHookResult = ReturnType<typeof useRootPageQuery>;
export type RootPageQueryLazyQueryHookResult = ReturnType<
  typeof useRootPageQueryLazyQuery
>;
export type RootPageQueryQueryResult = Apollo.QueryResult<
  RootPageQuery,
  RootPageQueryVariables
>;
export const AgreementPageQueryDocument = gql`
  query AgreementPageQuery {
    me: getMe {
      id
      role
    }
  }
`;

/**
 * __useAgreementPageQuery__
 *
 * To run a query within a React component, call `useAgreementPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgreementPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgreementPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useAgreementPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AgreementPageQuery,
    AgreementPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AgreementPageQuery, AgreementPageQueryVariables>(
    AgreementPageQueryDocument,
    options
  );
}
export function useAgreementPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AgreementPageQuery,
    AgreementPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AgreementPageQuery, AgreementPageQueryVariables>(
    AgreementPageQueryDocument,
    options
  );
}
export type AgreementPageQueryHookResult = ReturnType<
  typeof useAgreementPageQuery
>;
export type AgreementPageQueryLazyQueryHookResult = ReturnType<
  typeof useAgreementPageQueryLazyQuery
>;
export type AgreementPageQueryQueryResult = Apollo.QueryResult<
  AgreementPageQuery,
  AgreementPageQueryVariables
>;
export const AgreementPageListRelationshipsQueryDocument = gql`
  query AgreementPageListRelationshipsQuery(
    $type: GuardianRelationshipFilter!
  ) {
    subscription: getLatestSubscription {
      id
      isAvailable
    }
    relationships: listGuardianRelationships(type: $type) {
      id
      createdAt
      child: childAccount {
        id
        name
        avatarImage
      }
      guardian: guardianAccount {
        id
        name
      }
    }
  }
`;

/**
 * __useAgreementPageListRelationshipsQuery__
 *
 * To run a query within a React component, call `useAgreementPageListRelationshipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgreementPageListRelationshipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgreementPageListRelationshipsQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAgreementPageListRelationshipsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AgreementPageListRelationshipsQuery,
    AgreementPageListRelationshipsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AgreementPageListRelationshipsQuery,
    AgreementPageListRelationshipsQueryVariables
  >(AgreementPageListRelationshipsQueryDocument, options);
}
export function useAgreementPageListRelationshipsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AgreementPageListRelationshipsQuery,
    AgreementPageListRelationshipsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AgreementPageListRelationshipsQuery,
    AgreementPageListRelationshipsQueryVariables
  >(AgreementPageListRelationshipsQueryDocument, options);
}
export type AgreementPageListRelationshipsQueryHookResult = ReturnType<
  typeof useAgreementPageListRelationshipsQuery
>;
export type AgreementPageListRelationshipsQueryLazyQueryHookResult = ReturnType<
  typeof useAgreementPageListRelationshipsQueryLazyQuery
>;
export type AgreementPageListRelationshipsQueryQueryResult = Apollo.QueryResult<
  AgreementPageListRelationshipsQuery,
  AgreementPageListRelationshipsQueryVariables
>;
export const BillingManagementPageQueryDocument = gql`
  query BillingManagementPageQuery {
    subscription: getLatestSubscription {
      id
      plan
    }
  }
`;

/**
 * __useBillingManagementPageQuery__
 *
 * To run a query within a React component, call `useBillingManagementPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingManagementPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingManagementPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useBillingManagementPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BillingManagementPageQuery,
    BillingManagementPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BillingManagementPageQuery,
    BillingManagementPageQueryVariables
  >(BillingManagementPageQueryDocument, options);
}
export function useBillingManagementPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BillingManagementPageQuery,
    BillingManagementPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BillingManagementPageQuery,
    BillingManagementPageQueryVariables
  >(BillingManagementPageQueryDocument, options);
}
export type BillingManagementPageQueryHookResult = ReturnType<
  typeof useBillingManagementPageQuery
>;
export type BillingManagementPageQueryLazyQueryHookResult = ReturnType<
  typeof useBillingManagementPageQueryLazyQuery
>;
export type BillingManagementPageQueryQueryResult = Apollo.QueryResult<
  BillingManagementPageQuery,
  BillingManagementPageQueryVariables
>;
export const DemoLinkRelationPageQueryDocument = gql`
  query DemoLinkRelationPageQuery {
    me: getMe {
      id
      role
    }
  }
`;

/**
 * __useDemoLinkRelationPageQuery__
 *
 * To run a query within a React component, call `useDemoLinkRelationPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemoLinkRelationPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemoLinkRelationPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useDemoLinkRelationPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DemoLinkRelationPageQuery,
    DemoLinkRelationPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DemoLinkRelationPageQuery,
    DemoLinkRelationPageQueryVariables
  >(DemoLinkRelationPageQueryDocument, options);
}
export function useDemoLinkRelationPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DemoLinkRelationPageQuery,
    DemoLinkRelationPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DemoLinkRelationPageQuery,
    DemoLinkRelationPageQueryVariables
  >(DemoLinkRelationPageQueryDocument, options);
}
export type DemoLinkRelationPageQueryHookResult = ReturnType<
  typeof useDemoLinkRelationPageQuery
>;
export type DemoLinkRelationPageQueryLazyQueryHookResult = ReturnType<
  typeof useDemoLinkRelationPageQueryLazyQuery
>;
export type DemoLinkRelationPageQueryQueryResult = Apollo.QueryResult<
  DemoLinkRelationPageQuery,
  DemoLinkRelationPageQueryVariables
>;
export const DemoLinkRelationPageRelationshipQueryDocument = gql`
  query DemoLinkRelationPageRelationshipQuery(
    $type: GuardianRelationshipFilter!
  ) {
    relationships: listGuardianRelationships(type: $type) {
      id
      childAccount {
        id
      }
    }
  }
`;

/**
 * __useDemoLinkRelationPageRelationshipQuery__
 *
 * To run a query within a React component, call `useDemoLinkRelationPageRelationshipQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemoLinkRelationPageRelationshipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemoLinkRelationPageRelationshipQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useDemoLinkRelationPageRelationshipQuery(
  baseOptions: Apollo.QueryHookOptions<
    DemoLinkRelationPageRelationshipQuery,
    DemoLinkRelationPageRelationshipQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DemoLinkRelationPageRelationshipQuery,
    DemoLinkRelationPageRelationshipQueryVariables
  >(DemoLinkRelationPageRelationshipQueryDocument, options);
}
export function useDemoLinkRelationPageRelationshipQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DemoLinkRelationPageRelationshipQuery,
    DemoLinkRelationPageRelationshipQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DemoLinkRelationPageRelationshipQuery,
    DemoLinkRelationPageRelationshipQueryVariables
  >(DemoLinkRelationPageRelationshipQueryDocument, options);
}
export type DemoLinkRelationPageRelationshipQueryHookResult = ReturnType<
  typeof useDemoLinkRelationPageRelationshipQuery
>;
export type DemoLinkRelationPageRelationshipQueryLazyQueryHookResult =
  ReturnType<typeof useDemoLinkRelationPageRelationshipQueryLazyQuery>;
export type DemoLinkRelationPageRelationshipQueryQueryResult =
  Apollo.QueryResult<
    DemoLinkRelationPageRelationshipQuery,
    DemoLinkRelationPageRelationshipQueryVariables
  >;
export const DemoPageQueryDocument = gql`
  query DemoPageQuery {
    subscription: getLatestSubscription {
      id
      isAvailable
    }
  }
`;

/**
 * __useDemoPageQuery__
 *
 * To run a query within a React component, call `useDemoPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemoPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemoPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useDemoPageQuery(
  baseOptions?: Apollo.QueryHookOptions<DemoPageQuery, DemoPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DemoPageQuery, DemoPageQueryVariables>(
    DemoPageQueryDocument,
    options
  );
}
export function useDemoPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DemoPageQuery,
    DemoPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DemoPageQuery, DemoPageQueryVariables>(
    DemoPageQueryDocument,
    options
  );
}
export type DemoPageQueryHookResult = ReturnType<typeof useDemoPageQuery>;
export type DemoPageQueryLazyQueryHookResult = ReturnType<
  typeof useDemoPageQueryLazyQuery
>;
export type DemoPageQueryQueryResult = Apollo.QueryResult<
  DemoPageQuery,
  DemoPageQueryVariables
>;
export const DemoPageMeQueryDocument = gql`
  query DemoPageMeQuery {
    me: getMe {
      id
      role
    }
  }
`;

/**
 * __useDemoPageMeQuery__
 *
 * To run a query within a React component, call `useDemoPageMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemoPageMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemoPageMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useDemoPageMeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DemoPageMeQuery,
    DemoPageMeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DemoPageMeQuery, DemoPageMeQueryVariables>(
    DemoPageMeQueryDocument,
    options
  );
}
export function useDemoPageMeQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DemoPageMeQuery,
    DemoPageMeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DemoPageMeQuery, DemoPageMeQueryVariables>(
    DemoPageMeQueryDocument,
    options
  );
}
export type DemoPageMeQueryHookResult = ReturnType<typeof useDemoPageMeQuery>;
export type DemoPageMeQueryLazyQueryHookResult = ReturnType<
  typeof useDemoPageMeQueryLazyQuery
>;
export type DemoPageMeQueryQueryResult = Apollo.QueryResult<
  DemoPageMeQuery,
  DemoPageMeQueryVariables
>;
export const DemoPageRelationshipQueryDocument = gql`
  query DemoPageRelationshipQuery($type: GuardianRelationshipFilter!) {
    relationships: listGuardianRelationships(type: $type) {
      id
      guardianAccount {
        id
      }
      childAccount {
        id
      }
    }
  }
`;

/**
 * __useDemoPageRelationshipQuery__
 *
 * To run a query within a React component, call `useDemoPageRelationshipQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemoPageRelationshipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemoPageRelationshipQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useDemoPageRelationshipQuery(
  baseOptions: Apollo.QueryHookOptions<
    DemoPageRelationshipQuery,
    DemoPageRelationshipQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DemoPageRelationshipQuery,
    DemoPageRelationshipQueryVariables
  >(DemoPageRelationshipQueryDocument, options);
}
export function useDemoPageRelationshipQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DemoPageRelationshipQuery,
    DemoPageRelationshipQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DemoPageRelationshipQuery,
    DemoPageRelationshipQueryVariables
  >(DemoPageRelationshipQueryDocument, options);
}
export type DemoPageRelationshipQueryHookResult = ReturnType<
  typeof useDemoPageRelationshipQuery
>;
export type DemoPageRelationshipQueryLazyQueryHookResult = ReturnType<
  typeof useDemoPageRelationshipQueryLazyQuery
>;
export type DemoPageRelationshipQueryQueryResult = Apollo.QueryResult<
  DemoPageRelationshipQuery,
  DemoPageRelationshipQueryVariables
>;
export const LinkRelationPageQueryDocument = gql`
  query LinkRelationPageQuery {
    me: getMe {
      id
      role
    }
  }
`;

/**
 * __useLinkRelationPageQuery__
 *
 * To run a query within a React component, call `useLinkRelationPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkRelationPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkRelationPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useLinkRelationPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LinkRelationPageQuery,
    LinkRelationPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LinkRelationPageQuery, LinkRelationPageQueryVariables>(
    LinkRelationPageQueryDocument,
    options
  );
}
export function useLinkRelationPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LinkRelationPageQuery,
    LinkRelationPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LinkRelationPageQuery,
    LinkRelationPageQueryVariables
  >(LinkRelationPageQueryDocument, options);
}
export type LinkRelationPageQueryHookResult = ReturnType<
  typeof useLinkRelationPageQuery
>;
export type LinkRelationPageQueryLazyQueryHookResult = ReturnType<
  typeof useLinkRelationPageQueryLazyQuery
>;
export type LinkRelationPageQueryQueryResult = Apollo.QueryResult<
  LinkRelationPageQuery,
  LinkRelationPageQueryVariables
>;
export const LinkRlationPageListRelationshipsQueryDocument = gql`
  query LinkRlationPageListRelationshipsQuery(
    $type: GuardianRelationshipFilter!
  ) {
    relationships: listGuardianRelationships(type: $type) {
      id
      child: childAccount {
        id
        name
      }
      guardian: guardianAccount {
        id
        name
      }
    }
  }
`;

/**
 * __useLinkRlationPageListRelationshipsQuery__
 *
 * To run a query within a React component, call `useLinkRlationPageListRelationshipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkRlationPageListRelationshipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkRlationPageListRelationshipsQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useLinkRlationPageListRelationshipsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LinkRlationPageListRelationshipsQuery,
    LinkRlationPageListRelationshipsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LinkRlationPageListRelationshipsQuery,
    LinkRlationPageListRelationshipsQueryVariables
  >(LinkRlationPageListRelationshipsQueryDocument, options);
}
export function useLinkRlationPageListRelationshipsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LinkRlationPageListRelationshipsQuery,
    LinkRlationPageListRelationshipsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LinkRlationPageListRelationshipsQuery,
    LinkRlationPageListRelationshipsQueryVariables
  >(LinkRlationPageListRelationshipsQueryDocument, options);
}
export type LinkRlationPageListRelationshipsQueryHookResult = ReturnType<
  typeof useLinkRlationPageListRelationshipsQuery
>;
export type LinkRlationPageListRelationshipsQueryLazyQueryHookResult =
  ReturnType<typeof useLinkRlationPageListRelationshipsQueryLazyQuery>;
export type LinkRlationPageListRelationshipsQueryQueryResult =
  Apollo.QueryResult<
    LinkRlationPageListRelationshipsQuery,
    LinkRlationPageListRelationshipsQueryVariables
  >;
export const Subscription2PageQueryDocument = gql`
  query Subscription2PageQuery {
    subscription: getLatestSubscription {
      id
      isAvailable
    }
    relationships: listGuardianRelationships(type: child) {
      id
      childAccount {
        id
      }
    }
  }
`;

/**
 * __useSubscription2PageQuery__
 *
 * To run a query within a React component, call `useSubscription2PageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscription2PageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscription2PageQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscription2PageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Subscription2PageQuery,
    Subscription2PageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Subscription2PageQuery,
    Subscription2PageQueryVariables
  >(Subscription2PageQueryDocument, options);
}
export function useSubscription2PageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Subscription2PageQuery,
    Subscription2PageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Subscription2PageQuery,
    Subscription2PageQueryVariables
  >(Subscription2PageQueryDocument, options);
}
export type Subscription2PageQueryHookResult = ReturnType<
  typeof useSubscription2PageQuery
>;
export type Subscription2PageQueryLazyQueryHookResult = ReturnType<
  typeof useSubscription2PageQueryLazyQuery
>;
export type Subscription2PageQueryQueryResult = Apollo.QueryResult<
  Subscription2PageQuery,
  Subscription2PageQueryVariables
>;
