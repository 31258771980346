import { useEffect, PropsWithChildren, ReactElement, Fragment } from 'react';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/dist/client/router';
import * as Sentry from '@sentry/nextjs';

type AuthProps = PropsWithChildren<{}>;

function Auth({ children }: AuthProps): ReactElement | null {
  const { data: session, status } = useSession();
  const user = session?.user;
  const router = useRouter();

  if (user) {
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: user.id ?? '',
        email: user.email ?? '',
      });
    });
  }

  useEffect(() => {
    if (status === 'loading') {
      return;
    }

    if (
      status === 'unauthenticated' ||
      session?.error === 'RefreshAccessTokenError'
    ) {
      window.location.replace(
        `${
          window.location.origin
        }/api/auth/signin?callbackUrl=${encodeURIComponent(
          window.location.href
        )}`
      );
    }
  }, [user, status, router, session]);

  if (status === 'authenticated') {
    return <Fragment>{children}</Fragment>;
  }

  return null;
}

export default Auth;
