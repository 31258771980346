import {
  Fragment,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { useRouter } from 'next/router';

import qs from 'qs';

import {
  AccountRole,
  useSubscriptionQueryLazyQuery,
} from '@/generated/graphql';

type SubscriptionProps = PropsWithChildren<{}>;

const Subscription = ({ children }: SubscriptionProps): ReactElement | null => {
  const router = useRouter();
  const [
    isNotConnectedWithSubscribedChildId,
    setIsNotConnectedWithSubscribedChildId,
  ] = useState(false);

  const [latestSubscription, { data }] = useSubscriptionQueryLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const connectedChildIds =
        data.guardianRelationships?.map(
          (relationships) => relationships?.childAccount?.id ?? ''
        ) ?? [];

      if (!data?.subscription?.isAvailable) {
        if (router.pathname === '/activities') {
          const queryString = qs.stringify({
            ...router.query,
          });

          router.replace(`/demo/activities?${queryString}`);
          return;
        }

        router.replace(
          connectedChildIds.length === 0
            ? '/demo'
            : `/demo?childId=${connectedChildIds[0]}`
        );
      } else {
        const subscribedChildId = data.subscription.childIds[0];

        const isChild = data.me?.role === AccountRole.Student;
        const isNotConnectedWithSubscribedChildId =
          !connectedChildIds.includes(subscribedChildId);

        if (isNotConnectedWithSubscribedChildId && !isChild) {
          setIsNotConnectedWithSubscribedChildId(true);
          router.replace('/reconnection');
        }
      }
    },
  });

  useEffect(() => {
    latestSubscription();

    return () => {
      setIsNotConnectedWithSubscribedChildId(false);
    };
  }, [router.pathname, latestSubscription]);

  const isReconnectionPage = router.pathname.includes('/reconnection');

  if (!data?.subscription?.isAvailable) return null;
  if (isNotConnectedWithSubscribedChildId && !isReconnectionPage) return null;

  return <Fragment>{children}</Fragment>;
};

export default Subscription;
