import deepmerge from 'deepmerge';
import deepEqual from 'deep-equal';
import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  ApolloLink,
} from '@apollo/client';

import { BatchHttpLink } from '@apollo/client/link/batch-http';

import type { GetServerSidePropsContext } from 'next';
import type { Session } from 'next-auth';
import { getSession } from 'next-auth/react';

import { SentryLink } from 'apollo-link-sentry';
import { useMemo } from 'react';

const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

export const getApolloClient = (context?: GetServerSidePropsContext) => {
  let session: Session | null;

  const customFetch = async (uri: string, options: any) => {
    if (!session) {
      session = await getSession(context);
    }

    options.headers.authorization = session?.accessToken
      ? `Bearer ${session?.accessToken}`
      : '';

    return fetch(uri, options);
  };

  const httpLink = new BatchHttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_BASE_URL,
    credentials: 'same-origin',
    fetch: customFetch,
    batchMax: 5,
    batchInterval: 20,
  });

  const cache = new InMemoryCache({
    typePolicies: {
      ClassroomFriendshipCollectionDto: {
        keyFields: ['classroomId'],
      },
      Query: {
        fields: {
          listChildActivities: {
            keyArgs: false,
            merge(existing, incoming, { args }) {
              if (!args?.cursor) return { ...incoming };

              return {
                activities: [...existing.activities, ...incoming.activities],
                cursor: incoming.cursor,
                __typename: 'InsightActivitiesCollectionDto',
              };
            },
          },
          listMemberActivities: {
            keyArgs: false,
            merge(existing, incoming, { args }) {
              if (!args?.cursor) return { ...incoming };

              return {
                activities: [...existing.activities, ...incoming.activities],
                cursor: incoming.cursor,
                __typename: 'InsightActivitiesCollectionDto',
              };
            },
          },
          listCumulativeRecords: {
            keyArgs: false,
            merge(existing, incoming, { args }) {
              if (!args?.cursor) return { ...incoming };

              return {
                cumulativeRecords: [
                  ...existing.cumulativeRecords,
                  ...incoming.cumulativeRecords,
                ],
                cursor: incoming.cursor,
                __typename: 'CumulativeRecordsCollectionDto',
              };
            },
          },
          listAllCumulativeRecords: {
            keyArgs: false,
            merge(existing, incoming, { args }) {
              if (!args?.cursor) return { ...incoming };

              return [...existing, ...incoming];
            },
          },
        },
      },
    },
  });

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: ApolloLink.from([
      new SentryLink({
        uri: process.env.NEXT_PUBLIC_GRAPHQL_BASE_URL,
        attachBreadcrumbs: {
          includeVariables: true,
          includeError: true,
        },
      }),
      httpLink,
    ]),
    cache,
    connectToDevTools: process.env.NODE_ENV !== 'production',
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        // https://github.com/apollographql/apollo-client/issues/6833#issuecomment-679446789
        nextFetchPolicy(lastFetchPolicy) {
          if (
            lastFetchPolicy === 'cache-and-network' ||
            lastFetchPolicy === 'network-only'
          ) {
            return 'cache-first';
          }
          return lastFetchPolicy;
        },
        notifyOnNetworkStatusChange: true,
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
    },
  });
};

// https://github.com/vercel/next.js/blob/canary/examples/with-apollo/lib/apolloClient.js
export function initializeApollo({
  initialState = null,
  context,
}: {
  initialState?: NormalizedCacheObject | null;
  context?: GetServerSidePropsContext;
}) {
  // initialState: NormalizedCacheObject | null = null
  const _apolloClient = apolloClient ?? getApolloClient(context);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = deepmerge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !deepEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

// NOTE:: This function takes the pageProps returned from getStaticProps() / getServerSideProps() for the current page and adds to them Apollo’s cache data. From there, Next.js takes care of passing Apollo’s cache data, along with any other page-specific props into the page component.
export function addApolloState(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: any
) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps: any) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(
    () => initializeApollo({ initialState: state }),
    [state]
  );
  return store;
}
