import { WebViewBridge } from '@/common/webview-bridge';
import { IFrameBridge } from '@/common/iframe-bridge';

if (typeof window !== 'undefined') {
  if (!IFrameBridge.isEmbeddedInIFrame()) {
    if (WebViewBridge.isClasstingRNWebView()) {
      document.addEventListener(
        'click',
        (event) => {
          for (
            let target: HTMLElement = event.target as HTMLElement;
            target && target !== this;
            target = target.parentNode as HTMLElement
          ) {
            if (target instanceof Document) {
              break;
            }

            try {
              if (target?.matches('[target="_blank"]')) {
                const el = target as HTMLAnchorElement;

                WebViewBridge.openWithBrowser({ url: el.href });

                event.preventDefault();
                break;
              }
            } catch (e) {
              console.error(e);
              continue;
            }
          }
        },
        false
      );

      window.open = (url) => {
        if (url) {
          WebViewBridge.openWithBrowser({ url: url.toString() });
        }

        return null;
      };
    }
  }
}
