export enum AdvertisementEnv {
  dev = 'dev',
  stag = 'stag',
  prod = 'prod',
}

export const AD_AWS_SQS_BASE_URL =
  'https://sqs.ap-northeast-1.amazonaws.com/220554832478';

export const getAdAPIUrl = (env: AdvertisementEnv): string => {
  const baseUrl = 'https://adapi.classting';

  switch (env) {
    case AdvertisementEnv.prod:
      return `${baseUrl}.com/v1/advertisements`;
    default:
      return `${baseUrl}.net/v1/advertisements`;
  }
};

export const getSQSPath = (env: AdvertisementEnv): string => {
  const basePath = '/ct_staging_ad';

  switch (env) {
    case AdvertisementEnv.prod:
      return '/ct_production_ad';
    default:
      return basePath;
  }
};
