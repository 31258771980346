interface MessagePayload {
  method: IFrameBridgeMethod;
  callback?: IFrameBridgeMethod;
  data?: Record<string, unknown>;
}

export interface ConfirmResultMessagePayload extends MessagePayload {
  method: IFrameBridgeMethod.CONFIRM_RESULT;
  data: {
    isConfirmed: boolean;
    targetId: string;
  };
}

export enum IFrameBridgeMethod {
  LINK_OPEN = 'insights:linkOpen',
  CONFIRM_OPEN = 'insights:confirmOpen',
  CONFIRM_RESULT = 'insights:confirmResult',
  OPEN_MEMBER_ACTIVITY_REPORT = 'insights:openMemberActivityReport',
}

export class IFrameBridge {
  static isEmbeddedInIFrame() {
    if (typeof window === 'undefined') return false;

    return window.parent.location !== window.self.location;
  }

  static isClassroomMessage(origin: string) {
    return origin.includes(process.env.NEXT_PUBLIC_NEWCLASS_URL);
  }

  static isConfirmResultPayload(
    payload: MessagePayload
  ): payload is ConfirmResultMessagePayload {
    return payload.method === IFrameBridgeMethod.CONFIRM_RESULT;
  }

  static parseMessagePayload(stringifiedPayload: string): MessagePayload {
    const result = JSON.parse(stringifiedPayload);
    if (!('method' in result)) {
      throw new Error('Invalid JSON format.');
    }

    return result;
  }

  private static request(payload: MessagePayload) {
    if (typeof window === 'undefined') return;

    const stringifyPayload = JSON.stringify(payload);

    window.parent.postMessage(
      stringifyPayload,
      process.env.NEXT_PUBLIC_NEWCLASS_URL
    );
  }

  static linkOpenRequest(url: string, fallback: () => void) {
    if (this.isEmbeddedInIFrame()) {
      this.request({
        method: IFrameBridgeMethod.LINK_OPEN,
        data: {
          url,
        },
      });
      return;
    }

    fallback();
  }

  static openMemberActivityReportRequest(
    classroomId: string,
    memberId: string
  ) {
    if (this.isEmbeddedInIFrame()) {
      this.request({
        method: IFrameBridgeMethod.OPEN_MEMBER_ACTIVITY_REPORT,
        data: {
          classroomId,
          memberId,
        },
      });
      return;
    }
  }

  static confirmDialogRequest(targetId: string, fallback: () => void) {
    if (this.isEmbeddedInIFrame()) {
      this.request({
        method: IFrameBridgeMethod.CONFIRM_OPEN,
        callback: IFrameBridgeMethod.CONFIRM_RESULT,
        data: {
          targetId,
        },
      });
      return;
    }

    fallback();
  }
}
