import { useEffect } from 'react';
import { useSession } from 'next-auth/react';
import TagManager from 'react-gtm-module';

import { useRouter } from 'next/router';

import { useTrackingPixelQuery } from '../../../generated/graphql';

function TrackingPixel(): null {
  const { data: session } = useSession();
  const { pathname } = useRouter();
  const isClassroomView = pathname.includes('classroom');

  const { data } = useTrackingPixelQuery({
    fetchPolicy: 'cache-first',
    skip: isClassroomView,
    onCompleted: (data) => {
      TagManager.initialize({
        gtmId: process.env.NEXT_PUBLIC_GTM_ID as string,
        auth: process.env.NEXT_PUBLIC_GTM_AUTH as string,
        preview: process.env.NEXT_PUBLIC_GTM_ENV as string,
        dataLayer: {
          userId: session?.user?.id,
          role: session?.user?.role,
          name: session?.user?.name,
          email: session?.user?.email,
          isInsightsLicense: data?.subscription?.isAvailable,
        },
      });
    },
  });

  useEffect(() => {
    if (isClassroomView) {
      TagManager.initialize({
        gtmId: process.env.NEXT_PUBLIC_GTM_ID as string,
        auth: process.env.NEXT_PUBLIC_GTM_AUTH as string,
        preview: process.env.NEXT_PUBLIC_GTM_ENV as string,
        dataLayer: {
          userId: session?.user?.id,
          role: session?.user?.role,
          name: session?.user?.name,
          email: session?.user?.email,
        },
      });
    }
  }, [isClassroomView, session]);

  return null;
}

export default TrackingPixel;
