import { useRouter } from 'next/router';
import {
  Fragment,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import semver from 'semver';

import { WebViewBridge } from '../../../common/webview-bridge';

type AppVersionCheckerProps = PropsWithChildren<{ skip?: boolean }>;

const INSIGHTS_TARGET_VERSION = '7.17.1';

const AppVersionChecker = ({
  children,
  skip = false,
}: AppVersionCheckerProps): ReactElement | null => {
  const router = useRouter();
  const [isValidAppVersion, setIsValidAppVersion] = useState<boolean>(false);

  useEffect(() => {
    if (!skip) {
      if (WebViewBridge.isClasstingRNWebView()) {
        const appVersion = WebViewBridge.getClasstingRNAppVersion();

        if (!semver.gte(appVersion as string, INSIGHTS_TARGET_VERSION)) {
          router.replace('/version-update');
        } else {
          setIsValidAppVersion(true);
        }
      } else {
        setIsValidAppVersion(true);
      }
    }
  }, [router, skip]);

  if (skip || isValidAppVersion) {
    return <Fragment>{children}</Fragment>;
  }

  return null;
};

export default AppVersionChecker;
