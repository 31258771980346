import { useRouter } from 'next/router';

import { WebViewBridge } from '@/common/webview-bridge';

const useGoBackPage = () => {
  const router = useRouter();

  const goBackPage = (fallbackUrl?: string): void => {
    const prevHref = window.location.href;
    router.back();

    setTimeout(() => {
      if (prevHref === window.location.href) {
        if (fallbackUrl) {
          window.location.replace(fallbackUrl);
        } else {
          WebViewBridge.close();
        }
      }
    }, 300);
  };

  return goBackPage;
};

export default useGoBackPage;
