import { stringify } from 'qs';

export type BridgeMessageObject = {
  category: string;
  type: string;
  callback?: string;
  payload?: Record<string, unknown>;
};

export type EventPayload = {
  eventName: string;
  eventDetail?: any;
};

type AppBarTopPayload = {
  title: string;
};

type SharePayload = {
  message: string;
};

type OpenPayload = {
  url: string;
};

type AppBarTopMessageObject = {
  category: 'webView';
  type: 'app_bar_top';
  payload: AppBarTopPayload;
};

type CloseMessageObject = {
  category: 'navigation';
  type: 'close';
  payload?: EventPayload;
};

type OpenMessageObject = {
  category: 'navigation';
  type: 'open';
  payload: OpenPayload;
};

type ShareMessageObject = {
  category: 'interaction';
  type: 'share';
  payload: SharePayload;
};

type OpenParams = {
  url: string;
  transition?: 'stack' | 'drawer' | 'sheet';
  appbar?: boolean;
  toolbar?: boolean;
};

export class WebViewBridge {
  private static request(message: string): void {
    window.top?.ReactNativeWebView?.postMessage(message);
  }

  static requestWithCallback<T>(message: string, callbackKey: string) {
    const cleanUp = () =>
      setTimeout(() => {
        delete window.top!.bridgeCallbacks?.[callbackKey];
        delete window.top!.bridgeErrorCallbacks?.[callbackKey];
      }, 500);

    const asyncRequest = new Promise<T>((resolve, reject) => {
      if (window.top!.bridgeCallbacks === undefined) {
        window.top!.bridgeCallbacks = {};
      }

      if (window.top!.bridgeErrorCallbacks === undefined) {
        window.top!.bridgeErrorCallbacks = {};
      }

      window.top!.bridgeCallbacks[callbackKey] = function (
        stringifiedParam: string
      ): void {
        const parsed = JSON.parse(stringifiedParam);

        cleanUp();
        resolve(parsed);
      };

      window.top!.bridgeErrorCallbacks[callbackKey] = function (
        message?: string
      ) {
        cleanUp();
        reject(new WebViewBridgeCallbackFailedError(message));
      };

      WebViewBridge.request(message);
    });

    return asyncRequest;
  }

  static isClasstingRNWebView(): boolean {
    return window.top!.navigator.userAgent.includes('classting_rn');
  }

  static getClasstingRNAppVersion = (toNumber = false): string | number => {
    if (!window.top) {
      return '';
    }

    const { userAgent } = window.top.navigator;

    const regex = /classting_rn,\s(\d+).(\d+).(\d+)/;
    const match = regex.exec(userAgent.toLowerCase());

    if (toNumber) {
      return match ? Number(match.slice(1, 4).join('')) : 0;
    }

    return match ? match.slice(1, 4).join('.') : '';
  };

  static setAppBarTop(payload: AppBarTopPayload) {
    const message: AppBarTopMessageObject = {
      category: 'webView',
      type: 'app_bar_top',
      payload,
    };

    WebViewBridge.request(JSON.stringify(message));
  }

  static inAppShare(shareMessage: string) {
    const message: ShareMessageObject = {
      category: 'interaction',
      type: 'share',
      payload: {
        message: shareMessage,
      },
    };

    WebViewBridge.request(JSON.stringify(message));
  }

  static inAppPurchaseInit() {
    const message: BridgeMessageObject = {
      category: 'iap',
      type: 'initialize',
    };

    WebViewBridge.request(JSON.stringify(message));
  }

  static inAppPurchaseRelease() {
    const message: BridgeMessageObject = {
      category: 'iap',
      type: 'release',
    };

    WebViewBridge.request(JSON.stringify(message));
  }

  static close(payload?: EventPayload): void {
    const message: CloseMessageObject = {
      category: 'navigation',
      type: 'close',
      payload,
    };

    WebViewBridge.request(JSON.stringify(message));
  }

  static open({
    url,
    transition = 'stack',
    appbar = true,
    toolbar = false,
  }: OpenParams): void {
    const queryString = stringify({
      url,
      transition_type: transition,
      appbar: appbar ? 1 : 0,
      toolbar: toolbar ? 1 : 0,
    });

    window.location.href = `classting://webview?${queryString}`;
  }

  static navigateToInsights() {
    window.location.href = `classting://InsightTab`;
  }

  static openWithBrowser(payload: OpenPayload) {
    const message: OpenMessageObject = {
      category: 'navigation',
      type: 'open',
      payload,
    };

    WebViewBridge.request(JSON.stringify(message));
  }
}

class WebViewBridgeCallbackFailedError extends Error {
  constructor(message?: string) {
    super(message ?? 'webview bridge callback execution failed');
  }
}
