import { useCallback, createContext, useState, useMemo } from 'react';

import { AdvertisementFormData } from '../types/advertisementTypes';
import { AdvertisementEnv, getAdAPIUrl } from '../constants/urls';

interface FetchAdDataParams {
  view_template: string;
  token: string;
  mode: AdvertisementEnv;
}

interface AdServiceProviderReturn {
  data: AdvertisementFormData[];
  isLoading: boolean;
  error?: Error | null | undefined;
}

interface AdServiceProviderProps {
  children: React.ReactNode;
}

interface VideoPlayState {
  videoUrl: string;
  playSeconds?: number;
}

interface requestAdDataParams {
  viewTemplates: string | string[];
  token: string;
  mode?: AdvertisementEnv;
}

export interface AdServiceContextValue {
  requestAdData: (params: requestAdDataParams) => void;
  adState: AdServiceProviderReturn;
  viewedList: number[];
  videoStateList: VideoPlayState[];
  isAdViewed: (adId: number) => boolean;
  getVideoState: (videoUrl: string) => number;
  updateVideoStateList: (state: VideoPlayState) => void;
}

export const AdServiceContext = createContext<AdServiceContextValue>({
  requestAdData: () => {},
  adState: {
    data: [],
    isLoading: false,
    error: null,
  },
  viewedList: [],
  videoStateList: [],
  isAdViewed: () => false,
  getVideoState: () => 0,
  updateVideoStateList: () => {},
});

export const AdService = () => {
  const AdServiceProvider = ({ children }: AdServiceProviderProps) => {
    const [adState, setAdState] = useState<AdServiceProviderReturn>({
      data: [],
      isLoading: false,
      error: null,
    });
    const [viewedList, setViewedList] = useState<number[]>([]);
    const [videoStateList, setVideoStateList] = useState<VideoPlayState[]>([]);

    const fetchAdData = async ({
      view_template,
      token,
      mode,
    }: FetchAdDataParams) => {
      const adApiUrl = getAdAPIUrl(mode);

      setAdState((prev) => ({ ...prev, isLoading: true }));

      try {
        const response = await fetch(
          `${adApiUrl}?view_template=${view_template}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status >= 200 && response.status < 400) {
          const data = await response.json();
          setAdState((prev) => ({ ...prev, data: [...prev.data, ...data] }));
        } else {
          const error = await response.json();
          setAdState((prev) => ({
            ...prev,
            data: [],
            error: error as Error,
          }));
        }
      } catch (error) {
        setAdState((prev) => ({ ...prev, data: [], error: error as Error }));
      } finally {
        setAdState((prev) => ({ ...prev, isLoading: false }));
      }
    };
    const isAdViewed = useCallback(
      (adId: number) => {
        const isViewed = viewedList.includes(adId);
        if (!isViewed) {
          setViewedList((prevList) => [...prevList, adId]);
        }
        return isViewed;
      },
      [viewedList]
    );
    const getVideoState = useCallback(
      (videoUrl: string) => {
        const returnVal = videoStateList.filter(
          (state) => state.videoUrl === videoUrl
        );
        return returnVal?.[0]?.playSeconds || 0;
      },
      [videoStateList]
    );

    const updateVideoStateList = useCallback(
      ({ videoUrl, playSeconds }: VideoPlayState) => {
        setVideoStateList((prevList) => {
          const filteredList = prevList.filter(
            (state) => state.videoUrl !== videoUrl
          );
          return [...filteredList, { videoUrl, playSeconds }];
        });
      },
      []
    );

    const requestAdData = useCallback(
      ({
        viewTemplates,
        token,
        mode = process.env.NEXT_PUBLIC_STAGE as AdvertisementEnv,
      }: requestAdDataParams) => {
        if (typeof viewTemplates === 'string') viewTemplates = [viewTemplates];
        if (!viewTemplates.length) return;

        setAdState((prev) => ({ ...prev, data: [] }));
        setViewedList([]);
        setVideoStateList([]);

        viewTemplates.forEach((view_template: string) =>
          fetchAdData({ view_template, token, mode })
        );
      },
      []
    );

    const value = useMemo(
      () => ({
        requestAdData,
        adState,
        viewedList,
        videoStateList,
        isAdViewed,
        getVideoState,
        updateVideoStateList,
      }),
      [
        requestAdData,
        adState,
        viewedList,
        videoStateList,
        isAdViewed,
        getVideoState,
        updateVideoStateList,
      ]
    );

    return (
      <AdServiceContext.Provider value={value}>
        {children}
      </AdServiceContext.Provider>
    );
  };

  return {
    AdServiceProvider,
    AdService,
  };
};
